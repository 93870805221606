import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddServices from './AddServices';
import AddTeam from './AddTeam';
import AddFaqs from './AddFaqs';
import AddBanners from './AddBanners';
import AddAboutUs from './AddAboutUs';
import AddContactUs from './AddContactUs';
import { useWebBuilders } from '../../hooks/useWebBuilder';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { IBuilder, IWebAboutUs, IWebContact } from '../../models/builder.model';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const WebBuilder = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { webBuilderData } = useWebBuilders();

  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity.selectedCommunity
  );

  const communityId = selectedCommunity?._id;
  const [builderData, setBuilderData] = useState<IBuilder | null>(null);
  const [loading, setLoading] = useState(true);

  const getWebData = async () => {
    try {
      const webBuilderResponse = await webBuilderData(communityId);
      if (webBuilderResponse?.status === 200) {
        setBuilderData(webBuilderResponse.data?.data as IBuilder);
      } else {
        console.error('Unexpected response format:', webBuilderResponse);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (communityId) {
      getWebData();
    }
  }, [communityId]);


  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        width: '100%',
        height: '90vh',
        overflow: 'auto',
      }}
    >
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          width: '20%',
          minWidth: '200px',
          '& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root': {
            alignItems: 'flex-start',
            textTransform: 'capitalize',
            fontWeight: '500',
          },
          '& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected': {
            color: ' #3C5AA0',
            backgroundColor: '#F5F6F6',
          },
        }}
      >
        <Tab label="Banners" {...a11yProps(0)} />
        <Tab label="About us" {...a11yProps(1)} />
        <Tab label="Services" {...a11yProps(2)} />
        <Tab label="Teams" {...a11yProps(3)} />
        <Tab label="Faq" {...a11yProps(4)} />
        <Tab label="Contact us" {...a11yProps(5)} />
      </Tabs>
      <Box sx={{ flexGrow: 1, width: '80%' }}>
        <TabPanel value={value} index={0}>
          <AddBanners banners={builderData?.banner || []} refreshData={getWebData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AddAboutUs aboutUs={builderData?.aboutUs as IWebAboutUs}
            refreshData={getWebData} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AddServices services={builderData?.services || []} refreshData={getWebData} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <AddTeam team={builderData?.teams || []} refreshData={getWebData} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <AddFaqs faq={builderData?.faq || []} refreshData={getWebData} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <AddContactUs
            contactUs={builderData?.contactUs as IWebContact}
            refreshData={getWebData}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};
export default WebBuilder;
