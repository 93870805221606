import { Box, styled } from '@mui/material';

export const StyledLogin = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',
  // overflow: "auto",
  '& .MuiGrid-root': {
    // height: "100%",
    flexGrow: 1,
  },
  '& .MuiFab-root': {
    backgroundColor: theme.palette.common.white,
  },
  '& .css-rh9fm5 .login-form .MuiFormControl-root': {
    backgroundColor: '#ffffff',
  },
  '& .login-form': {
    pt: 1,
    '& .MuiFormControl-root': {
      backgroundColor: '#ffffff',
      fontSize: '14px',
    },
  },
}));

export const label = {
  '& .label.Mui-focused': {
    color: '#355B3E',
  },
};

export const error = {
  fontSize: '12px',
  color: '#d32f2f',
  backgroundColor: '',
};
