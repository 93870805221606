import React from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { stringAvatar } from '../../../utils/stringAvatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export interface IUserInfoCell {
  name: string;
  avatar: string;
  [key: string]: any;
}
export const UserInfoCell = ({ name, avatar, ...restProps }: IUserInfoCell) => {
  const avatarProps = { ...stringAvatar(name) };
  return (
    <Stack spacing={1} direction={'row'} alignItems={'center'}>
      <Avatar
        alt={name}
        src={avatar}
        {...{
          ...avatarProps,
          sx: { ...(avatarProps.sx ?? {}), ...(restProps.avatarStyles ?? {}) },
        }}
      />
      <Box>
        <Typography {...(restProps.nameProps ?? {})}>{name}</Typography>
        {restProps?.id && (
          <Typography {...(restProps?.idProps ?? {})}>
            {restProps.id}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
