import { initializeApp, getApps, getApp } from 'firebase/app';
import { getMessaging, getToken, Messaging } from 'firebase/messaging';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const firebaseConfig: FirebaseConfig = {
  apiKey: 'AIzaSyCudv4bg1XgN9W6Yszjb2FNXc7L46W786k',
  authDomain: 'onecommunn-63bb9.firebaseapp.com',
  projectId: 'onecommunn-63bb9',
  storageBucket: 'onecommunn-63bb9.appspot.com',
  messagingSenderId: '953091073665',
  appId: '1:953091073665:web:dfef6cab5eae6bb0cc0f80',
};

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}

const messaging: Messaging = getMessaging(app);

export const getDeviceToken = async (): Promise<string | null> => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        'BFGFs0E2kFMzn7V0tb8cLsWwv2Hhd9lAxB3cFYxXutK8jVeuhwcqkMRgfk0aINHHw_4r20ULX3kk2-BCG6eJEkc',
    });

    if (currentToken) {
      return currentToken;
    } else {
      console.warn(
        'No registration token available. Request permission to generate one.'
      );
      return null;
    }
  } catch (error: any) {
    if (
      error.code === 'messaging/permission-blocked' ||
      error.code === 'messaging/permission-default'
    ) {
      console.warn('Push notification permission not granted.');
      return null;
    }
    console.error('An error occurred while retrieving the token: ', error);
    return null;
  }
};

getDeviceToken();
