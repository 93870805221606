import {
  Grid,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import { useEffect, useState } from 'react';
import { InvitesAllCommunities } from '../AllCommunities/InvitesAllCommunities';
import { useInvitations } from '../../../hooks/useInvitations';
import { Invitations } from '../../../models/Invitation.model';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../utils/constants';

const InvitiesCommunitiesGird = () => {
  const [invitesCommunities, setInvitesCommunities] = useState<Invitations[]>(
    []
  );
  const { isLoading, getInvitesForUser } = useInvitations();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const data = await getInvitesForUser();
      setInvitesCommunities(data);
    }
    fetchData();
  }, []);

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.invitesCommunities}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack
        direction={'row'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
        }}
      >
        <BootstrapTooltip title="Back" placement="left">
          <KeyboardBackspaceIcon
            sx={{
              color: '#50A1CA',
              cursor: 'pointer',
              ml: 2,
              fontSize: { xs: '20px', md: '40px' },
            }}
            onClick={() => navigate(`/explore-communities`)}
          />
        </BootstrapTooltip>
        <Typography
          sx={{
            fontSize: { xs: '14px', md: '18px' },
            fontWeight: 700,
            textDecoration: 'none',
            color: '#000000',
            // marginLeft: 10
          }}
        >
          Invite Communities
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            color: '#B3B3B3',
            cursor: 'pointer',
          }}
        >
          {invitesCommunities?.length} communities
        </Typography>
      </Stack>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container spacing={0}>
          {invitesCommunities.map((item) => (
            <Grid item key={item._id} xs={6} sm={2} md={2} lg={2} xl={2}>
              <InvitesAllCommunities
                community={item?.community}
                _id={item?._id}
                message={item?.message}
                invites={item}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default InvitiesCommunitiesGird;
