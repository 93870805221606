import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import { IReports } from '../models/reports.model';
import { getReports } from '../services/reports.service';
export const useReports = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  // const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getCommunityReports = async (
    communityId: string,
    planId: string,
    startDate: string,
    endDate: string
  ) => {
    setIsLoading(true);
    const response = await getReports(
      getAccessToken(),
      communityId,
      planId,
      startDate,
      endDate
    );
    if (response.status === 200) {
      setIsLoading(false);
      return response.data.data as IReports[];
    } else if (response.status === 404) {
      console.log(response?.status);
    } else if (response.status === 500) {
      // enqueueSnackbar("Failed to fetch community reports", {
      //   variant: "error",
      //   autoHideDuration: 3000
      // });
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    getCommunityReports,
  };
};
