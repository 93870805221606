import { Button, CircularProgress, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { IWebTeam } from '../../models/builder.model';
import { useWebBuilders } from '../../hooks/useWebBuilder';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

export interface AddTeamProps {
  team: IWebTeam[];
  refreshData: () => void;
}

const AddTeams: React.FC<AddTeamProps> = ({ team, refreshData }) => {
  const { updateTeams, uploadImages } = useWebBuilders();
  const { enqueueSnackbar } = useSnackbar();
  const [teams, setTeams] = useState<IWebTeam[]>([]);
  const selectedCommunity = useSelector((state: any) => state?.selectedCommunity.selectedCommunity)


  const [loader, setLoader] = useState<boolean>(false);
  const [loaderImg, setLoadingImg] = useState(false);

  useEffect(() => {
    if (team && team.length > 0) {
      setTeams(team);
    } else {
      setTeams([{ name: '', designation: '', experience: '', description: '', avatar: null }]);
    }
  }, [team]);


  console.log(teams, "teams");



  const handleImageChange = async (files: FileList | null, index: number): Promise<void> => {
    if (!files) return;
    const selectedFiles = Array.from(files);
    setLoadingImg(true);
    try {
      const responses = await uploadImages(selectedFiles);
      const uploadedImageUrl = responses[0]._id;
      // console.log(uploadedImageUrl, "uploadedImageUrl");
      setTeams((prevTeams) => {
        const updatedTeams = [...prevTeams];
        if (updatedTeams[index]) {
          updatedTeams[index].avatar = uploadedImageUrl;
        }
        return updatedTeams;
      });
      enqueueSnackbar("Team Member image updated successfully", { variant: "success" });
    } catch (error) {
      console.error('Error during upload:', error);
      enqueueSnackbar('Failed to upload team member image. Please try again.', { variant: 'error' });
    } finally {
      setLoadingImg(false);
    }
  };


  const handleRemoveImage = (index?: number) => {
    if (index !== undefined) {
      setTeams((prevData) => {
        const updatedServiceData = [...prevData];
        updatedServiceData[index].avatar = '';
        return updatedServiceData;
      });
    } else {
      setTeams((prevService) => ({
        ...prevService,
        avatar: '',
      }));
    }
  };


  const handleTeamChange = (index: number, field: keyof IWebTeam, value: string) => {
    setTeams((prevData) =>
      prevData.map((team, i) => (i === index ? { ...team, [field]: value } : team))
    );
  };

  const addNewTeam = () => {
    const newTeam = { name: '', designation: '', experience: '', description: '', avatar: '' };
    setTeams((prevTeams) => [...prevTeams, newTeam]);
  };

  const deleteSingleTeamForm = (index: number) => {
    setTeams((prevTeams) => prevTeams.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    const teamsToSubmit = teams.filter(team => team.name || team.designation || team.experience);
    const hasEmptyFields = teams.some(team => !team.name || !team.designation || !team.experience);

    if (hasEmptyFields) {
      enqueueSnackbar('Please fill in name, designation, and experience for all team members.', { variant: 'warning' });
      return;
    }
    setLoader(true)
    try {
      const communityId = selectedCommunity?._id;
      const response = await updateTeams(teamsToSubmit, communityId);
      if (response?.status === 200) {
        enqueueSnackbar("Teams updated successfully", { variant: "success" });
        refreshData();
      }
    } catch (error) {
      console.error('Error updating teams:', error);
      enqueueSnackbar("Failed to update teams. Please try again.", { variant: "error" });
    } finally {
      setLoader(false)
    }
  };

  return (
    <Stack spacing={2}>
      {teams.map((team, index) => (
        <Stack
          key={index}
          sx={{
            border: '1px solid #E0E0E0',
            padding: '30px',
            margin: '15px 0px',
            position: 'relative',
          }}
        >
          <IconButton
            style={{
              position: 'absolute',
              top: '1px',
              right: '1px',
              backgroundColor: 'white',
            }}
            onClick={() => deleteSingleTeamForm(index)}
          >
            <DeleteIcon />
          </IconButton>
          <Grid container spacing={2} display={'flex'} alignItems="center" >
            <Grid item xs={9}>
              <Stack flexDirection="row" gap={5} >
                <Stack sx={{ width: '100%' }}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={team.name}
                    onChange={(e) => handleTeamChange(index, 'name', e.target.value)}
                    sx={{ marginBottom: '15px' }}
                  />
                  <TextField
                    label="Designation"
                    variant="outlined"
                    fullWidth
                    value={team.designation}
                    onChange={(e) => handleTeamChange(index, 'designation', e.target.value)}
                    sx={{ marginBottom: '15px' }}
                  />
                  <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    value={team.description}
                    onChange={(e) => handleTeamChange(index, 'description', e.target.value)}
                    sx={{ marginBottom: '15px' }}
                  />
                  <TextField
                    label="Experience"
                    variant="outlined"
                    fullWidth
                    value={team.experience}
                    onChange={(e) => handleTeamChange(index, 'experience', e.target.value)}
                    sx={{ marginBottom: '15px' }}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              {teams.map((team, index) => (
                <Stack
                  key={index}
                  sx={{
                    backgroundColor: team.avatar?.value ? '#ffffff' : '#F5F5F5',
                    p: 1.5,
                    m: 1,
                    borderRadius: '5px',
                    border: team.avatar?.value ? 'none' : '1px dashed #000000',
                    height: { xs: '20vh', md: '15vh' },
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage: team.avatar?.value ? `url(${team.avatar.value})` : 'none',
                    position: 'relative',
                  }}
                >
                  {team.avatar?.value ? (
                    <IconButton
                      onClick={() => handleRemoveImage(index)}
                      sx={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        backgroundColor: 'white',
                        '&:hover': { backgroundColor: '#f2f2f2' },
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <>
                      <Typography sx={{ fontSize: '13px', color: '#5E6C84', pb: 1 }}>
                        Upload Image
                      </Typography>
                      {loaderImg ? (
                        <CircularProgress size={24} sx={{ color: '#3C5AA0' }} />
                      ) : (
                        <input
                          type="file"
                          name="images"
                          onChange={(e) => handleImageChange(e.target.files, index)}
                          style={{
                            border: '2px solid #5E6C84',
                            borderRadius: '5px',
                            padding: '8px',
                            backgroundColor: '#ffffff',
                            color: '#5E6C84',
                            fontSize: '10px',
                            fontWeight: '500',
                            cursor: 'pointer',
                            width: '70%',
                            marginTop: '10px',
                          }}
                        />
                      )}
                    </>
                  )}
                </Stack>
              ))}
            </Grid>
          </Grid>
        </Stack>
      ))}
      <Button
        variant="outlined"
        sx={{
          textTransform: 'capitalize',
          width: 'fit-content',
          fontSize: '13px',
          color: '#2952A2',
          border: '1px solid #2952A2',
          ':hover': {
            border: '1px solid #2952A2',
            color: '#2952A2',
          },
        }}
        onClick={addNewTeam}
      >
        Add Team Member
      </Button>

      <Button
        variant="contained"
        size="large"
        sx={{
          textTransform: 'capitalize',
          borderRadius: '10px',
          mt: 2,
          mb: 2,
          color: '#ffffff',
          width: '100px',
          boxShadow: 'none',
          background:
            'linear-gradient(140deg, #3C5AA0 13.54%, #467DB4 68.75%, #50A1CA 100%)',
          '&:hover': {
            backgroundColor: '#50A1CA',
            cursor: 'pointer',
          },
        }}
        onClick={handleSubmit}
        disabled={loader}
      >
        {loader ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "Update"}
      </Button>
    </Stack>
  );
};

export default AddTeams;