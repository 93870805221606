import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Drawer, DrawerHeader, styles } from './Layout.styles';
import NavigationBar from './components/NavigationBar/NavigationBar';
import { Outlet } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Stack from '@mui/material/Stack';
// import { SidebarTogglerIcon } from "../../assets/icons";
import { MenuIcon } from '../../assets/icons';
import MobileNav from './components/MobileNav/MobileNav';
// import MobileTopNav from "./components/MobileNav/MobileTopNav";
// import { Paper } from "@mui/material";
// import MobileSideBar from "./components/MobileNav/MobileSidebar";

export default function Layout() {
  const [open, setOpen] = React.useState(true);
  // const [showButtons, setShowButtons] = React.useState(true);

  // const handleCancelClick = () => {
  //   setShowButtons(false);
  // };
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        open={open}
        sx={{ display: { xs: 'none', md: 'block' } }}
      >
        <DrawerHeader>
          <Stack alignItems={'center'} justifyContent={'center'}>
            {open && (
              <Box
                component={'img'}
                src={require('../../assets/images/Dashboard-Logo.png')}
                alt="Dashboard Logo"
                sx={{ width: '90%' }}
              />
            )}
          </Stack>

          <IconButton disableRipple={true} onClick={handleDrawerOpen}>
            {!open
              ? ''
              : // <img
                //     src={require("../../assets/images/Communn-favicon.png")}
                //     alt="home page logo not found"
                //     style={{ width: "3.5vh", }}
                // />
                ''}

            {/* <SidebarTogglerIcon /> */}
            <MenuIcon sx={{ mr: 2, mt: 1 }} />
          </IconButton>
          {/* <MobileSideBar /> */}
        </DrawerHeader>

        <Sidebar open={open} />
      </Drawer>

      <Box sx={styles.main}>
        {/* Header Section */}

        <NavigationBar />

        {/* Content Section */}
        <Box sx={styles.contentSection}>
          {/* <Dashboard /> */}
          {/* <Box sx={{
            display: { xs: 'block', md: 'none' }, mb: 1,
            position: 'sticky',
            top: 0, // Ensures it sticks to the top
            zIndex: 1000, // Adjust z-index as needed 
          }}>
            <MobileTopNav />
          </Box> */}
          <Outlet />
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <MobileNav />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
