import { Grid } from '@mui/material';
import HomePost from './HomePost';
import { Homecarousel } from './HomeCarousel';
// import { HomeCreatePost } from "./HomeCreatePost";
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
// import { useLocation } from "react-router-dom";

export const Home = () => {
  // const { state } = useLocation();
  // const selectedCommunityId: string = state?.id;

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.home}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Grid container>
        <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
          <>
            <Homecarousel />
            {/* <HomeCreatePost /> */}
            <HomePost />
          </>
        </Grid>
        {/* <Grid item xs={6} md={4} lg={4.9} xl={4}>
          <HomeRightBar />
        </Grid> */}
      </Grid>
      {/* { {isLoading && <Loader />} } */}
    </>
  );
};
