const emailPattern =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const phoneNumber = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

export const validateEmail = (email: string, pattern?: any) => {
  if (pattern) {
    return pattern.test(email);
  }
  return emailPattern.test(email);
};
export const validatePhoneNumber = (phone: string, pattern?: any) => {
  if (pattern) {
    return pattern.test(phone);
  }
  return phoneNumber.test(phone);
};

export const validateEmailAddress = (value: string) => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(value);
};

export const validateMobileNumber = (value: string) => {
  const mobileNumberRegex = /^\d{10}$/;
  return mobileNumberRegex.test(value);
};
