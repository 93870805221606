import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Divider from '@mui/material/Divider';
import { BorderLinearProgress } from './styles';
import Box from '@mui/material/Box';

interface ITableProps {
  data: any;
}
export const ActivePlans = ({ data }: ITableProps) => {
  return (
    <Stack direction={'column'} spacing={1}>
      <Stack direction={'row'} spacing={2} alignItems={'baseline'}>
        <Typography variant="body2" sx={{ fontWeight: '500', pt: '1.4vh' }}>
          Active Plans
        </Typography>
        <Stack direction={'row-reverse'} sx={{ flexGrow: '1' }}>
          {/* <MoreVertIcon sx={{ color: "info.main", cursor: "pointer" }} /> */}
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold',
              color: '#2952A2',
              display: 'flex',
              cursor: 'pointer',
            }}
          >
            View All
            <ArrowForwardIcon sx={{ fontSize: '16px' }} />
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction={'column'} spacing={2}>
        {data.map((item: any, i: number) => (
          <Box key={i}>
            <Typography
              variant="caption"
              style={{ textTransform: 'capitalize' }}
            >
              {item.name}
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={item.value}
              name={item.name}
              sx={{ color: 'red' }}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};
