import { Box, Stack } from '@mui/material';
import { useLocation } from 'react-router-dom';
// import CircularProgress from "@mui/material/CircularProgress";

export const SmallLoader = () => {
  const location = useLocation();
  const isExploreCommunitiesPage = location.pathname === '/explore-communities';
  const isSwitchCardWidth =
    location.pathname === '/home' ||
    location.pathname === '/payments' ||
    location.pathname === '/payments' ||
    location.pathname === '/plans' ||
    location.pathname === '/users' ||
    location.pathname === '/posts' ||
    location.pathname === '/communities' ||
    location.pathname === '/profile/:id' ||
    location.pathname === '/bank-details' ||
    location.pathname === '/create-community' ||
    location.pathname === '/communities/build/:id' ||
    location.pathname === '/users' ||
    location.pathname === '/invitations' ||
    location.pathname === '/users' ||
    location.pathname === '/users' ||
    location.pathname === '/users';
  return (
    <Stack
      sx={{
        color: 'grey.500',
        display: 'flex',

        background: isExploreCommunitiesPage ? 'transparent' : '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30vh',
      }}
      spacing={2}
      direction="row"
    >
      {/* <CircularProgress color="inherit" /> */}
      <Box
        component={'img'}
        src={require('../../assets/images/loader.gif')}
        alt=""
        sx={{ width: isSwitchCardWidth ? '100%' : '50%' }}
      />
    </Stack>
  );
};

export default SmallLoader;
