import axios from 'axios';
import { BASE_URL_V2 } from '../configurations/url.config';

export const getNotifications = async (token: string, userId: any) => {
  try {
    const response = await axios.get(
      `${BASE_URL_V2}/push-notification/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const updateNotifications = async (
  token: string,
  notificationIds: string[],
  status: string
) => {
  try {
    const response = await axios.put(
      `${BASE_URL_V2}/push-notification`,
      {
        ids: notificationIds,
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
