import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Fab,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { StyledLogin } from './Login.styles';
import { BASE_URL } from '../../../configurations/url.config';
import { Helmet } from 'react-helmet';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../contexts/Auth.context';
import { getOtp, verifyOtp } from '../../../services/otp.service';
import {
  validateEmailAddress,
  validateMobileNumber,
} from '../../../utils/helper';
import { metaConfig } from '../../../utils/constants';

import {
  EMPTY_STRING,
  INACTIVE_USER_MSG,
  INVALID_OTP,
  OTP_LIMIT_REACHED,
  OTP_RESENT,
  OTP_SEND_FAILED,
  OTP_SENT,
  PASSWORD_INVALID,
  USER_NOT_FOUNT_MSG,
} from '../../../utils/UdfMessages';
import GoogleIcon from './../../../assets/images/icons8-google.svg';
import AppleIcon from './../../../assets/images/icons8-apple.svg';

// import {
//   // CredentialResponse,
//   useGoogleLogin,
//   CodeResponse,
//   // googleLogout,
//   // GoogleLogin,
// } from "@react-oauth/google";
import { auth, google, apple } from '../../../configurations/fireBase';
import { signInWithPopup } from '@firebase/auth';
import { getDeviceToken } from '../../../services/pushNotification/pushNotification.service';

// interface Profile {
//   // Define the structure of the profile data
//   id: string;
//   name: string;
//   email: string;
//   // Add more properties as needed
// }
// interface User {
//   access_token: string;
// }

// interface SocialProfile {
//   // Define the structure of the profile data
//   id: string;
//   name: string;
//   email: string;
//   given_name: string;
//   family_name: string;
//   hd: string;
//   locale: string;
//   picture: string;
//   verified_email: string;
//   // Add more properties as needed
// }
// interface User {
//   access_token: string;
// }

export const Login = () => {
  const authContex = useContext(AuthContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingSocialGoogle, setLoadingSocialGoogle] = useState(false);
  const [loadingSocialFacebook, setLoadingSocialFacebook] = useState(false);
  const [showPassword] = useState(false);
  const [error, setError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);
  const [enableGetOTP, setEnableGetOTP] = useState(false);
  const [otpReceived, setOtpReceived] = useState(false);
  const [otpSuccessMessage, setOtpSuccessMessage] = useState('');
  // const [enteredOpt, setEnteredOtp] = useState("");
  const [resendCount, setResendCount] = useState(0);
  const [remainingTime, setRemainingTime] = useState(60);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isBlocked] = useState(false);
  const [actionButton, setActionButton] = useState('Login');
  const [information] = React.useState('');
  // const [otp6, setOtp6] = useState("");
  // const [generatedOTP, setGeneratedOTP] = useState<Number>(0);
  const NUMBER_3 = 3;

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Email/Mobile Number is required')
      .test(
        'username',
        'Invalid Email or Mobile Number',
        (value) => validateEmailAddress(value) || validateMobileNumber(value)
      ),
    password: Yup.string()
      .required('OTP is required')
      .max(40, 'Password must not exceed 40 characters'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleNumberChange = (e: any) => {
    const value = e.target.value;
    if (/^\d+$/.test(value) && value.length === 10) {
      setPhoneNumber(value);
      setEnableGetOTP(true);
      setValidPhoneNumber(true);
      setResendCount(0);
      setTimerRunning(false);
      setRemainingTime(60);
      setOtpReceived(false);
      setOtpSuccessMessage('');
      setError('');
    } else {
      setEnableGetOTP(false);
      setValidPhoneNumber(false);
      setError(EMPTY_STRING);
      setActionButton('Login');
      // setGeneratedOTP(0);
      setOtpSuccessMessage(EMPTY_STRING);
    }
  };

  const generateOTP = async () => {
    if (resendCount < NUMBER_3) {
      const { data, status } = await getOtp(phoneNumber);
      // console.log(data);
      let number = phoneNumber.replace(/^.{6}/g, '******');
      setError('');
      if (data && status === 200) {
        setOtpReceived(true);
        setActionButton('Verify OTP');
        setResendCount(resendCount + 1);
        setTimerRunning(true);
        // setGeneratedOTP(data?.opt);
        //console.log(generatedOTP);
        if (resendCount === 0) {
          setOtpSuccessMessage(OTP_SENT.replace('#PHONE_NUMBER', number));
        } else {
          setOtpSuccessMessage(OTP_RESENT.replace('#PHONE_NUMBER', number));
        }
      } else {
        setOtpSuccessMessage('');
        setError(OTP_SEND_FAILED.replace('#PHONE_NUMBER', number));
      }
    } else if (resendCount === 3) {
      setOtpSuccessMessage(OTP_LIMIT_REACHED);
      setTimerRunning(false);
    }
  };

  useEffect(() => {
    if (timerRunning && remainingTime > 0) {
      const interval = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setTimerRunning(false);
      setRemainingTime(60);
    }
  }, [timerRunning, remainingTime]);

  const onSubmit = async (data: any) => {
    setLoading(true);
    const value = data.password;
    try {
      if (!isNaN(value) && value.toString().length === 6) {
        // Call verify OTP to verify
        const verifyResponse = await verifyOtp(phoneNumber, value);

        if (verifyResponse.status === 200) {
          // Request notification permission, but allow login even if denied
          const permission = await Notification.requestPermission();

          let token = null;
          if (permission === 'granted') {
            // Notification permission granted, get the device token
            token = await getDeviceToken();
          }

          // Proceed with autoLogin regardless of notification permission
          const response = await authContex.autoLogin(phoneNumber, '', token);
          handleLoginResponse(response);
        } else {
          // Handle invalid OTP
          setOtpSuccessMessage(EMPTY_STRING);
          setError(INVALID_OTP);
        }
      } else {
        // Handle email flow: login
        const response: any = await authContex.login(data);
        handleLoginResponse(response);

        // Check for login errors
        if (response?.response?.status === 401) {
          setError(PASSWORD_INVALID);
        } else if (response?.response?.status === 404) {
          setError('User not Found, check your Account Credentials');
        }
      }
    } catch (error) {
      console.error('An error occurred during submission:', error);
      setError('An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false); // Ensure loading is set to false in both success and error cases
    }
  };

  const handleLoginResponse = async (response: any) => {
    if (response?.status === 200) {
      const { user, adminCommunities } = response?.data;
      if (
        (adminCommunities && adminCommunities.length > 0) ||
        user?.role === 'SUPERADMIN'
      ) {
        navigate('/home', { state: { id: user?.community } });
      } else if (!adminCommunities || adminCommunities.length === 0) {
        navigate('/explore-communities', {
          state: { id: 2, data: user },
        });
      } else if (user?.community) {
        navigate('/dashboard', { state: { id: 3, data: user } });
      }
    } else if (response.status === 403) {
      setError(INACTIVE_USER_MSG);
      setOtpSuccessMessage(EMPTY_STRING);
    } else if (response?.status === 404) {
      setError(USER_NOT_FOUNT_MSG);
      navigate('/register', { state: { id: 1, phoneNumber: phoneNumber } });
    }
    setLoading(false);
  };

  useEffect(() => {
    requestPermissionAndStoreToken();
  }, []);

  const requestPermissionAndStoreToken = async () => {
    try {
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        console.log('Notification permission granted.');

        const token = await getDeviceToken();

        if (token) {
          console.log('Device token received:', token);

          localStorage.setItem('deviceToken', token);
          console.log('Device token stored in local storage');
        } else {
          console.warn('Device token not available.');
        }
      }
    } catch (error) {
      console.error(
        'Error while requesting permission or getting device token:',
        error
      );
    }
  };

  // const usernameValue = watch("username");
  // const isValidUsername = /^\d+$/.test(usernameValue);

  // const [user, setUser] = useState<CodeResponse | null>(null);
  // const [profile, setProfile] = useState<Profile | null>(null);
  // const [accessToken, setAccessToken] = useState<string | null>(null);

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse: CodeResponse) => {
  //     // Extract the access token from your data and set it in your state
  //     const { access_token }: any = codeResponse;
  //     setAccessToken(access_token);
  //   },
  //   onError: (error: any) => console.log("Login Failed:", error),
  // });

  // useEffect(() => {
  //   if (accessToken) {
  //    // console.log("Access Token is available:", accessToken);
  //     axios
  //       .get(`https://www.googleapis.com/oauth2/v1/userinfo`, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           Accept: "application/json",
  //         },
  //       })
  //       .then((res) => {
  //        const socialProfile = res.data as SocialProfile;
  //         //console.log("User Info:", socialProfile);
  //         const autoCreateData = {
  //           firstName: socialProfile.given_name,
  //           lastName: socialProfile.family_name,
  //           emailId: socialProfile.email,
  //           isEmailVerified: socialProfile.verified_email,
  //           avatar: socialProfile.picture

  //         };

  //         axios
  //           .post(`${BASE_URL}/auth/social-login`, autoCreateData)
  //           .then(async(response) => {
  //            const {user} = response?.data
  //           // console.log("User Info:", user)
  //             const autologinResponse = await authContex.autoLogin("",user?.emailId);
  //            handleLoginResponse(autologinResponse);
  //           })
  //           .catch((error) => {
  //             console.error("Auto login error:", error);
  //           });
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [accessToken]);

  const SocialLogin = async (login: any) => {
    const response: any = await signInWithPopup(auth, login);
    const socialProfile = response['_tokenResponse'];
    const user = response.user;
    let deviceToken = localStorage.getItem('deviceToken');
    if (!deviceToken) {
      deviceToken = await getDeviceToken();
      if (deviceToken) {
        localStorage.setItem('deviceToken', deviceToken);
      } else {
        console.warn('Device token is unavailable or permission is denied.');
      }
    }

    const autoCreateData = {
      firstName: socialProfile.firstName,
      lastName: socialProfile.lastName,
      emailId: socialProfile.email,
      isEmailVerified: socialProfile.emailVerified,
      avatar: socialProfile.photoUrl,
      phoneNumber: user?.phoneNumber,
      deviceToken: deviceToken || null,
    };

    // console.log(autoCreateData)
    axios
      .post(`${BASE_URL}/auth/social-login`, autoCreateData)
      .then(async (response) => {
        const { user } = response?.data;

        const autoLoginResponse = await authContex.autoLogin(
          '',
          user?.emailId,
          ''
        );
        handleLoginResponse(autoLoginResponse);
      })
      .catch((error) => {
        //console.info("Auto login error:", error?.response?.data?.error);
        setError(error?.response?.data?.error);
      });
  };

  const handleGoogleLogin = async () => {
    setLoadingSocialGoogle(true);
    SocialLogin(google);
  };

  const handleFacebookLogin = async () => {
    setLoadingSocialFacebook(true);
    SocialLogin(apple);
  };

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.loginPage}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.description} />
        <meta name="keywords" content={metaConfig.description} />
      </Helmet>
      <StyledLogin>
        <Grid container>
          <Grid
            item
            md={7}
            sx={{
              background: 'linear-gradient(to right bottom, #50a1ca,#469EA7)',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ height: '100%', width: '100%', justifyContent: 'center' }}
            >
              <Box
                component={'img'}
                src={require('./../../../assets/images/registerbckg.png')}
                alt=""
              />
              <Stack alignItems={'center'} sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    fontSize: '28px',
                    fontWeight: '700',
                    color: '#ffffff',
                    fontFamily: 'Inter',
                  }}
                >
                  Create or Join Communities
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#ffffff',
                    fontFamily: 'Montserrat',
                    pt: 1,
                  }}
                >
                  Get ready to explore, create, and embrace the limitless
                  possibilities that await you here.{' '}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack
              sx={{
                height: '100vh',
                overflow: 'auto',
                justifyContent: {
                  xs: 'flex-start',
                  sm: 'flex-start',
                  md: 'center',
                  lg: 'center',
                },
              }}
              className="login-form-container"
              alignItems={'center'}
            >
              {information ? (
                <Typography
                  sx={{
                    color: 'green',
                    fontWeight: '700',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                >
                  {information}
                </Typography>
              ) : (
                ''
              )}
              <Box
                component={'img'}
                src={require('./../../../assets/images/RegisterMBg.png')}
                sx={{
                  height: '180px',
                  width: '100%',
                  display: { xs: 'block', md: 'none' },
                }}
                alt=""
              ></Box>
              <Box
                component={'form'}
                onSubmit={onSubmit}
                sx={{
                  width: '328px',
                  textAlign: 'center',
                  mt: { xs: '5vh', sm: '5vh', md: '10vh', lg: '10vh' },
                }}
              >
                <Box
                  sx={{}}
                  component={'img'}
                  src={require('./../../../assets/images/Communn-new-logo.png')}
                  alt=""
                  width={'75%'}
                />

                <Typography
                  sx={{
                    color: '#000000',
                    fontSize: '14px',
                    textAlign: 'center',
                  }}
                >
                  New user? Enter mobile number please.
                </Typography>
                <Stack
                  spacing={2}
                  sx={{ pt: 2, backgroundColor: 'white' }}
                  className="login-form"
                >
                  <TextField
                    sx={{ backgroundColor: 'white' }}
                    size="small"
                    label="Enter Mobile No"
                    fullWidth
                    {...register('username')}
                    error={errors.username ? true : false}
                    helperText={
                      errors.username ? (errors.username.message as string) : ''
                    }
                    onChange={handleNumberChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            cursor: 'pointer',
                            backgroundColor: '#ffffff',
                            alignContent: 'center',
                            borderRadius: '100px',
                          }}
                        >
                          <Box
                            display="flex"
                            sx={{
                              display: validPhoneNumber ? 'block' : 'none',
                            }}
                          >
                            <Stack direction="row">
                              <Divider
                                orientation="vertical"
                                sx={{ m: 1, height: 24 }}
                              />

                              <Button
                                size="small"
                                sx={{
                                  backgroundColor: '#3b9b7f',
                                  textTransform: 'capitalize',
                                  color: '#ffffff',
                                  borderRadius: '100px',
                                  height: '30px',
                                  mt: '5px',
                                  mr: '-15px',
                                  ':hover': {
                                    backgroundColor: '#3b9b7f',
                                    color: '#ffffff',
                                  },
                                }}
                                disabled={
                                  !enableGetOTP || isBlocked || timerRunning
                                }
                                onClick={generateOTP}
                              >
                                {timerRunning
                                  ? `Resend OTP in ${remainingTime}s`
                                  : resendCount === 0
                                    ? `Get OTP`
                                    : `Resend OTP`}
                              </Button>
                            </Stack>
                          </Box>
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: '100px',
                        backgroundColor: '#ffffff',
                      },
                    }}
                  />

                  <TextField
                    size="small"
                    label="Enter OTP"
                    defaultValue=""
                    fullWidth
                    type={showPassword ? 'text' : 'password'}
                    {...register('password')}
                    error={errors.password ? true : false}
                    helperText={
                      errors.password ? (errors.password.message as string) : ''
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{
                            cursor: 'pointer',
                            backgroundColor: '#E5E3EF',
                            borderRadius: '100px',
                          }}
                        >
                          <Stack
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                          ></Stack>
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: '30px',
                        backgroundColor: '#ffffff',
                      },
                    }}
                  ></TextField>

                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      boxShadow: 'none',
                      borderRadius: '30px',
                      background:
                        'linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)',
                      textTransform: 'capitalize',
                    }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    {' '}
                    {loading ? (
                      <CircularProgress size={24} sx={{ color: 'white' }} />
                    ) : (
                      `${actionButton}`
                    )}
                  </Button>

                  <Stack
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ color: 'red' }}
                  >
                    {' '}
                    {error ? (
                      <Typography
                        sx={{
                          fontSize: { xs: '15px', md: '16px' },
                          textAlign: 'center',
                        }}
                      >
                        {error}
                      </Typography>
                    ) : null}{' '}
                  </Stack>
                  {otpReceived && (
                    <Typography
                      sx={{
                        color: 'green',
                        textAlign: 'center',
                        fontSize: { xs: '15px', md: '16px' },
                      }}
                      className="success-message"
                    >
                      {otpSuccessMessage}
                    </Typography>
                  )}
                  <Divider sx={{ pt: 1 }}>
                    <Box component={'span'}>OR Continue With</Box>
                  </Divider>
                  <Box sx={{ pt: 1 }}>
                    <Stack
                      direction={'row'}
                      spacing={2}
                      justifyContent={'center'}
                      alignContent={'center'}
                    >
                      <Fab
                        size="medium"
                        disabled={loadingSocialFacebook}
                        sx={{ backgroundColor: 'white' }}
                      >
                        {loadingSocialGoogle ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              background:
                                'linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)',
                              color: 'white',
                              borderRadius: '100px',
                            }}
                          />
                        ) : (
                          <Box
                            onClick={handleGoogleLogin}
                            component={'img'}
                            src={GoogleIcon}
                            sx={{ width: '30px', height: '30px' }}
                          />
                        )}
                      </Fab>

                      {/* <Fab size="medium" disabled={loadingSocialGoogle} sx={{ backgroundColor: "white" }}>
                        {loadingSocialFacebook ? (
                          <CircularProgress size={24} sx={{
                            background:
                              "linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)", color: 'white', borderRadius: '100px',
                          }} />
                        ) : (
                          <Box
                            onClick={() => handleFacebookLogin()}
                            component={"img"}
                            src={require("./../../../assets/images/facebook.png")}
                          />
                        )}
                      </Fab> */}
                      <Fab
                        size="medium"
                        disabled={loadingSocialGoogle}
                        sx={{ backgroundColor: 'white' }}
                      >
                        {loadingSocialFacebook ? (
                          <CircularProgress
                            size={24}
                            sx={{
                              background:
                                'linear-gradient(140deg, #50A1CA 0%, #3B9B7F 100%)',
                              color: 'white',
                              borderRadius: '100px',
                            }}
                          />
                        ) : (
                          <Box
                            onClick={() => handleFacebookLogin()}
                            component={'img'}
                            src={AppleIcon}
                            sx={{ width: '30px', height: '30px' }}
                          />
                        )}
                      </Fab>
                    </Stack>

                    <Box
                      sx={{
                        color: '#9A9A9A',
                        fontSize: '12px',
                        pt: 2,
                        textAlign: 'center',
                        mt: 2,
                      }}
                    >
                      By clicking Get OTP, you agree to Onecommunn's <br></br>
                      <Link
                        href="https://onecommunn.com/terms-of-uses"
                        target="_blank"
                        style={{ color: '#9A9A9A' }}
                      >
                        User Agreement,
                      </Link>
                      <Link
                        href="https://onecommunn.com/privacy-policy"
                        target="_blank"
                        style={{ color: '#9A9A9A' }}
                      >
                        {' '}
                        Privacy Policy,
                      </Link>
                      and
                      <Link
                        href="https://onecommunn.com/privacy-policy"
                        target="_blank"
                        style={{ color: '#9A9A9A' }}
                      >
                        {' '}
                        Cookie Policy.
                      </Link>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </StyledLogin>
    </>
  );
};

export default Login;
