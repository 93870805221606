import {
  Button,
  Card,
  Stack,
  Typography,
  Tooltip,
  Avatar,
} from '@mui/material';
import { ICommunity } from '../../../models/communities.modal';
// import { useNavigate } from "react-router-dom";
import { KingIcon } from '../../../assets/icons';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';
import { USER_APP_BASE_URL } from '../../../configurations/url.config';
// import { useSelector } from "react-redux";
// import store from "../../../store";
import { useSnackbar } from 'notistack';
import InvitesandJoinPreview from '../CommunityPreview/InvitesandJoinPreview';

export const JoinedAllCommunities = (community: ICommunity) => {
  // const state = store.getState();
  // const loggedInUserData = useSelector(() => {
  //   return state?.loggedInUser;
  // });
  // const adminPhone =loggedInUserData.user?.phoneNumber;

  const [copied, setCopied] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [link] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  // console.log(community);
  // const navigate = useNavigate();

  const handlePreviewCommunity = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCopyLink = () => {
    const labelContent = `${USER_APP_BASE_URL}/communities/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}/about`;

    navigator.clipboard
      .writeText(labelContent)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
        enqueueSnackbar('Link copied successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      })
      .catch((error) => {
        console.error('Error copying text: ', error);
      });
  };

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}/about`
  )}`;

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}/about`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}/about`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${community?.name?.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${community?._id}/about`
  )}`;

  const navigateToUserFlow = () => {
    const token = localStorage.getItem('access-token');
    const id = community?._id;
    const redirectPath = encodeURIComponent('/home');
    const url = `${USER_APP_BASE_URL}/login?communityId=${id}&token=${token}&redirect=${redirectPath}`;
    window.open(url, '_blank');
  };

  const navigateToUserSubscription = () => {
    const token = localStorage.getItem('access-token');
    const id = community?._id;
    const redirectPath = encodeURIComponent('/subscriptions');
    const url = `${USER_APP_BASE_URL}/login?communityId=${id}&token=${token}&redirect=${redirectPath}`;
    window.open(url, '_blank');
  };

  // console.log(community, "Joined Communities")

  return (
    <>
      <Stack spacing={2} sx={{ p: 1 }}>
        <Card
          sx={{
            textAlign: 'center',
            borderRadius: '10px',
            p: 2,
            m: 1,
            minHeight: 'fit-content',
          }}
        >
          <Stack
            direction={'row'}
            alignContent={'space-evenly'}
            justifyContent={'space-between'}
          >
            <Stack direction={'row'} alignContent={'center'}>
              {' '}
              {community?.type === 'PRIVATE' ? (
                <LockOutlinedIcon sx={{ color: '#838383', fontSize: '18px' }} />
              ) : (
                <Stack sx={{ height: '25px' }}></Stack>
              )}
              {community?.collectSubscription === 'YES' ? (
                <KingIcon sx={{ color: '#838383' }} />
              ) : (
                <Stack sx={{ height: '25px' }}></Stack>
              )}
            </Stack>

            <Stack>
              <BootstrapTooltip title="Info" placement="left">
                <InfoOutlinedIcon
                  onClick={handlePreviewCommunity}
                  sx={{ color: '#838383', fontSize: '18px', cursor: 'pointer' }}
                ></InfoOutlinedIcon>
              </BootstrapTooltip>
            </Stack>
          </Stack>
          {/* Community Logo */}
          <Stack display="flex" alignItems="center" justifyContent="center">
            <Avatar
              className="product--image"
              src={community?.logo}
              alt="community-profile"
              sx={{
                objectFit: 'cover',
                width: '60px',
                height: '60px',
                borderRadius: '100px',
              }}
            />
          </Stack>

          {/* Render name with conditional check */}
          {community?.name ? (
            <Typography
              variant="h2"
              sx={{
                fontSize: '12px',
                color: '#4D4C4C',
                fontWeight: '600',
                mt: 0.5,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%', // Ensure the text doesn't overflow the container
              }}
            >
              {community?.name?.length > 20
                ? `${community?.name.slice(0, 20)}...`
                : community?.name}
            </Typography>
          ) : (
            <Stack sx={{ height: '15px' }}></Stack>
          )}

          {/* Render city with conditional check */}
          {community?.city ? (
            <Typography
              sx={{ fontSize: '10px', color: '#878787', fontWeight: '500' }}
            >
              {community?.city?.length > 20
                ? `${community?.city?.slice(0, 20)}...`
                : community?.city}
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: '10px', color: '#878787', fontWeight: '500' }}
            >
              No city
            </Typography>
          )}

          {/* Render members count with conditional check */}
          {community?.members?.length !== undefined ? (
            <Typography
              sx={{ fontSize: '11px', color: '#353535', fontWeight: '500' }}
            >
              {community?.members?.length}
            </Typography>
          ) : (
            <Stack sx={{ height: '15px' }}></Stack>
          )}
          <Stack
            direction={'row'}
            alignContent={'center'}
            justifyContent={'center'}
            spacing={2}
            mt={1}
          >
            {community?.type === 'PRIVATE' &&
            community?.collectSubscription === 'YES' ? (
              <Button
                variant="contained"
                size="small"
                sx={{
                  background:
                    'linear-gradient(to right bottom, #50a1ca, #3b9b7f)',
                  textTransform: 'capitalize',
                  height: '25px',
                  color: '#ffffff',
                  boxShadow: 'none',
                }}
                // onClick={() => navigate(`/home`, { state: { id: community.id } })}
                onClick={navigateToUserFlow}
              >
                <Typography
                  sx={{ color: '#ffffff', fontSize: '13px', fontWeight: '500' }}
                >
                  Joined
                </Typography>
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                sx={{
                  background:
                    'linear-gradient(to right bottom, #50a1ca, #3b9b7f)',
                  textTransform: 'capitalize',
                  height: '25px',
                  color: '#ffffff',
                  boxShadow: 'none',
                }}
                // onClick={() => navigate(`/home`, { state: { id: community.id } })}
                onClick={navigateToUserFlow}
              >
                <Typography
                  sx={{ color: '#ffffff', fontSize: '13px', fontWeight: '500' }}
                >
                  Joined
                </Typography>
              </Button>
            )}
          </Stack>
        </Card>
      </Stack>

      <InvitesandJoinPreview
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        logo={community?.logo}
        id={community?._id}
        title={community?.name}
        city={community?.city}
        status={community?.status}
        link={link}
        members={community?.members?.length || []}
        totalmembers={community?.totalMembers}
        type={community?.type}
        description={community?.description}
        handleCopyLink={handleCopyLink}
        copied={copied}
        whatsappShareUrl={whatsappShareUrl}
        facebookShareUrl={facebookShareUrl}
        linkedinShareUrl={linkedinShareUrl}
        twitterShareUrl={twitterShareUrl}
        coverImageSrc={community?.banner}
      />
    </>
  );
};
