/* eslint-disable jsx-a11y/img-redundant-alt */
import { Avatar, Box, CardContent, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ShareCommunity from './ShareCommunity';
import { USER_APP_BASE_URL } from '../../configurations/url.config';
import { useSnackbar } from 'notistack';
import { useLocation, useParams } from 'react-router-dom';
export const CommunityCard = () => {
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity.selectedCommunity
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [link, setLink] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [copied, setCopied] = useState(false);
  const handleJoinCommunity = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCopyLink = () => {
    if (selectedCommunity?.status === 'PUBLISHED') {
      const labelContent = `${USER_APP_BASE_URL}/communities/${selectedCommunity?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${selectedCommunity?._id}/about`;

      navigator.clipboard
        .writeText(labelContent)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
          enqueueSnackbar('Link copied successfully', { variant: 'success' });
        })
        .catch((error) => {
          console.error('Error copying text: ', error);
        });
    } else {
      // Handle the case when the status is not "PUBLISHED"
      setLink(`/communities/build/${selectedCommunity?._id}`);
      enqueueSnackbar('Cannot copy link  for Non-Published Communities', {
        variant: 'error',
      });
    }
  };

  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${selectedCommunity?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${selectedCommunity?._id}/about`
  )}`;
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${selectedCommunity?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${selectedCommunity?._id}/about`
  )}`;
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${selectedCommunity?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${selectedCommunity?._id}/about`
  )}`;
  const linkedinShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
    `${USER_APP_BASE_URL}/communities/${selectedCommunity?.name.trim().toLocaleLowerCase().replace(/\s+/g, '-')}/${selectedCommunity?._id}/about`
  )}`;

  const { id, name } = useParams();
  const location = useLocation();
  const pathMatches = location.pathname === `/posts/user/${name}/${id}`;

  const communityName =
    (selectedCommunity?.name
      ? selectedCommunity?.name
      : selectedCommunity?.selectedCommunity?.name) || '';
  const selectedCommunityName =
    communityName.length > 45
      ? communityName.substring(0, 45) + '...'
      : communityName;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          mt: 1,
          backgroundColor: 'white',
          borderRadius: '5px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '-1vw 1vw -1vw 0vw',
          }}
        >
          <Avatar alt={selectedCommunity?.logo} src={selectedCommunity?.logo} />

          <CardContent sx={{ marginLeft: '0vw' }}>
            <Typography
              color="#2952A2"
              sx={{ fontSize: { xs: '13px', md: '17px' }, fontWeight: 'bold' }}
            >
              {pathMatches ? name : selectedCommunityName}
            </Typography>
            <Typography
              sx={{ fontSize: { xs: '11px', md: '13px' }, color: '#7A7A7A' }}
            >
              {selectedCommunity?.city}
            </Typography>
          </CardContent>
        </Box>
        <Box>
          <Tooltip title="Share">
            <ShareOutlinedIcon
              onClick={handleJoinCommunity}
              sx={{ color: '#3C5AA0', fontSize: '23px', cursor: 'pointer' }}
            />
          </Tooltip>
        </Box>
      </Box>

      <ShareCommunity
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        dpSrc={selectedCommunity?.logo ?? ''}
        title={selectedCommunity?.name || ''}
        city={selectedCommunity?.city || ''}
        members={selectedCommunity?.members || []}
        communityId={selectedCommunity?._id || ''}
        handleCopyLink={handleCopyLink}
        link={link}
        status={selectedCommunity?.status}
        copied={copied}
        whatsappShareUrl={whatsappShareUrl}
        facebookShareUrl={facebookShareUrl}
        linkedinShareUrl={linkedinShareUrl}
        twitterShareUrl={twitterShareUrl}
        coverImageSrc={selectedCommunity?.banner ?? ''}
      />
    </>
  );
};
