import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { useQrCode } from '../../hooks/useQrCode';

interface UrlProps {
  id: string;
  url: string;
}

const QrCode: React.FC<UrlProps> = ({ id, url }) => {
  const [qrCode, setQrCode] = useState('');
  const { getQrCode } = useQrCode();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQrCode = async () => {
      try {
        const response = await getQrCode(id, url);
        if (response && response.status === 200) {
          setQrCode(response.data.qr_code);
        } else {
          console.error('Failed to fetch QR code');
        }
      } catch (error) {
        console.error('Error fetching QR code:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchQrCode();
  }, [id, url]);

  return (
    <>
      <>
        {loading ? (
          <Skeleton animation="wave" width="40%" sx={{ height: 215 }} />
        ) : (
          <img src={qrCode} alt="QR Code" width="45%" />
        )}
      </>
    </>
  );
};

export default QrCode;
