import React from 'react';
import { Card, CardMedia, Paper, Stack } from '@mui/material';

export const NoActivity = () => {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Stack
          spacing={2}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          sx={{ paddingBottom: '10px', p: 2 }}
        >
          <Card elevation={0}>
            <CardMedia
              component="img"
              alt="No posts"
              image={require('./../../assets/icons/svg-icons/activities.png')}
              sx={{
                objectFit: 'cover',
                width: '100%', // Set the width to 100% to fill the container
                height: '60vh', // Set the height to 100% to fill the container
              }}
            />
          </Card>
        </Stack>
      </Paper>
    </>
  );
};
