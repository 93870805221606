import React from 'react';
import FaqTab from '../../components/FAQ/FaqDisplay';
import AddTab from '../../components/FAQ/AddQuestion';

interface ContentDisplayProps {
  selectedTab: number;
  addNewFAQ: (newFAQ: { question: string; answer: string }) => void;
}

const ContentDisplay: React.FC<ContentDisplayProps> = ({
  selectedTab,
  addNewFAQ,
}) => {
  return (
    <>
      {selectedTab === 0 && <FaqTab />}
      {selectedTab === 2 && <AddTab addNewFAQ={addNewFAQ} />}
    </>
  );
};

export default ContentDisplay;
