import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';

import {
  singleFileUpload,
  updateSingleFileUpload,
  uploadCommunityFile,
  uploadSingleFile,
} from '../services/fileHandler.service';

export const useFileHandler = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading] = useState<boolean>(true);
  const [uploadSingle] = useState<string>('');

  const singleFileUploader = async (formData: any, name: string) => {
    try {
      const response = await uploadSingleFile(getAccessToken(), formData, name);
      console.log('Response:', response); // Check the response object
      if (response?.status === 200) {
        enqueueSnackbar(' File Uploaded successfully', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Failed to Upload File', { variant: 'error' });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while Creating the Community', {
        variant: 'error',
      });
    }
  };
  const uploadCommunitySingleFile = async (
    formData: any,
    name: string,
    id: string
  ) => {
    try {
      const response = await uploadCommunityFile(
        getAccessToken(),
        formData,
        name,
        id
      );
      console.log('Response:', response); // Check the response object

      if (response.status === 200) {
        enqueueSnackbar('Upload successful', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Failed to Upload File', { variant: 'error' });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('Couldn’t upload', {
        variant: 'error',
      });
    }
  };
  const uploadfile = async (file: FileList | null, location: string) => {
    try {
      const response = await singleFileUpload(getAccessToken(), file, location);
      console.log('Response:', response); // Check the response object

      if (response.status === 200) {
        // enqueueSnackbar("Upload successful", {
        //   variant: "success",
        // });
      } else {
        // enqueueSnackbar("Failed to Upload File", { variant: "error" });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('Couldn’t upload', {
        variant: 'error',
      });
    }
  };
  const updateUploadfile = async (
    file: FileList | null,
    _id: string,
    location: string
  ) => {
    try {
      const response = await updateSingleFileUpload(
        getAccessToken(),
        file,
        _id,
        location
      );
      console.log('Response:', response); // Check the response object

      if (response.status === 200) {
        // enqueueSnackbar("Upload successful", {
        //   variant: "success",
        // });
      } else {
        // enqueueSnackbar("Failed to Upload File", { variant: "error" });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('Couldn’t upload', {
        variant: 'error',
      });
    }
  };

  return {
    isLoading,
    uploadSingle,
    singleFileUploader,
    uploadCommunitySingleFile,
    uploadfile,
    updateUploadfile,
  };
};
