import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Button,
  TextField,
  Box,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

interface AddTabProps {
  addNewFAQ: (newFAQ: { question: string; answer: string }) => void;
}

const AddTab: React.FC<AddTabProps> = ({ addNewFAQ }) => {
  const [questions, setQuestions] = useState([
    { id: 1, question: '', answer: '' },
  ]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  useEffect(() => {
    const areAllQuestionsAnswered = () => {
      return questions.every(
        (item) => item.question.trim() !== '' && item.answer.trim() !== ''
      );
    };

    setIsSaveDisabled(!areAllQuestionsAnswered());
    setIsAddButtonDisabled(!areAllQuestionsAnswered());
  }, [questions]);

  const handleAddQuestion = () => {
    const newId = questions.length + 1;
    const newQuestion = { id: newId, question: '', answer: '' };
    setQuestions([...questions, newQuestion]);
    setIsAddButtonDisabled(true);
  };

  const handleDeleteQuestion = (idToDelete: number) => {
    const updatedQuestions = questions.filter((item) => item.id !== idToDelete);
    setQuestions(updatedQuestions);
  };

  return (
    <>
      <Accordion sx={{ pl: { xs: '10px' }, pr: { xs: '10px' } }}>
        <AccordionSummary sx={{ minHeight: '40px', maxHeight: '40px' }}>
          <Typography
            variant="h6"
            sx={{ fontSize: '13px', fontFamily: 'Roboto' }}
          >
            Add New FAQ Here
          </Typography>
        </AccordionSummary>
        <div
          style={{
            height: questions.length === 1 ? 'auto' : '400px',
            overflow: 'hidden',
          }}
        >
          <AccordionDetails
            style={{
              height: '100%',
              overflowY: 'scroll',
              marginRight: '-17px',
            }}
          >
            <Box
              sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}
            >
              {questions.map((item, index) => (
                <div key={item.id}>
                  <Grid item sx={{ display: 'flex', flexDirection: 'row' }}>
                    <TextField
                      label={`Question ${index + 1}`}
                      value={item.question}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        const updatedQuestions = [...questions];
                        updatedQuestions[index].question = e.target.value;
                        setQuestions(updatedQuestions);
                      }}
                      fullWidth
                      margin="normal"
                      sx={{ width: '95%' }}
                      inputProps={{
                        style: {
                          fontSize: '13px',
                          padding: '8px',
                          marginTop: '6px',
                          minHeight: '15px',
                          maxHeight: '15px',
                          fontFamily: 'Roboto',
                          overflow: 'hidden',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          marginTop: '-8px',
                          fontSize: '13px',
                          fontFamily: 'Roboto',
                        },
                      }}
                    />

                    {index !== 0 && (
                      <Grid item sx={{ mr: '-10px' }}>
                        <IconButton
                          onClick={() => handleDeleteQuestion(item.id)}
                          sx={{ mt: '10px', mr: '10px' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                  <TextField
                    label={`Answer ${index + 1}`}
                    value={item.answer}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const updatedQuestions = [...questions];
                      updatedQuestions[index].answer = e.target.value;
                      setQuestions(updatedQuestions);
                    }}
                    fullWidth
                    margin="normal"
                    sx={{ width: '95%' }}
                    multiline
                    rows={2}
                    inputProps={{
                      style: {
                        fontSize: '13px',
                        padding: '1px',
                        marginTop: '6px',
                        fontFamily: 'Roboto',
                        overflow: 'hidden',
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: '13px',
                        fontFamily: 'Roboto',
                      },
                    }}
                  />
                </div>
              ))}
            </Box>
          </AccordionDetails>
        </div>
      </Accordion>
      <Grid container justifyContent="space-between" alignItems="center" mt={2}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={isSaveDisabled}
          onClick={() => {
            console.log('Saved:', questions);
            addNewFAQ({
              question: questions[0].question,
              answer: questions[0].answer,
            });
          }}
        >
          Save
        </Button>
        <IconButton onClick={handleAddQuestion} disabled={isAddButtonDisabled}>
          <AddIcon />
        </IconButton>
      </Grid>
    </>
  );
};

export default AddTab;
