// import React from "react";

import { Helmet } from 'react-helmet';
import { metaConfig } from '../../utils/constants';
import { PaymentReports } from './Reports';
// import DataTables from "../sub-components/Table";
// import { PaymentDetails } from "./PaymentDetails";

export const PaymentReport = () => {
  return (
    <>
      <Helmet>
        <title>{metaConfig.title.paymentReports}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <div>
        <PaymentReports />
      </div>
    </>
  );
};
