import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const faqData = [
  {
    question: 'Can I change my community settings?',
    answer:
      'Yes, you have the flexibility to change the privacy settings of your community. If you initially created a public community but later prefer to make it private, or if you want to transition from a private community to a public one, you can modify the settings accordingly. Access the community management dashboard or settings to make the desired changes.....',
    _id: '',
  },
  {
    question: 'Questions 2',
    answer: 'Aws ,,,....',
    _id: '',
  },
  {
    question: 'Questions 3',
    answer: 'Aws ,,,....',
    _id: '',
  },
  {
    question: 'Questions 4',
    answer: 'Aws ,,,....',
    _id: '',
  },
  {
    question: 'Questions 5',
    answer: 'Aws ,,,....',
    _id: '',
  },
];

const FaqTab = () => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        borderRadius: '6px',
        mt: 2,
        display: { xs: '0', sm: '600', md: '900', lg: '1200', xl: '1536' },
      }}
    >
      <Stack alignContent={'center'} justifyContent={'center'} sx={{ p: 1.5 }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '500',
            color: '#5F5F5F',
            textAlign: 'center',
          }}
        >
          Frequently Asked questions
        </Typography>
      </Stack>

      <Stack>
        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            elevation={0}
            disableGutters={true}
            sx={{
              borderTop: '1px solid #00000030',
              minHeight: 'auto',
              flexShrink: 0,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ borderTop: 'red' }}
            >
              <Typography
                sx={{ flexShrink: 0, fontSize: '12px', color: '5F5F5F' }}
              >
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ top: 0, fontSize: '12px', color: '5F5F5F' }}>
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Stack>
  );
};

export default FaqTab;
