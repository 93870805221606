import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import { useSnackbar } from 'notistack';
import {
  deletePlan,
  getCommunityPlans,
  getPlan,
  savePlan,
  updatePlan,
} from '../services/plans.service';
import { IPlan } from '../components/Plans/Plans';
// import store from "../store";
// import { useSelector } from "react-redux";

export const usePlans = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [plans, setPlans] = useState<IPlan[]>([]);
  //const [plan, setPlan] = useState<IPlan>();

  //data from the store
  // const state = store.getState();
  // const community = useSelector(() => {
  //   return state?.selectedCommunity;
  // });
  //const [selectedCommunityId,]= useState(community.selectedCommunity?.id)
  // useEffect(() => {
  //    getCommunityPlansList(selectedCommunityId||"");
  // }, [selectedCommunityId,Plans]);

  // const getPlansList = async () => {
  //   setIsLoading(true);
  //   const response = await getPlans(getAccessToken());
  //   if (response.status === 200) {
  //     setPlans(response.data);
  //   } else {
  //     enqueueSnackbar("Fetching plans failed", { variant: "error" });
  //   }
  //   setIsLoading(false);
  // };
  // useEffect(() => {

  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await getCommunityPlans(getAccessToken(), selectedCommunityId?? '');
  //       if (response.status === 200) {
  //         setPlans(response.data);
  //         fetchData();
  //         // setPostsChanged(false); // Reset the flag after fetching data
  //       } else {
  //         // enqueueSnackbar("Fetching posts failed", { variant: "error" });
  //       }
  //     } catch (error) {
  //       // enqueueSnackbar("An error occurred while fetching posts", { variant: "error" });
  //     }
  //     setIsLoading(false);
  //   };

  //   fetchData();
  // }, [selectedCommunityId]);

  const getCommunityPlansList = async (id: string) => {
    setIsLoading(true);
    const response = await getCommunityPlans(getAccessToken(), id);
    if (response.status === 200) {
      setPlans(response.data);
      setIsLoading(false);
      return response.data;
    } else {
      enqueueSnackbar('Failed to fetch plans', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    setIsLoading(false);
  };

  const deletePlanById = async (id: string) => {
    try {
      const response = await deletePlan(getAccessToken(), id);

      if (response.status === 200) {
        setPlans((prevPlans) => prevPlans.filter((plan) => plan?._id !== id));

        enqueueSnackbar('Plan deleted', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Couldn’t delete plan', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('Couldn’t delete plan', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const editPlan = async (
    planId: string,
    formData: any,
    files: FileList | null,
    documents: FileList | null
  ) => {
    try {
      const response = await updatePlan(
        getAccessToken(),
        planId,
        formData,
        files,
        documents
      );
      if (response.status === 200) {
        // Find and update the post in the posts state
        setPlans((prevPosts) =>
          prevPosts.map((plan) => (plan._id === planId ? response.data : plan))
        );
        enqueueSnackbar('Plan updated', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Couldn’t update plan', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('Couldn’t Update Plan', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  const createNewPlan = async (
    id: string,
    formData: any,
    files: FileList | null,
    documents: FileList | null
  ) => {
    try {
      const response = await savePlan(
        id,
        getAccessToken(),
        formData,
        files,
        documents
      );

      // console.log(response.data)
      if (response.status === 200) {
        setPlans((prevPlans) => [...prevPlans, response.data]);
        enqueueSnackbar('Post created', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar("Couldn't create post", {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't create post", {
        variant: 'error',
      });
    }
  };

  const getPlanDetailById = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await getPlan(getAccessToken(), id);
      if (response.status === 200) {
        //setPaymentRequest(response.data)
      } else {
        enqueueSnackbar('Failed to fetch plan details', { variant: 'error' });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('Failed to fetch plan details', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    plans,
    deletePlanById,
    editPlan,
    createNewPlan,
    getCommunityPlansList,
    getPlanDetailById,
  };
};
