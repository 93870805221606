import { useState } from 'react';
import { IRole } from '../models/roles.model';

const rolesList: IRole[] = [
  { label: 'User', value: 'USER' },
  { label: 'Admin', value: 'ADMIN' },
];
export const useRoles = () => {
  const [roles] = useState<IRole[]>(rolesList);
  return {
    roles,
  };
};
