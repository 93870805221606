import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import { add, get, remove, update } from '../services/addon.service';
import { IAddOn, IAddOnPayload, IAddOnType } from '../models/addon.model';

export const useAddOns = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [list, setList] = useState<IAddOn[]>([]);
  const [types] = useState<IAddOnType[]>([
    { title: 'User Base', value: 'userBase' },
    { title: 'Duration Base', value: 'durationBase' },
  ]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, []);

  const getList = async () => {
    setIsLoading(true);
    const response = await get(getAccessToken());

    if (response.status === 200) {
      setList(response.data);
    } else {
      enqueueSnackbar('Fetching users failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    setIsLoading(false);
  };

  const addAddOn = async (data: IAddOnPayload) => {
    setIsLoading(true);
    const response = await add(data, getAccessToken());
    if (response.status === 200) {
      enqueueSnackbar('Successfully updated add-on', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setList([...list, response.data]);
    } else {
      enqueueSnackbar('Failed to update add-on', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    setIsLoading(false);
  };
  const updateAddOn = async (data: IAddOnPayload, id: string) => {
    setIsLoading(true);
    const response = await update(id, data, getAccessToken());

    if (response.status === 200) {
      enqueueSnackbar('Successfully updated add-on', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setList(
        list.map((item: IAddOn) => (item.id === id ? response.data : item))
      );
    } else {
      enqueueSnackbar('Failed to update add-on', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    setIsLoading(false);
  };
  const removeAddOn = async (id: string) => {
    setIsLoading(true);
    const response = await remove(id, getAccessToken());

    if (response.status === 200) {
      enqueueSnackbar('Successfully deleted add-on', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      setList(list.filter((item: IAddOn) => item.id === id));
    } else {
      enqueueSnackbar('Failed to delete add-on', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    list,
    types,
    addAddOn,
    updateAddOn,
    removeAddOn,
  };
};
