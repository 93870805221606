import React, { useEffect, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { UsersTable } from '../tables/UsersTable';
import { useUsers } from '../../hooks/useUsers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import { IEditUser } from '../../models/user.model';
import { MessageInfo1, SearchIcon1 } from '../../assets/icons';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { IAction, IColumn } from '../../models/table.model';
import { ContentWrapper } from '../Layout/components/ContentWrapper';
import { useNavigate } from 'react-router-dom';
import EditPopup from '../sub-components/EditPopup';
import { EditUser } from './EditUser';
import { ConfirmaionPopover } from '../sub-components/ConfirmaionPopover';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  List,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Invitations } from '../../models/Invitation.model';
import Invitation from '../Invitations/Invitation';
import { CommunityMembers } from '../../models/community-user.model';
import { Helmet } from 'react-helmet';
import Loader from '../Loader/Loader';
import { useRequests } from '../../hooks/useRequests';
import { metaConfig } from '../../utils/constants';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '15px',
  // boxShadow: 24,
  p: 2,
};
const columns: IColumn[] = [
  { title: 'Member', dataKey: 'user' },
  { title: 'Contact', dataKey: 'contact' },
  { title: 'Community', dataKey: 'community' },
  { title: 'Subscription', dataKey: 'subscription' },
  { title: 'Role Type', dataKey: 'roleType' },
  { title: 'Joined On', dataKey: 'joinedOn' },
  { title: 'Status', dataKey: 'status' },
  { title: 'Action', dataKey: 'action' },
];

export const Users = () => {
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const selectedUser = useRef<IEditUser>({
    id: '',
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    isPhoneVerified: false,
    description: '',
    pincode: '',
    city: '',
    address: '',
    aadhar: '',
    pan: '',
    userName: '',
    avatar: '',
    about: '',
  });
  const [userList, setUserList] = useState<CommunityMembers[]>([]);

  const navigate = useNavigate();
  // const singleCommunity = useSelector((state:any) => { state?.selectedCommunity;});
  const singleCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );
  let community_id = singleCommunity?._id;
  // if (!community_id) {
  //   community_id = singleCommunity?._id;
  // }

  const { getUsersList } = useUsers();
  const [loading, setLoading] = useState(true);
  const loadUserList = async () => {
    try {
      setLoading(true);
      const users = await getUsersList(community_id);
      // console.log(users,"userssss");

      if (!users) {
        setUserList([]);
      } else {
        setUserList(users);
      }
    } catch (error) {
      console.error('Error loading user list:', error);
      setUserList([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadUserList();
    // eslint-disable-next-line
  }, [community_id]);

  const { requests } = useRequests();

  const handleActionClick = (
    a: IAction,
    data: CommunityMembers,
    e?: React.MouseEvent<HTMLButtonElement> | undefined
  ) => {
    if (a.action === 'edit') {
      setOpenEdit(true);
    }
    if (a.action === 'delete') setAnchorEl((e && e.currentTarget) ?? null);
  };

  const handleCreateNew = () => {
    navigate('/users/invitations');
  };

  const handleEditActions = (action: string, data?: any) => {
    if (action === 'cancel') {
      handleEditClose();
    }
  };
  const handleClosePopeover = () => {
    setAnchorEl(null);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const [openFilter, setOpenFilter] = useState(false);
  const [openFilterMobile, setOpenFilterMobile] = useState(false);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [roleTypeFilter, setRoleTypeFilter] = useState<string[]>([]);

  const handleStatusFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    if (checked) {
      setStatusFilter((prevFilters) => [...prevFilters, value]);
    } else {
      setStatusFilter((prevFilters) =>
        prevFilters?.filter((filter) => filter !== value)
      );
    }
  };

  const handleRoleFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, checked } = event.target;
    if (checked) {
      setRoleTypeFilter((prevFilters) => [...prevFilters, value]);
    } else {
      setRoleTypeFilter((prevFilters) =>
        prevFilters.filter((filter: string) => filter !== value)
      );
    }
  };

  const [checkboxes, setCheckboxes] = useState<{
    [key: string]: boolean;
  }>({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
  });
  // console.log(checkboxes);

  const handleClearButtonClick = () => {
    setStatusFilter([]);
    setRoleTypeFilter([]);
    setCheckboxes({
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      checkbox4: false,
      checkbox5: false,
      checkbox6: false,
      checkbox7: false,
      checkbox8: false,
    });
  };
  const handleDoneButtonClick = () => {
    setOpenFilter(false); // Close the select
  };

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [invitation, setInvitation] = useState<Invitations>();

  const filteredUsers = userList?.filter((user: CommunityMembers) => {
    const fullName =
      `${user?.user?.firstName} ${user?.user?.lastName}`.toLowerCase();
    const searchQueryLower = searchQuery.toLowerCase();
    const statusMatch =
      statusFilter.length === 0 || statusFilter.includes(user.status);
    const roleTypeMatch =
      roleTypeFilter.length === 0 || roleTypeFilter.includes(user.type);

    return fullName.includes(searchQueryLower) && statusMatch && roleTypeMatch;
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = (invitation: Invitations) => {
    setInvitation(invitation);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleCloseDialogue = () => setOpenAddUser(false);
  return (
    <>
      <Helmet>
        <title>{metaConfig.title.members}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack direction={'column'} sx={{ height: '100%', p: 0 }}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Stack direction={'row'} spacing={1}>
              <MessageInfo1 />

              <Typography>
                Message by:{invitation?.createdBy?.firstName}{' '}
                {invitation?.createdBy?.lastName}
              </Typography>
            </Stack>
            <Stack
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              spacing={1}
            >
              <Divider style={{ color: 'red', width: '100%' }} />
              <Typography>{invitation?.message}</Typography>
            </Stack>
          </Box>
        </Modal>

        {/* filters for large screens */}
        <Stack
          direction="row"
          display={{ xs: 'none', md: 'flex' }}
          alignItems={'center'}
        >
          <Stack direction={'row'} flexGrow={1} spacing={1}>
            <Box component="form" noValidate autoComplete="off">
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 500,
                  color: '#3760A9',
                  fontFamily: 'Inter',
                }}
              >
                Members
              </Typography>
            </Box>
          </Stack>
          <Stack direction={'row-reverse'} flexGrow={1} spacing={2}>
            <Button
              variant="contained"
              startIcon={<AddCircleRoundedIcon />}
              onClick={handleCreateNew}
              sx={{
                textTransform: 'capitalize',
                borderRadius: '30px',
                backgroundColor: '#3C5AA0',
                fontWeight: 500,
                '&:hover': {
                  backgroundColor: '#3C5AA0', // Background color on hover
                  cursor: 'pointer',
                  border: 'none',
                },
              }}
            >
              Invite
            </Button>

            <Box component="form" noValidate autoComplete="off">
              <Select
                open={openFilter}
                onOpen={() => setOpenFilter(true)}
                onClose={() => setOpenFilter(false)}
                sx={{
                  backgroundColor: 'white',
                  height: '35px',
                  borderRadius: '30px',
                  '& .MuiSelect-icon': {
                    color: '#1A1A1A',
                  },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <FilterListIcon />
                    Filter
                  </InputAdornment>
                }
              >
                <Accordion
                  disableGutters={true}
                  sx={{ paddingTop: '0', pb: 0 }}
                  elevation={0}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography sx={{ fontSize: '13px', font: 'Roboto' }}>
                      Status
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingTop: '0px' }}>
                    <Stack>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={statusFilter.includes('ACTIVE')}
                            onChange={handleStatusFilterChange}
                            value="ACTIVE"
                            size="small"
                          />
                        }
                        label="Active"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={statusFilter.includes('BLOCK')}
                            onChange={handleStatusFilterChange}
                            value="BLOCK"
                            size="small"
                          />
                        }
                        label="Block"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                          },
                        }}
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  disableGutters={true}
                  sx={{ pt: 0, pb: 0 }}
                  elevation={0}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography sx={{ fontSize: '13px', font: 'Roboto' }}>
                      Role Type
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingTop: '0px', pb: 0 }}>
                    <Stack>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="ADMIN"
                            checked={roleTypeFilter.includes('ADMIN')}
                            onChange={handleRoleFilterChange}
                            size="small"
                          />
                        }
                        label="Admin"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                          },
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={'USER'}
                            checked={roleTypeFilter.includes('USER')}
                            onChange={handleRoleFilterChange}
                            size="small"
                          />
                        }
                        label="Member"
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            fontSize: '13px',
                          },
                        }}
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>

                <Stack direction={'row-reverse'}>
                  <Button
                    onClick={handleClearButtonClick}
                    variant="outlined"
                    size="small"
                    sx={{
                      m: 1,
                      color: '#3C5AA0',
                      textTransform: 'capitalize',
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleDoneButtonClick}
                    size="small"
                    sx={{
                      m: 1,
                      backgroundColor: '#2952A2',
                      textTransform: 'capitalize',
                    }}
                  >
                    Done
                  </Button>
                </Stack>
              </Select>
            </Box>

            <TextField
              placeholder="Search"
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon1 sx={{ mt: 1 }} />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '30px',
                  backgroundColor: 'white',

                  height: '37px',
                  width: { xs: '150px', md: '200px' },
                  color: '#000000',
                },
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Stack>
        </Stack>

        {/* for small screens  */}
        <Stack
          direction="row"
          display={{ xs: 'flex', md: 'none', xl: 'none', lg: 'none' }}
          sx={{ mb: '5px' }}
        >
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={6}>
              <Box component="form" noValidate autoComplete="off">
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 500,
                    color: '#3760A9',
                    fontFamily: 'Inter',
                    // ml: 3,
                  }}
                >
                  Member List
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleCreateNew}
                startIcon={<AddCircleRoundedIcon />}
                sx={{
                  textTransform: 'capitalize',
                  borderRadius: '30px',
                  backgroundColor: '#3C5AA0',
                  // "&:hover": {
                  //   backgroundColor: "#50A1CA", // Background color on hover
                  //   cursor: "pointer",
                  //   border: "none",
                  // },
                }}
              >
                Invite
              </Button>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                placeholder="Search…"
                variant="outlined"
                size="medium"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon1 sx={{ mt: 1 }} />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: '30px',
                    backgroundColor: 'white',
                    height: '35px',
                    // width: { xs: "150px", md: "200px" },
                  },
                }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{ borderRadius: '30px' }}
              >
                <Select
                  fullWidth
                  MenuProps={{}}
                  open={openFilterMobile}
                  onOpen={() => setOpenFilterMobile(true)}
                  onClose={() => setOpenFilterMobile(false)}
                  sx={{
                    backgroundColor: 'white',
                    height: '35px',
                    borderRadius: '30px',

                    '& .MuiSelect-icon': {
                      color: '#1A1A1A',
                    },
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterListIcon />
                      Filters
                    </InputAdornment>
                  }
                >
                  <Accordion
                    disableGutters={true}
                    sx={{ paddingTop: '0', pb: 0 }}
                    elevation={0}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={{ fontSize: '13px', font: 'Roboto' }}>
                        Status
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: '0px' }}>
                      <Stack>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={statusFilter.includes('ACTIVE')}
                              onChange={handleStatusFilterChange}
                              value="ACTIVE"
                              size="small"
                            />
                          }
                          label="Active"
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                            },
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={statusFilter.includes('BLOCK')}
                              onChange={handleStatusFilterChange}
                              value="BLOCK"
                              size="small"
                            />
                          }
                          label="Block"
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                            },
                          }}
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion
                    disableGutters={true}
                    sx={{ pt: 0, pb: 0 }}
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontSize: '13px', font: 'Roboto' }}>
                        Role Type
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: '0px', pb: 0 }}>
                      <Stack>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="ADMIN"
                              checked={roleTypeFilter.includes('ADMIN')}
                              onChange={handleRoleFilterChange}
                              size="small"
                            />
                          }
                          label="Admin"
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                            },
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={'USER'}
                              checked={roleTypeFilter.includes('USER')}
                              onChange={handleRoleFilterChange}
                              size="small"
                            />
                          }
                          label="Member"
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '13px',
                            },
                          }}
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>

                  <Stack direction={'row-reverse'}>
                    <Button
                      onClick={handleClearButtonClick}
                      variant="outlined"
                      size="small"
                      sx={{
                        m: 1,
                        color: '#3C5AA0',
                        textTransform: 'capitalize',
                      }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleDoneButtonClick}
                      size="small"
                      sx={{
                        m: 1,
                        backgroundColor: '#2952A2',
                        textTransform: 'capitalize',
                      }}
                    >
                      Done
                    </Button>
                  </Stack>
                </Select>
              </Box>
            </Grid>
          </Grid>
        </Stack>

        <Stack sx={{ pt: '5px' }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              lg={requests.length > 0 ? 10 : 12}
              xl={requests.length > 0 ? 9 : 12}
            >
              <ContentWrapper>
                {loading && <Loader />}
                {!loading && (
                  <UsersTable
                    data={filteredUsers}
                    columns={columns}
                    onActionClick={handleActionClick}
                    loadUserList={loadUserList}
                    checkboxes={checkboxes}
                    // handleCheckboxChange={handleCheckboxChange}
                  />
                )}
              </ContentWrapper>
            </Grid>
            {requests.length > 0 && (
              <Grid
                item
                xs={2}
                md={2}
                lg={2}
                xl={3}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'block',
                    xl: 'block',
                    // maxHeight: "calc(100vh - 50vh)"
                  },
                }}
              >
                <ContentWrapper>
                  <Typography
                    sx={{
                      fontSize: '15px',
                      fontWeight: 500,
                      color: '#1E1E1E',
                      ml: '10px',
                      pb: '5px',
                      pt: '2px',
                    }}
                  >
                    Pending Requests
                  </Typography>
                  <Divider />
                  <Paper
                    elevation={0}
                    sx={{
                      borderRadius: '10px',
                      maxWidth: '250px',
                      overflowY: 'auto',
                      borderBottom: '30px',
                      height: 'calc(100vh - 26.9vh)',
                    }}
                  >
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      {requests.map((invitation: Invitations) => (
                        <div
                          key={invitation._id}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '8px',
                          }}
                        >
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Avatar alt="Community Logo" src="" />
                            <div style={{ marginLeft: '10px', flex: 1 }}>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  color: '#000000',
                                }}
                              >
                                {invitation?.community?.name}
                              </Typography>
                              <Typography
                                sx={{ color: '#787878', fontSize: '12px' }}
                              >
                                {invitation?.community?.fullAddress}
                              </Typography>
                              <Typography
                                sx={{ color: '#787878', fontSize: '12px' }}
                              >
                                {invitation?.community?.city}
                              </Typography>
                            </div>
                            <MessageInfo1
                              onClick={() => handleOpen(invitation)}
                              sx={{ cursor: 'pointer' }}
                            />
                          </div>
                          <Button
                            sx={{
                              textTransform: 'none',
                              fontSize: '13px',
                              backgroundColor: '#3C5AA0',
                              color: '#ffffff',
                              fontWeight: '500',
                              width: '100px',
                              margin: 'auto',
                              marginTop: '10px',
                              marginBottom: '8px',
                              '&:hover': {
                                boxShadow: 'none',
                                backgroundColor: '#50A1CA',
                              },
                            }}
                          >
                            Join
                          </Button>
                        </div>
                      ))}
                    </List>
                  </Paper>
                  <Divider />
                </ContentWrapper>
              </Grid>
            )}
          </Grid>
        </Stack>
      </Stack>
      <EditPopup
        open={openEdit}
        handleClose={handleEditClose}
        component={
          <EditUser data={selectedUser.current} onAction={handleEditActions} />
        }
      />

      <ConfirmaionPopover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClosePopeover}
        component={
          <Box sx={{ px: 2, pt: 1, maxWidth: '250px' }}>
            <Stack spacing={1}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    textAlign: 'center',
                    color: 'warning.dark',
                  }}
                >
                  Are you sure?
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: '11px', fontWeight: 500 }}
                  textAlign={'center'}
                >
                  Do you really want to delete this record? This process cannot
                  be undo.
                </Typography>
              </Box>
              <Divider variant={'fullWidth'} />
              <Stack
                direction={'row'}
                spacing={1}
                justifyContent={'center'}
                sx={{ pb: 1 }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClosePopeover}
                >
                  Cancel
                </Button>
                <Button color="warning" variant="contained" size="small">
                  Delete
                </Button>
              </Stack>
            </Stack>
          </Box>
        }
      />
      <Stack>
        <Dialog
          open={openAddUser}
          onClose={handleCloseDialogue}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            style: {
              width: '100%',
              height: '100%',
            },
          }}
        >
          <DialogActions>
            <Button onClick={handleCloseDialogue}>X</Button>
          </DialogActions>
          <DialogContent>
            {/* <AddUser onClose={handleCloseDialogue}/> */}
            <Invitation openMode={false} />
          </DialogContent>
        </Dialog>
      </Stack>
    </>
  );
};
