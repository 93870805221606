import * as React from 'react';
import Box from '@mui/material/Box';
import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import Grid from '@mui/material/Grid';

export interface ICard {
  name: string;
  arrow: string;
  percentage: number;
  icon: string;
  total: string;
  text: string;
}
interface ICardProps {
  data: ICard;
}
export const DashboardCard = (props: ICardProps) => {
  const { data } = props;
  return (
    <Card elevation={0}>
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pt: 2,
          }}
        >
          {data.arrow === 'up' ? (
            <ArrowUpwardIcon sx={{ color: 'success.light' }} />
          ) : (
            <ArrowDownwardIcon color="warning" />
          )}
          <Typography
            variant="body2"
            sx={{
              mr: 1,
              color: data.arrow === 'up' ? 'success.light' : 'warning.main',
            }}
          >
            {data.percentage}%
          </Typography>
          <Typography color="#565656" variant="caption">
            Since last month
          </Typography>
        </Box>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Stack direction={'row'} alignItems="center" spacing={2}>
              <Avatar
                sx={{
                  backgroundColor: '#F0F9FF',
                  color: 'primary.main',
                  height: 56,
                  width: 56,
                }}
              >
                <PeopleIcon sx={{ color: '#2952A2' }} />
              </Avatar>
              <Box>
                <Typography color="#2952A2" variant="h6">
                  {data.total}
                </Typography>
                <Typography color="#565656" gutterBottom variant="body2">
                  {data.text}
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
