import React, { useState } from 'react';
import {
  Card,
  CardMedia,
  Button,
  Stack,
  Dialog,
  Grid,
  Box,
  DialogActions,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface ImageGalleryProps {
  images: string[];
  imageHeight: number;
  imageWidth: number;
}

const ImageGalleryCraft: React.FC<ImageGalleryProps> = ({ images }) => {
  const [showCarousel, setShowCarousel] = useState(false);

  // const theme = useTheme();

  const openCarousel = () => {
    setShowCarousel(true);
  };

  const closeCarousel = () => {
    setShowCarousel(false);
  };

  return (
    <Stack
      sx={{
        gap: 1,
        // paddingLeft: "20px",
        // paddingRight: "20px",
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {images.length > 0 && (
        <Grid container spacing={1}>
          {images.slice(0, 1).map((image, index) => (
            <Grid key={index} item xs={images.length === 1 ? 12 : 6}>
              <Card
                elevation={0}
                sx={{
                  height: { xs: 150, md: 300 },
                  maxHeight: { xs: 150, md: 300 },
                  width: '100%',
                  marginBottom: '2px',
                  borderRadius: '5px',
                }}
                onClick={openCarousel}
              >
                <CardMedia
                  component="img"
                  sx={{
                    height: { xs: 150, md: 300 },
                    maxHeight: { xs: 150, md: 300 },
                    width: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    borderRadius: '5px', // Add some border radius for rounded corners
                    marginBottom: '16px', // Add some spacing between cards if needed
                  }}
                  image={image}
                />
              </Card>
            </Grid>
          ))}
          {images.length > 1 && (
            <Grid item xs={6}>
              <Card
                elevation={0}
                sx={{
                  height: { xs: 150, md: 300 },
                  maxHeight: { xs: 150, md: 300 },
                  width: '100%',
                  marginBottom: '2px',
                  position: 'relative',
                  borderRadius: '5px',
                }}
                onClick={openCarousel}
              >
                <CardMedia
                  component="img"
                  sx={{
                    height: { xs: 150, md: 300 },
                    maxHeight: { xs: 150, md: 300 },
                    width: '100%',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    borderRadius: '5px', // Add some border radius for rounded corners
                    marginBottom: '16px', // Add some spacing between cards if needed
                  }}
                  image={images[1]}
                />
                {images.length > 2 && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: '#3C5AA0',
                      color: '#ffffff',
                      fontSize: '15px',
                      fontFamily: 'Inter',
                      mt: -12,
                      left: { xs: '125px', md: '92%' },
                      textTransform: 'capitalize',
                      transform: 'translateX(-50%)',
                      '&:hover': {
                        backgroundColor: '#3C5AA0',
                      },
                    }}
                    onClick={openCarousel}
                  >
                    + {images.length - 2}
                  </Button>
                )}
              </Card>
            </Grid>
          )}
        </Grid>
      )}

      <Dialog open={showCarousel} onClose={closeCarousel} maxWidth="sm">
        <DialogActions sx={{ backgroundColor: '#F0F9FF' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ backgroundColor: '#F0F9FF' }}
          >
            <Stack></Stack>
            <Stack></Stack>
            <Stack sx={{ backgroundColor: '#F0F9FF' }}>
              <Box
                onClick={closeCarousel}
                sx={{
                  backgroundColor: '#2653A3',
                  padding: '4px',
                  borderRadius: '50%',
                  width: '20px',
                  height: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '10px',
                  mt: '0.5px',
                  mr: '10px',
                  cursor: 'pointer',
                  // "&:hover": {
                  //   backgroundColor: "#50A1CA", // Background color on hover
                  //   cursor: "pointer",
                  //   border: "none",
                  // },
                }}
              >
                <Close sx={{ fontSize: '15px' }} />
              </Box>
            </Stack>
          </Stack>
        </DialogActions>
        <Carousel
          showThumbs={false}
          infiniteLoop={false}
          width={'fit-content'}
          showStatus={false}
          showArrows={true}
        >
          {images.map((image, index) => (
            <Box key={index}>
              <CardMedia
                component="img"
                sx={{
                  width: '100%',
                  objectFit: 'contain',
                  cursor: 'pointer',
                }}
                src={image}
              />
            </Box>
          ))}
        </Carousel>
      </Dialog>
    </Stack>
  );
};

export default ImageGalleryCraft;
