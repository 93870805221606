import Popover from '@mui/material/Popover';

interface IConfirmaionPopoverProps {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  component: any;
}
export const ConfirmaionPopover = ({
  open,
  anchorEl,
  component,
  onClose,
}: IConfirmaionPopoverProps) => {
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {component}
    </Popover>
  );
};
