import * as React from 'react';
import Dialog from '@mui/material/Dialog';

interface IEditPopupProps {
  open: boolean;
  component: any;
  handleClose: () => void;
}
export default function EditPopup({
  open,
  component,
  handleClose,
}: IEditPopupProps) {
  return (
    <Dialog open={open} onClose={handleClose}>
      {component}
    </Dialog>
  );
}
