import Stack from '@mui/material/Stack';
import { SubNavbar } from '../Layout/components/SubNavbar';
import Button from '@mui/material/Button';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { IColumn } from '../../models/table.model';
import { ContentWrapper } from '../Layout/components/ContentWrapper';
import { useNavigate } from 'react-router-dom';
import { useAddOns } from '../../hooks/useAddOn';
import { AddOnsTable } from '../tables/AddOnsTable';
import { IAddOn, IAddOnsList } from '../../models/addon.model';
import { DeleteIcon, EditIcon } from '../../assets/icons';

export const AddOns = () => {
  const navigate = useNavigate();
  const { list } = useAddOns();

  const handleActionClick = () => {};
  const handleCreateNew = () => {
    navigate('/add-ons/create');
  };

  const columns: IColumn[] = [
    { title: 'Title', dataKey: 'title' },
    { title: 'Add-on type', dataKey: 'title' },
    { title: 'Add-on Keys', dataKey: 'title' },
    { title: 'Created By', dataKey: 'title' },
    { title: 'Status', dataKey: 'title' },
    { title: 'Updated / Created On', dataKey: 'title' },
    { title: '', dataKey: '' },
  ];

  const attachActions = (data: IAddOn[]): IAddOnsList[] => {
    return data.map((d: IAddOn) => {
      return {
        ...d,
        actions: [
          { action: 'edit', title: 'Edit', tooltip: 'Edit', icon: EditIcon },
          {
            action: 'edit',
            title: 'Delete',
            tooltip: 'Delete',
            icon: DeleteIcon,
          },
        ],
      };
    });
  };

  return (
    <Stack direction={'column'} spacing={1} sx={{ height: '100%' }}>
      <SubNavbar title={'Add-ons'} value="3/4">
        <Stack direction={'row-reverse'} flexGrow={1}>
          <Button
            variant="contained"
            onClick={handleCreateNew}
            startIcon={<AddCircleRoundedIcon />}
            sx={{
              textTransform: 'capitalize',
              borderRadius: '30px',
              backgroundColor: '#4578F9',
            }}
          >
            Add New
          </Button>
        </Stack>
      </SubNavbar>
      <ContentWrapper>
        <AddOnsTable
          data={attachActions(list)}
          columns={columns}
          onActionClick={handleActionClick}
        />
      </ContentWrapper>
    </Stack>
  );
};
