import { Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import HomePost from '../../../Home/HomePost';
import store from '../../../../store';
import { useSelector } from 'react-redux';
import { ICommunity } from '../../../../models/communities.modal';
import { useCommunity } from '../../../../hooks/useCommunity';
import { Helmet } from 'react-helmet';
import { metaConfig } from '../../../../utils/constants';

const Profile: React.FC = () => {



  const { getCommunityById } = useCommunity();

  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);

  // eslint-disable-next-line
  const [communityProfile, setCommunityProfile] = useState<ICommunity>();
  const state = store.getState();
  const selectedCommunity = useSelector(() => {
    return state?.selectedCommunity;
  });
  const id = selectedCommunity.selectedCommunity?._id;

  useEffect(() => {
    if (!id) return;
    const fetchCommunity = async () => {
      setLoading(true);
      const communityData = await getCommunityById(id);
      setLoading(false);
      setCommunityProfile(communityData?.data);
    };

    fetchCommunity();
  }, [id]);


  return (
    <>
      <Helmet>
        <Helmet>
          <title>{metaConfig.title.myProfile}</title>
          <meta name="description" content={metaConfig.description} />
          <meta name="format-detection" content="telephone=no" />
          <meta name="author" content={metaConfig.description} />
          <meta name="keywords" content={metaConfig.description} />
        </Helmet>
      </Helmet>
      <Grid container spacing={1} sx={{ height: 'auto' }}>

      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={12} xs={12} md={12}>
          <HomePost />
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
