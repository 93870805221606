

import { Box, Button, Card, Grid, Paper, Stack, Typography } from '@mui/material'
import Image1 from "../../assets/images/upload.png"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const TemplateSelect = () => {

    const cards = [
        {
            id: "1",
            templateName: "Yoga",
            image: `${Image1}`
        },
        {
            id: "2",
            templateName: "Gym",
            image: `${Image1}`
        },
        {
            id: "3",
            templateName: "Business",
            image: `${Image1}`
        },
        {
            id: "4",
            templateName: "Food",
            image: `${Image1}`
        },

    ]


    const navigate = useNavigate()


    const selectedCommunity = useSelector(
        (state: any) => state?.selectedCommunity.selectedCommunity
    );

    const communityId = selectedCommunity?._id;


    const handleNavigate = () => {
        navigate(`/builder/${communityId}`)

    }

    return (
        <>
            <Paper elevation={0} sx={{ height: "45%", mb: 2, backgroundColor: '#D2E6FF' }}>
                <Card>

                </Card>
            </Paper>
            <Paper elevation={0} sx={{ height: "50%", p: 2 }}>
                <Typography sx={{
                    color: '#3760A9',
                    fontSize: '20px',
                    fontWeight: 500,
                    fontFamily: 'Inter',
                    mb: 2
                }}> Popular free Themes</Typography>

                <Grid container gap={1} sx={{
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {cards.map((card, index) => (
                        <Grid key={index} xs={2.9} sx={{
                            border: '1px solid #E7E7E7',
                            borderRadius: '10px',
                            padding: 2
                        }}>
                            <Card sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: "center",
                                boxShadow: "none",
                            }}>
                                <Box
                                    component="img"
                                    src={card?.image}
                                    alt="Template Image"
                                    width="100%"
                                    height="100%"
                                />
                            </Card>
                            <Stack sx={{
                                display: "flex",
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                alignItems: "center",
                                mt: 2
                            }}>
                                <Stack>
                                    <Typography
                                        sx={{
                                            fontSize: "15px",
                                            fontFamily: 'Nunito',
                                            color: '#3760A9',
                                            fontWeight: 600
                                        }}
                                    >{card?.templateName}</Typography>
                                </Stack>
                                <Stack>
                                    <Button variant='outlined'
                                        sx={{
                                            border: "1px solid #E7E7E7",
                                            textTransform: 'capitalize',
                                            fontFamily: 'Nunito',
                                            fontWeight: 600,
                                            color: '#515151'

                                        }}
                                        onClick={handleNavigate}
                                    >
                                        Add
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </>
    )
}

export default TemplateSelect
