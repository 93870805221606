import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box, Grid } from '@mui/material';
import ContentDisplay from './ContentDisplay';

const MainComponent = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const addNewFAQ = (newFAQ: any) => {
    console.log('Adding new FAQ:', newFAQ);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Grid>
      <Grid item xs={12} md={8}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Tabs"
          >
            <Tab
              label="FAQ"
              id="faq-tab"
              sx={{
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: 500,
              }}
            />
            <Tab label="|" disabled />
            <Tab
              label="Add"
              id="add-tab"
              sx={{
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                fontWeight: 500,
              }}
            />
          </Tabs>
        </Box>
        <Box mt={2} sx={{ marginBottom: '50px' }}>
          <Typography component="div">
            <ContentDisplay selectedTab={selectedTab} addNewFAQ={addNewFAQ} />
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MainComponent;
