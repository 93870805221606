// import * as React from "react";
import Box from '@mui/material/Box';
import { styles } from './NoAuthLayout.styles';
import { Outlet, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Header from '../ExploreCommunities/Layout/Header';
import Sidebar from '../ExploreCommunities/Layout/Sidebar';
import { Grid } from '@mui/material';
// import { AppBar, Button, Grid, Toolbar } from "@mui/material";
import store from '../../store';
// import { AddIcon, SearchIcon } from "../../assets/icons";
// import { useState } from "react";

// interface FormValues {
//     firstName: string;
//     lastName: string;
//     emailId: string;
//     phoneNumber: string;
//   }

export default function NoAuthLayout() {
  const communityState = store.getState();
  const communities = communityState?.communities?.communities;

  // console.log(communities, "communities");

  // const navigate = useNavigate();
  // const userData = useLocation();
  // const users = userData?.state?.data;
  // console.log("USER",users?.firstName)
  // const [profileImage, setProfileImage] = useState(
  //   "https://media.licdn.com/dms/image/C4D03AQHY0lgjd9835g/profile-displayphoto-shrink_800_800/0/1657463160696?e=2147483647&v=beta&t=Pzo6gxl5qtxsg875yYk1-9g21CQrNtaFcIvyWKjSYtc"
  // );

  // const [formValues, setFormValues] = useState<FormValues>({
  //   firstName: users?.firstName,
  //   lastName: users?.lastName,
  //   emailId: "",
  //   phoneNumber:users?.phoneNumber,

  // });
  // console.log(formValues);

  // const data = JSON.stringify(formValues);
  // console.log("Values:", data);

  //console.log(userData);

  // const usersData = store.getState();

  // const location = useLocation();
  // const isExploreCommunitiesPage = location.pathname === "/explore-communities" || location.pathname === "/invites-communities" ||
  //   location.pathname === "/trending-communities" || location.pathname === "/joined-communities" || location.pathname === "/admin-communities";
  //   const isCreateCommunityPage =
  //   location.pathname.includes("/explore-create-community") || /^\/profile-edit\/\d+$/.test(location.pathname);
  //   const isProfileEdit = /^\/profile-edit\/\d+/.test(location.pathname);

  // rest of your code...

  const navigate = useNavigate();

  return (
    <Grid container>
      {/* Sidebar */}
      <Grid item md={3} sm={12} xs={12}>
        <Stack
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
              xxl: 'block',
            },
          }}
        >
          {communities.length > 0 ? (
            <Box
              onClick={() => {
                navigate(`/home`);
              }}
              ml={3}
              mt={1}
              component={'img'}
              src={require('../../assets/images/Communn-Logo.png')}
              alt=""
              sx={{ width: '60%', cursor: 'pointer' }}
            />
          ) : (
            <Box
              ml={3}
              mt={1}
              component={'img'}
              src={require('../../assets/images/Communn-Logo.png')}
              alt=""
              sx={{ width: '60%' }}
            />
          )}
        </Stack>
        <Sidebar />
      </Grid>

      {/* Main Content */}
      <Grid item md={9} sm={12} xs={12}>
        <Box sx={styles.main}>
          {/* Header Section */}
          <Header />

          {/* Content Section */}
          <Box sx={styles.contentSection}>
            {/* <Dashboard /> */}
            <Outlet />
            {/* <Stack direction={"row"} justifyContent={"center"} sx={{ pt: 8 }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="#" style={{ color: "#A0A0A0", fontSize: "12px" }}>
                  FAQs
                </Link>
                <Link to="#" style={{ color: "#A0A0A0", fontSize: "12px" }}>
                  Terms of Services
                </Link>
                <Link to="#" style={{ color: "#A0A0A0", fontSize: "12px" }}>
                  Privacy Policy
                </Link>
                <Link to="#" style={{ color: "#A0A0A0", fontSize: "12px" }}>
                  Contact Us
                </Link>
              </Breadcrumbs>
            </Stack> */}
            {/* <Stack
              sx={{
                display: { xs: "block", sm: "block", md: "none", lg: "none" },
              }}
            >
              <AppBar
                position="fixed"
                color="primary"
                sx={{
                  top: "auto",
                  bottom: 0,
                  backgroundColor: "#FFFFFF",
                  p: 1,
                  justifyContent: "center",
                  mt: -5,
                }}
              >
                <Toolbar sx={{ justifyContent: "center" }}>
                  <Stack direction={"row"} display={"flex"} spacing={2}>
                    <Stack>
                      {communities.length >= 1 ? (
                        // Display "Home" button if communities exist
                        <Link to="/home" style={{textDecoration:'none'}}>
                        <Button
                          size="large"
                          variant="contained"
                          
                          fullWidth
                          sx={{
                            background: "linear-gradient(to right bottom, #50A1CA, #3B9B7F)",
                            textTransform: "capitalize",
                            borderRadius: "100px",
                            fontSize: "10px",
                            width: 150,
                            display : isProfileEdit ? "none" : "block",
                            textAlign:'center',
                          }}
                        
                        >
                          Home
                        </Button>
                        </Link>
                      ) : (
                        // Display "Create Community" button if no communities exist
                        <Button
                          size="large"
                          variant="contained"
                          
                          fullWidth
                          sx={{
                            background: "linear-gradient(to right bottom, #50A1CA, #3B9B7F)",
                            textTransform: "capitalize",
                            borderRadius: "100px",
                            fontSize: "10px",
                            width: 150,
                            textAlign:'center',
                            display:isProfileEdit ? "none" :"block",
                          }}
                          onClick={() => navigate(`/profile-edit/${usersData?.loggedInUser?.user?.id}`)}
                        >
                          Profile Edit
                        </Button>
                      )}
                    </Stack>
                    <Stack>
                    <Link to="/explore-communities" style={{textDecoration:'none'}}>
                      <Button
                        size="large"
                        variant= "outlined"
                       
                        fullWidth
                        sx={{
                          textTransform: "capitalize",
                          textAlign:'center',
                          borderRadius: "100px",
                          border: "1px solid #50A1CA",
                          fontSize: "10px",
                          width: 150,
                          color: "#50A1CA",
                          display: isExploreCommunitiesPage ? "none" : "block",
                        }}
                       
                      >
                        Explore Communities
                      </Button>
                      </Link>
                    </Stack>
                    <Stack>
                    <Link to="/explore-create-community" style={{textDecoration:'none'}}>
                      <Button
                        size="large"
                        variant="contained"
                        
                        fullWidth
                        sx={{
                          background: isProfileEdit ? "linear-gradient(to right bottom, #50A1CA, #3B9B7F)" : "#FFFFFF",
                          textTransform: "capitalize",
                          borderRadius: "100px",
                          fontSize: "10px",
                          width: 150,
                          border: "1px solid #50A1CA",
                          textAlign:'center',
                          color: isProfileEdit ? "#ffffff" : "#50A1CA",
                          display: isCreateCommunityPage ? "none" : "block",
                          '&:hover': {
                            backgroundColor: "#FFFFFF",
                          },
                        }}
                       
                      >
                        Create Community
                      </Button>
                      </Link>
                    </Stack>
                  </Stack>
                </Toolbar>
              </AppBar>
            </Stack> */}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
