import React, { useContext, useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { SubNavbar } from '../Layout/components/SubNavbar';
import { DeleteIcon } from '../../assets/icons';
import AddIcon from '@mui/icons-material/Add';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { array, object } from 'yup';
import { ContentWrapper } from '../Layout/components/ContentWrapper';
import { useRoles } from '../../hooks/useRoles';
import { IRole } from '../../models/roles.model';
import { AuthContext, IAuthContext } from '../../contexts/Auth.context';
import Invitation from '../Invitations/Invitation';
import { useInvitations } from '../../hooks/useInvitations';
import { Invitations } from '../../models/Invitation.model';
import { CommunityMembers } from '../../models/community-user.model';
const phoneRegExp = /^\d{10}$/;
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Name is required'),
  role: Yup.string().required('Role is required'),
  emailId: Yup.string().email('Email is invalid'),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, 'Invalid Mobile number')
    .required('Mobile number required'),
});

export interface IUser {
  getAccessToken: () => string;
  firstName: string;
  lastName: string;
  role: string;
  emailId: string;
  phoneNumber: string;
  status: string;
  community: string;
  message: string;
}

export const AddNewUserForm = ({
  remove,
  index,
  register,
  errors,
  fields,
  setUserInputs,
}: any) => {
  const { roles } = useRoles();
  const error = errors?.users?.[index];
  const handleInputChange = (fieldKey: string, value: string) => {
    setUserInputs((prevUserInputs: any) => ({
      ...prevUserInputs,
      [fieldKey]: value,
    }));
  };

  return (
    <Grid container alignItems="center">
      <Stack
        spacing={1}
        direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
        sx={{ width: '100%', mb: 1 }}
      >
        <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
          <TextField
            id={`fullName-${index}`}
            label="Full name"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ fontSize: '13px' }}
            onChange={(e) =>
              handleInputChange(`fullName-${index}`, e.target.value)
            }
            {...register(`users.${index}.fullName`)}
            error={error?.fullName ? true : false}
            InputLabelProps={{ style: { fontSize: '13px' } }}
            helperText={
              error?.fullName ? (error?.fullName?.message as string) : ''
            }
          />
        </Grid>
        <Grid item sm={3}>
          <FormControl fullWidth size="small">
            <InputLabel id={`role-label-${index}`} style={{ fontSize: '13px' }}>
              Select Role
            </InputLabel>
            <Select
              id={`role-${index}`}
              label="Select Role"
              size="small"
              variant="outlined"
              sx={{ color: 'black', fontSize: '13px', pt: '5px' }}
              onChange={(e: any) =>
                handleInputChange(`role-${index}`, e.target.value)
              }
              {...register(`users.${index}.role`)}
              defaultValue={fields.length > 0 ? fields[index].role : ''}
              error={error?.role ? true : false}
            >
              {roles?.map((role: IRole) => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label === 'User' ? 'Member' : 'Admin'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={3} sx={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required
            id={`phoneNumber-${index}`}
            label="Mobile Number"
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) =>
              handleInputChange(`phoneNumber-${index}`, e.target.value)
            }
            {...register(`users.${index}.phoneNumber`)}
            error={error?.phoneNumber ? true : false}
            InputLabelProps={{ style: { fontSize: '13px' } }}
            helperText={
              error?.phoneNumber ? (error?.phoneNumber?.message as string) : ''
            }
          />
        </Grid>

        <Grid item sm={3} sx={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            id={`email-${index}`}
            label="Email"
            size="small"
            fullWidth
            InputLabelProps={{ style: { fontSize: '13px' } }}
            variant="outlined"
            onChange={(e) =>
              handleInputChange(`emailId-${index}`, e.target.value)
            }
            {...register(`users.${index}.emailId`)}
            error={error?.emailId ? true : false}
            helperText={
              error?.emailId ? (error?.emailId?.message as string) : ''
            }
          />
          {fields.length > 1 && (
            <Grid item sx={{ mr: '-10px' }}>
              <IconButton
                onClick={() => remove(index)}
                sx={{ cursor: 'pointer' }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Stack>
    </Grid>
  );
};

interface AddUserProps {
  onClose: () => void;
  isCheckboxSelected: boolean;
  initialInviteData: Invitations[];
  selectedInvitations: Invitations[];
  communityMembers: CommunityMembers[];
  selectedCommunityId: string;
  refetchInvitesData: () => void;
  updateInvitationList: (newInvitation: Invitations[]) => void;
}
export const AddUser: React.FC<AddUserProps> = ({
  onClose,
  initialInviteData,
  selectedInvitations,
  communityMembers,
  selectedCommunityId,
  refetchInvitesData,
  updateInvitationList,
}) => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { addInvites, updateSelectedInvitations, getInviteList } =
    useInvitations();

  const [showInvitations, setShowInvitations] = useState(false);
  const [customMessages, setCustomMessages] = useState('');
  const [inviteList] = useState<Invitations[]>([]);
  const [duplicateError, setDuplicateError] = useState<string | null>(null);
  const inviteListRef = useRef<Invitations[]>([]);
  const memberListRef = useRef<CommunityMembers[]>([]);
  const [loading, setLoading] = useState(false);

  // Form validation schema
  const schema = object({
    users: array().of(validationSchema),
  });

  inviteListRef.current = initialInviteData;
  memberListRef.current = communityMembers;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    defaultValues: {
      users:
        selectedInvitations.length > 0
          ? selectedInvitations.map((invite, index) => ({
              firstName: invite.firstName || '',
              lastName: invite.lastName || '',
              fullName: `${invite.firstName} ${invite.lastName}`,
              role: invite.role || '',
              emailId: invite.emailId || '',
              phoneNumber: invite.phoneNumber || '',
              status: invite.status || 'INVITED',
              community: invite.community || selectedCommunityId,
              message: invite.message || '',
              id: index,
            }))
          : [
              {
                firstName: '',
                lastName: '',
                fullName: '',
                role: '',
                emailId: '',
                phoneNumber: '',
                status: 'INVITED',
                community: selectedCommunityId,
                message: '',
                id: 0,
              },
            ],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const isDuplicateInInviteList = (emailId: string, phoneNumber: string) => {
    const duplicates = inviteListRef?.current?.filter(
      (item: Invitations) =>
        (item.emailId && item.emailId === emailId) ||
        (item.phoneNumber && item.phoneNumber.toString() === phoneNumber)
    );
    return duplicates.length > 0;
  };

  const isDuplicateMemberList = (emailId: string, phoneNumber: string) => {
    const duplicates = memberListRef?.current?.filter(
      (item: CommunityMembers) =>
        (item?.user?.emailId && item?.user.emailId === emailId) ||
        (item?.user?.phoneNumber &&
          item?.user.phoneNumber.toString() === phoneNumber)
    );
    return duplicates.length > 0;
  };

  const addUsersAsync = async (accessToken: any, usersToAdd: any) => {
    try {
      const response = await addInvites(usersToAdd, selectedCommunityId ?? '');
      if (response && response.status === 201) {
        updateInvitationList(response.data.data);

        return 'Users added successfully';
      } else {
        console.error('Error adding users:', response);
        throw new Error('Error adding users');
      }
    } catch (error) {
      console.error('Error adding users:', error);
      throw error;
    }
  };

  const updateSelectedInvites = (
    accessToken: any,
    communityId: string,
    updatedData: any
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        await updateSelectedInvitations(communityId, updatedData);
        refetchInvitesData();
        resolve('User updated successfully');
      } catch (error) {
        reject(error);
      }
    });
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const usersToAdd = data.users.map((user: any) => {
        const fullNameParts = user.fullName.split(' ');
        const firstName = fullNameParts[0];
        const lastName = fullNameParts.slice(1).join(' ');
        return {
          firstName,
          lastName,
          role: user.role,
          emailId: user.emailId,
          phoneNumber: user.phoneNumber,
          status: 'INVITED',
          community: selectedCommunityId,
          message: customMessages,
        };
      });

      const accessToken = getAccessToken();

      // Separate selected invitations and new invitations
      const selectedInvitationsToUpdate: Invitations[] = [];
      const newInvitationsToAdd: Invitations[] = [];

      usersToAdd.forEach((newUser: any, index: number) => {
        if (selectedInvitations && selectedInvitations[index]?._id) {
          // Existing invitation, add to update list
          selectedInvitationsToUpdate.push({
            _id: selectedInvitations[index]._id,
            ...newUser,
          });
        } else {
          // New invitation, add to add list
          newInvitationsToAdd.push(newUser);
        }
      });

      const isDuplicateInMemberListNewInvite = newInvitationsToAdd.some(
        (user: any) =>
          isDuplicateMemberList(user.emailId, user.phoneNumber) ||
          isDuplicateInInviteList(user.emailId, user.phoneNumber)
      );

      if (isDuplicateInMemberListNewInvite) {
        // Print message and return, do not make API calls
        setDuplicateError(
          'The email or phoneNumber you provided are already in use.'
        );
        console.error(
          'Duplicate emailId or phoneNumber found in member/invite for new invitations'
        );
        return;
      } else {
        // Clear duplicate error before making API calls
        setDuplicateError(null);
      }

      if (selectedInvitationsToUpdate.length > 0) {
        const updatedData = {
          data: selectedInvitationsToUpdate,
        };
        await updateSelectedInvites(
          accessToken,
          selectedCommunityId ?? '',
          updatedData
        );
      }

      // Add new invitations
      if (newInvitationsToAdd.length > 0) {
        // Check duplicates for new invitations only
        const isDuplicateInMemberListNewInvite = newInvitationsToAdd.some(
          (user: any) =>
            isDuplicateMemberList(user.emailId, user.phoneNumber) ||
            isDuplicateInInviteList(user.emailId, user.phoneNumber)
        );

        if (isDuplicateInMemberListNewInvite) {
          setDuplicateError(
            'The email or phoneNumber you provided are already in use.'
          );
          return;
        } else {
          setDuplicateError(null);

          // Add new invitations only if no duplicates found
          const newInvitesData = { data: newInvitationsToAdd };
          const emailSet = new Set();
          const phoneSet = new Set();
          const formFields = newInvitesData.data;

          for (const field of formFields) {
            // Check if the email is not empty before checking for duplicates
            if (field.emailId && emailSet.has(field.emailId)) {
              setDuplicateError('Duplicate email found!');
              return;
            }

            if (field.phoneNumber && phoneSet.has(field.phoneNumber)) {
              setDuplicateError('Duplicate phone number found!');
              return;
            }

            if (field.emailId) {
              emailSet.add(field.emailId);
            }

            if (field.phoneNumber) {
              phoneSet.add(field.phoneNumber);
            }
          }

          setDuplicateError('');
          await addUsersAsync(accessToken, newInvitesData);
          refetchInvitesData();
        }
      }

      setValue('users', []);
      onClose();
    } catch (error) {
      console.error('Error adding or updating users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdd = () => {
    if (!initialInviteData || fields.length < initialInviteData.length) {
      append({
        firstName: '',
        lastName: '',
        fullName: '',
        role: '',
        emailId: '',
        phoneNumber: '',
        status: 'INVITED',
        community: selectedCommunityId,
        message: customMessages,
        id: fields.length,
      });
    } else {
      append({
        firstName: '',
        lastName: '',
        fullName: '',
        role: '',
        emailId: '',
        phoneNumber: '',
        status: 'INVITED',
        community: selectedCommunityId,
        message: customMessages,
        id: fields.length,
      });
    }
  };

  const handleCloseInvitations = () => {
    setShowInvitations(false);
    getInviteList(selectedCommunityId ?? '');
  };

  return (
    <Stack direction={'column'} sx={{ height: '100%' }}>
      <SubNavbar />
      <ContentWrapper>
        <Stack spacing={1}>
          <Typography sx={{}}>
            {/* {initialInviteData.length > 0 ? 'Edit Invitations' : 'Add Invitations'} */}
          </Typography>

          <Box component="form">
            <Stack>
              {fields.map((item: any, index: number) => (
                <AddNewUserForm
                  key={item.id}
                  form={item}
                  index={index}
                  setValue={setValue}
                  inviteList={inviteList}
                  {...{ register, remove, errors, fields }}
                />
              ))}
            </Stack>
          </Box>
          {selectedInvitations.length === 0 && (
            <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Send New Invitation" placement="left">
                <Box
                  onClick={handleAdd}
                  sx={{
                    backgroundColor: '#3C5AA0',
                    padding: '8px',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    // "&:hover": {
                    //   backgroundColor: "#50A1CA", // Background color on hover
                    //   cursor: "pointer",
                    //   border: "none",
                    // },
                  }}
                >
                  <AddIcon
                    style={{
                      fontSize: '16px',
                      color: '#ffffff',
                    }}
                  />
                </Box>
              </Tooltip>
            </Grid>
          )}
        </Stack>
        <Typography
          sx={{
            color: '#B1B1B1',
            paddingTop: '2px',
            paddingBottom: '5px',
            fontSize: '13px',
            pb: '7px',
            mb: '5px',
          }}
        >
          Make your invites personal with a custom message
        </Typography>
        <Box sx={{ width: '100%' }}>
          <TextField
            id="outlined-basic"
            label="Custom Message"
            size="medium"
            fullWidth
            variant="outlined"
            multiline
            rows={2}
            inputProps={{ style: { width: '100%', height: '30%' } }}
            onChange={(e) => setCustomMessages(e.target.value)}
            InputLabelProps={{ style: { fontSize: '13px' } }}
            value={
              customMessages ||
              (initialInviteData.length > 0
                ? initialInviteData?.map((invite) => invite.message).join('\n')
                : '')
            }
          />
        </Box>
        <Box>
          {duplicateError && (
            <Typography
              sx={{ color: 'red', marginBottom: '10px', fontSize: '12px' }}
            >
              {duplicateError}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            mt: 1.5,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 1, sm: 2, md: 2 },
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            sx={{
              px: 2,
              textTransform: 'capitalize',
              borderRadius: '4px',
              background: '#3C5AA0',
              '&:hover': {
                backgroundColor: '#3C5AA0',
                cursor: 'pointer',
                border: 'none',
              },
            }}
            disabled={!isValid}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              'Send Invitation'
            )}
          </Button>
        </Box>

        {showInvitations && (
          <Dialog
            fullWidth
            maxWidth="lg"
            open={showInvitations}
            onClose={handleCloseInvitations}
          >
            <DialogContent>
              <Invitation openMode={false} />
            </DialogContent>
          </Dialog>
        )}
      </ContentWrapper>
    </Stack>
  );
};
