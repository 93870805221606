import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Stack } from '@mui/system';
import { CustomTableHeader } from './components/CustomTableHeader';
import { IColumn } from '../../models/table.model';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TableContainer,
  TablePagination,
  Tooltip,
  Typography,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import { ISubscribers } from '../../models/subscription.model';
import { getStaticValue } from '../../utils/StaticValues';
import { useSubscription } from '../../hooks/useSubscription';
import Loader from '../Loader/Loader';
import { useUsers } from '../../hooks/useUsers';

interface ITableProps {
  data: ISubscribers[];
  columns: IColumn[];
  subscriptionList: () => void;
}

export const SubscriptionListTable = ({
  columns,
  data = [],
  subscriptionList,
}: ITableProps) => {
  const [sortedColumns, setSortedColumns] = useState<string[]>([]);
  const [tableData, setTableData] = useState<ISubscribers[]>(data);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [, setUpdateStatus] = useState('');
  const [, setDeleteSubscriber] = useState('');

  const { changeSequenceStatusId } = useSubscription();

  const { removeSubscriberUserById } = useUsers();

  useEffect(() => {
    // console.log("Incoming data:", data);
    setTableData(Array.isArray(data) ? data : []);
  }, [data]);

  // console.log(tableData, "tableData");
  const sortedData = Array.isArray(tableData) ? [...tableData] : [];

  // console.log(sortedData, "sortedData");
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: 'asc' | 'desc' | undefined;
  }>({});

  const handleSort = (columnTitle: string) => {
    setSortDirections((prevSortDirections) => {
      let newSortDirections: { [key: string]: 'asc' | 'desc' | undefined } = {};
      let newSortedColumns: string[] = [];

      // Toggle the sorting direction for the clicked column
      if (prevSortDirections[columnTitle] === 'asc') {
        newSortDirections[columnTitle] = 'desc';
      } else {
        newSortDirections[columnTitle] = 'asc';
      }

      // Include the clicked column in the list of sorted columns
      newSortedColumns.push(columnTitle);

      setSortedColumns(newSortedColumns);

      return newSortDirections;
    });
  };

  const sortedColumnTitles = sortedColumns?.filter(
    (columnTitle) => sortDirections[columnTitle] !== undefined
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    handleChangePage(null, 0);
  }, [data]);
  const startIndex = page * rowsPerPage;
  const endIndex = (page + 1) * rowsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);
  const navigate = useNavigate();

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    itemId: string
  ) => {
    setAnchorEl(event.currentTarget as HTMLElement);
    setSelectedItemId(itemId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };

  const updateSequenceStatusByRow = async (
    communityID: string,
    sequenceID: string,
    subscription_status: string
  ) => {
    setIsLoading(true);
    try {
      const updateResponse = await changeSequenceStatusId(
        communityID,
        sequenceID,
        subscription_status
      );
      setUpdateStatus(updateResponse);
      subscriptionList();
    } catch (err) {
      console.log('Error: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSubscriber = async (planId: string, userId: string) => {
    setIsLoading(true);
    try {
      const deleteResponse = await removeSubscriberUserById(planId, userId);
      // setDeleteSubscriber(deleteResponse);
      subscriptionList();
    } catch (err) {
      console.log('Error: ', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {' '}
      <PerfectScrollbar>
        <TableContainer
          component={Paper}
          elevation={0}
          sx={{
            height: { xs: 'calc(100vh - 32vh)', md: 'calc(100vh - 25vh)' },
          }}
        >
          <Table
            sx={{
              '& th': {
                fontSize: '12px',
                fontWeight: 700,
                color: '#565656',
                p: 1,
              },
              '& td': {
                fontSize: '15px',
                fontWeight: 500,

                color: '#1A1A1A',
                p: '7px 10px',
                '& .MuiTypography-root': { m: 0, fontSize: '11px' },
              },
            }}
          >
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                background: '#ffffff',
              }}
            >
              <TableRow sx={{}}>
                {columns?.map((column: IColumn, i: number) => (
                  <TableCell
                    key={i}
                    onClick={() => handleSort(column.title)}
                    sx={{ width: '100px' }}
                  >
                    <CustomTableHeader
                      title={column.title}
                      sortDirection={sortDirections[column.title]}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* <NoPosts /> */}
              {paginatedData?.map((item: ISubscribers, index) => (
                <TableRow hover key={index}>
                  <TableCell sx={{ width: '220px' }}>
                    <Stack
                      flexDirection={'row'}
                      alignItems={'center'}
                      sx={{ cursor: 'pointer', width: '100px' }}
                      onClick={() => navigate(`/subscribers/${item?._id}`)}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          src={item?.user?.avatar}
                          sx={{
                            height: '40px',
                            width: '40px',
                            fontSize: '11px',
                          }}
                          alt={`${item?.user?.firstName}`}
                        />
                        <Typography
                          style={{
                            marginLeft: '8px',
                            fontWeight: 600,
                            fontSize: '11px !important',
                            color: '#1A1A1A',
                            flexDirection: 'row',
                            minWidth: 100,
                          }}
                        >
                          <Tooltip
                            title={item?.user?.firstName}
                            placement="bottom"
                          >
                            <Stack>{item?.user?.firstName}</Stack>
                          </Tooltip>
                        </Typography>
                      </div>
                    </Stack>
                  </TableCell>
                  <TableCell sx={{ width: '100px' }}>
                    {columns[1].dataKey === 'plan' && (
                      <Typography sx={{ textTransform: 'capitalize' }}>
                        {item?.plan?.name}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell sx={{ width: '100px' }}>
                    {columns[2].dataKey === 'amount' && (
                      <>
                        <Typography>
                          ₹
                          {item?.plan?.offerValue || item?.plan?.totalPlanValue}
                        </Typography>
                      </>
                    )}
                  </TableCell>
                  <TableCell sx={{ width: '100px' }}>
                    <Typography>
                      {item?.plan?.interval}{' '}
                      {getStaticValue(item?.plan?.duration)}
                      { }
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      width: '100px',
                    }}
                  >
                    <Typography>
                      {(() => {
                        const originalDate = new Date(item?.plan?.startDate);
                        const istDate = new Date(
                          originalDate.getTime() + 5.5 * 60 * 60 * 1000
                        );
                        return istDate.toLocaleString('en-GB', {
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                        });
                      })()}
                    </Typography>
                  </TableCell>

                  <TableCell sx={{ width: '100px' }}>
                    <Stack>
                      <Typography>
                        {new Date(item?.startDate).toLocaleString('en-GB', {
                          timeZone: 'Asia/Kolkata',
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                          hour12: false,
                        })}
                      </Typography>
                    </Stack>
                  </TableCell>

                  <TableCell sx={{ width: '100px' }}>
                    <Grid container>
                      <Grid item xs={12} md={12} lg={8}>
                        <Box
                          sx={{
                            fontSize: '13px',
                            fontWeight: '400',
                            boxShadow: 'none',
                            textAlign: 'center',
                            borderRadius: '4px',
                            textTransform: 'capitalize',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            px: 1,
                            py: 0.5,
                          }}
                        >
                          {/* <ArrowDropDownIcon sx={{ cursor: "pointer" }} /> */}

                          <Button
                            sx={{
                              fontSize: '11px',
                              backgroundColor:
                                item?.subscription_status === 'ACTIVE'
                                  ? '#10a00d1a'
                                  : '#ffa87d1a',
                              color:
                                item?.subscription_status === 'ACTIVE'
                                  ? '#10A00D'
                                  : '#ffa87d',
                              border:
                                item?.subscription_status === 'ACTIVE'
                                  ? '1px solid #10a00d1a'
                                  : '1px solid #ffa87d1a',
                              px: 2,
                              borderRadius: '4px',
                              textTransform: 'capitalize',
                            }}
                          >
                            {item?.subscription_status === 'ACTIVE'
                              ? 'Active'
                              : 'Inactive'}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </TableCell>

                  <TableCell sx={{ width: '100px' }}>
                    <Stack
                      display={'flex'}
                      direction={'row'}
                      justifyContent={'space-between'}
                      alignItems={'flex-start'}
                      sx={{ boxShadow: 'none' }}
                    >
                      <IconButton
                        aria-controls={`menu-${index}`}
                        aria-haspopup="true"
                        onClick={(event) => handleClick(event, item?._id!)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id={`menu-${index}`}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedItemId === item?._id}
                        onClose={handleClose}
                        elevation={1}
                      >
                        {item?.subscription_status === 'ACTIVE' ? (
                          <MenuItem
                            sx={{ fontSize: '13px' }}
                            onClick={() => {
                              updateSequenceStatusByRow(
                                item?.community?._id,
                                item._id,
                                'STOP'
                              );
                              handleClose();
                            }}
                            disabled={isLoading}
                          >
                            {isLoading ? <Loader /> : 'Stop'}
                          </MenuItem>
                        ) : (
                          <>
                            <MenuItem
                              sx={{ fontSize: '13px' }}
                              onClick={() => {
                                updateSequenceStatusByRow(
                                  item?.community?._id,
                                  item._id,
                                  'ACTIVE'
                                );
                                handleClose();
                              }}
                              disabled={isLoading}
                            >
                              {isLoading ? 'Loading...' : 'Resume'}
                            </MenuItem>
                          </>
                        )}

                        <MenuItem
                          sx={{ fontSize: '13px' }}
                          onClick={() => {
                            deleteSubscriber(item?.plan?._id, item?.user?._id);
                            handleClose();
                          }}
                          disabled={isLoading}
                        >
                          {isLoading ? <Loader /> : 'Delete'}
                        </MenuItem>
                      </Menu>
                    </Stack>
                  </TableCell>

                  <TableCell sx={{ width: '100px' }}>
                    <Stack>
                      <Button
                        sx={{
                          color: '#3C5AA0',
                          textTransform: 'capitalize',
                          fontWeight: 500,
                        }}
                        onClick={() => navigate(`/subscribers/${item?._id}`)}
                        endIcon={<ArrowRightIcon sx={{ mr: 5 }} />}
                      >
                        Details
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <Stack
        direction={'row-reverse'}
        alignItems={'center'}
        sx={{
          color: '#A5A5A5',
          fontSize: { xs: '10px', md: '1.5vh' },
          pr: { xs: '0px', md: '25px' },
        }}
      >
        <Typography
          sx={{
            color: '#A5A5A5',
            fontSize: '13px',
            display: { xs: 'none', md: 'block' },
          }}
        >
          Showing {Math.min(rowsPerPage, data.length - page * rowsPerPage)} of{' '}
          {data.length} entries
        </Typography>
        <TablePagination
          // rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPageOptions={[5, 10, 15, 20]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="View"
          labelDisplayedRows={() => null}
          SelectProps={{
            SelectDisplayProps: {
              style: { border: '1px solid #A5A5A5', borderRadius: '5px' },
            },
            // You can also customize the style of the menu options if desired
            MenuProps: {
              PaperProps: { style: { border: '1px solid #A5A5A5' } },
            },
          }}
          ActionsComponent={({ onPageChange, page }) => {
            const totalPages = Math.ceil(data.length / rowsPerPage);

            return (
              <Stack
                direction={'row'}
                display="flex"
                alignItems="center"
                justifyContent={'space-between'}
              >
                {/* <IconButton
                  onClick={(event) => onPageChange(event, 0)} // Go to the first page
                  disabled={page === 0}
                  aria-label="go to first page"
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton> */}

                <IconButton
                  onClick={(event) => onPageChange(event, page - 1)} // Go to the previous page
                  disabled={page === 0}
                  aria-label="go to previous page"
                >
                  <KeyboardArrowLeft />
                </IconButton>

                {[...Array(3)].map((_, index) => {
                  const pageNumber = page + index;
                  if (pageNumber < totalPages) {
                    return (
                      <IconButton
                        key={pageNumber}
                        onClick={(event) => onPageChange(event, pageNumber)}
                        style={{
                          fontSize: '1.9vh',
                          border:
                            page === pageNumber ? '1px solid #3C5AA0' : 'none', // Highlight the active page with a border
                          color: page === pageNumber ? '#3C5AA0' : '#000000', // Highlight the active page with a color
                          width: page === pageNumber ? '30px' : '30px',
                          height: page === pageNumber ? '30px' : '30px',
                        }}
                      >
                        {pageNumber + 1}
                      </IconButton>
                    );
                  } else {
                    return null; // Don't render the button if it's out of bounds
                  }
                })}

                <IconButton
                  onClick={(event) => onPageChange(event, page + 1)} // Go to the next page
                  disabled={page >= totalPages - 1}
                  aria-label="next page"
                >
                  <KeyboardArrowRight />
                </IconButton>

                {/* <IconButton
                  onClick={(event) => onPageChange(event, totalPages - 1)} // Go to the last page
                  aria-label="go to last page"
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton> */}
              </Stack>
            );
          }}
        />
      </Stack>
    </>
  );
};
