import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Divider,
  ButtonGroup,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { updateCommunityService } from '../../services/communities.service';
import { AuthContext, IAuthContext } from '../../contexts/Auth.context';
import { useCommunity } from '../../hooks/useCommunity';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CreateCommunity } from './CreateCommunity';
import { loadSelectedCommunity } from '../../store/slices/CommunitySlice';
import { useSnackbar } from 'notistack';
import InfoIcon from '@mui/icons-material/Info';
import { Helmet } from 'react-helmet';
import { ICreateCommunity } from '../../models/communities.modal';
import { useDispatch } from 'react-redux';
// import zIndex from "@mui/material/styles/zIndex";
interface IProps {
  CommunityData: ICreateCommunity | null;
  updateCommunityData: (data: Partial<ICreateCommunity>) => void;
  setCommunityData: any;
}

export interface SocialLink {
  type: string;
  value: string;
}
export interface IService {
  title: String;
  description: String;
  status?: String;
  images: String[];
}

export interface IWebService {
  title: String;
  description: String;
  status?: String;
  images?: String;
  community?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ITeam {
  name: String;
  designation: String;
  experience: String;
  description: String;
  avatar: String;
}

export interface IFaq {
  _id: any;

  question: string;
  answer: string;
}

export interface IUpdateCommunity {
  community: string;
  zipCode: number;
  fullAddress: string;
  description: string;
  vision: string;
  mission: string;
  adminMessage: string;
  directorMessage: string;
  phoneNumber: number;
  email: string;
  location: string;
  mobileNumber: number;
  logo: string;
  banner: string;
  gallery: string[];
  socialLinks: SocialLink[] | null;
  services: IService[] | undefined;
  status: string | undefined;
  teams: ITeam[];
  faq: IFaq[];
}

export const CreateCommunityPage2 = ({
  CommunityData,
  updateCommunityData,
  setCommunityData,
}: IProps) => {
  const { id } = useParams();
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { createNewCommunity } = useCommunity();
  const navigate = useNavigate();
  const [page, setPage] = useState(2);
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = React.useState('PRIVATE');
  const [selectedSubscription, setSelectedSubscription] = React.useState('YES');
  // eslint-disable-next-line
  // const [isSelectionMade, setIsSelectionMade] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { getCommunityById } = useCommunity();
  const [errorMessage, setErrorMessage] = useState('');

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    updateCommunityData({ [name]: value });
  };

  // console.log(CommunityData);
  const handleButtonChanges = (event: any) => {
    updateCommunityData({ type: event });
  };
  const handleButtonSubscription = (event: any) => {
    updateCommunityData({ collectSubscription: event });
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      CommunityData &&
      CommunityData.maxMembers &&
      CommunityData.maxMembers < 0
    ) {
      // Set the error message
      setErrorMessage('Max members must be a positive value');
      return;
    }
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      const communityObject = { community: CommunityData };
      console.log(communityObject, 'cccc');
      if (id) {
        const response = await updateCommunityService(
          getAccessToken(),
          id,
          CommunityData
        );
        if (response && response.status === 200) {
          navigate('/communities');
          enqueueSnackbar('Community updated successfully', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Community updated failed', { variant: 'error' });
        }
      } else {
        const response = await createNewCommunity(CommunityData);

        if (response && response.status === 201) {
          await getCommunityById(response.data._id);
          navigate('/communities/create-community', {
            state: { id: response?.data._id },
          });
          dispatch(loadSelectedCommunity(response.data));
        } else {
        }
      }
    } catch (error) {
      console.log('An error occurred:', error);
    }
  };

  //   location.pathname ===
  //   `/communities/${selectedCommunity?.selectedCommunity?.id}`
  //     ? CommunityData.name || ""
  //     : communityName;
  // const Category =
  //   location.pathname ===
  //   `/communities/${selectedCommunity?.selectedCommunity?.id}`
  //     ? getStaticValue(CommunityData?.category)
  //     : selectedCategory && selectedCategory.toUpperCase();
  // console.log(Category);

  // const handleGoBack = () => {
  //   navigate(-1); // Go back one page in the browser history

  //   const formData = JSON.stringify(CommunityData);
  //   console.log("CommunityData", formData);
  //   console.log("CommunityData", CommunityData);
  //   await updateCommunityService(id, getAccessToken(), formData);

  // };

  // Toolpit text
  const subscription = `
   <strong>Subscription:</strong>
   Offer exclusive content or benefits.
    Manage business memberships and track payments through
     Communn and generate recurring revenue<br/>
<strong>No Subscription:</strong>
Keep your community open and accessible to all without paid restrictions.
 `;

  const type = `
 Consider your community's nature and intended audience to make the right choice<br />
 <strong>Private Community:</strong> A group only for those with an invitation, like a secret club.<br />
 <strong>Public Community:</strong> A community that's open for everyone, like a public park.
`;

  const locations = useLocation();
  const isExploreCreateCommunityPage =
    locations.pathname === '/explore-create-community';

  const isSaveButtonEnabled = () => {
    const val = selectedValue && selectedSubscription !== '' ? true : false;

    return val;
  };
  return (
    <>
      <Helmet>
        <title>
          {id ? 'Update ' : 'Create'} community - Communn - Build & Manage your
          community
        </title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
          Subscription effortlessly
          Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
      {page === 2 && (
        <>
          <Stack
            direction={'row'}
            spacing={1}
            display={'flex'}
            alignItems={'center'}
          >
            <Typography>
              {' '}
              {CommunityData?.status !== 'PUBLISHED' && (
                <ArrowBackIcon
                  sx={{
                    color: isExploreCreateCommunityPage ? '#50A1CA' : '#416DAB',
                    cursor: 'pointer',
                    mt: 1,
                  }}
                  onClick={() => setPage(1)}
                />
              )}
            </Typography>

            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 500,
                color: isExploreCreateCommunityPage ? '#50A1CA' : '#3760A9',
                fontFamily: 'Inter',
                ml: 1,
              }}
            >
              {id ? 'Update ' : 'Create'} community
            </Typography>
          </Stack>
          <Box
            sx={{ backgroundColor: '#ffffff', p: 2, pb: 5, borderRadius: 5 }}
          >
            <Box>
              <Stack>
                <Stack direction={'row'} sx={{ display: 'flex' }}>
                  <Stack direction={'row'}></Stack>
                  <Typography
                    sx={{
                      color: '#A4A4A4',
                      fontSize: '16px',
                      fontWeight: '400',
                    }}
                  >
                    Basic Details
                  </Typography>
                  {/* <Link
                    to="/faq"
                    style={{
                      color: "#50A1CA",
                      fontSize: "12px",
                      fontWeight: "500",
                      
                    }}
                  >
                    Need assistance?
                  </Link> */}
                </Stack>
                <Divider></Divider>

                <Stack sx={{ pt: 5 }}>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        focused
                        fullWidth
                        label="Community name"
                        id="outlined-size-small"
                        size="small"
                        // sx={{ fontWeight: "bold" }}
                        name="Name"
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={handleChanges}
                        value={CommunityData?.name}
                        InputLabelProps={{
                          shrink: true,
                          style: { zIndex: 0 },
                        }}
                        sx={{
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#575757',
                          },
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                          {
                            border: '1.5px solid #D6D6D6',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>

                <Stack sx={{ pt: 3 }}>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        focused
                        fullWidth
                        label="Selected category"
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        name="category"
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={handleChanges}
                        value={
                          (CommunityData?.category === 'Other'
                            ? CommunityData?.otherCategory
                            : CommunityData?.category) ||
                          (CommunityData?.otherCategory === ''
                            ? CommunityData?.category
                            : CommunityData?.otherCategory)
                        }
                        InputLabelProps={{
                          shrink: true,
                          style: { zIndex: 0 },
                        }}
                        sx={{
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#575757',
                          },
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                          {
                            border: '1.5px solid #D6D6D6',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>

                <Stack sx={{ pt: 3 }}>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        focused
                        fullWidth
                        label="No of members(approx)"
                        id="outlined-size-small"
                        size="small"
                        type="number"
                        name="maxMembers"
                        onChange={handleChanges}
                        error={errorMessage !== ''}
                        helperText={errorMessage}
                        value={
                          CommunityData?.maxMembers !== 0
                            ? CommunityData?.maxMembers
                            : ''
                        }
                        InputLabelProps={{
                          style: { zIndex: 0 },
                        }}
                        sx={{
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#575757',
                          },
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                          {
                            border: '1.5px solid #D6D6D6',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>

                <Stack sx={{ pt: 3 }}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Stack
                        direction={'row'}
                        sx={{
                          justifyContent: 'flex-start',
                          display: {
                            xs: 'grid',
                            sm: 'grid',
                            md: 'grid',
                            lg: 'flex',
                          },
                        }}
                        spacing={{ xs: 0, sm: 0, md: 0, lg: 5 }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              color: '#1A1A1A',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}
                          >
                            {/* Mark community as{" "} */}
                            Privacy ?
                            <Tooltip
                              title={
                                <span
                                  dangerouslySetInnerHTML={{ __html: type }}
                                />
                              }
                              placement="top"
                              arrow
                            >
                              <IconButton>
                                <InfoIcon
                                  sx={{
                                    fontSize: '16px',
                                    color: isExploreCreateCommunityPage
                                      ? '#50A1CA'
                                      : '#416DAB',
                                    ml: -1,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',

                              '& > *': {
                                mt: 1,
                              },
                            }}
                          >
                            <ButtonGroup
                              variant="outlined"
                              aria-label="outlined button group"
                              sx={{ color: '#C6C6C6' }}
                            >
                              <Button
                                sx={{
                                  color: `${selectedValue === 'PUBLIC'
                                    ? '#ffffff'
                                    : '#000000'
                                    }`,
                                  borderRight: '1px solid #C6C6C6',
                                  borderTop: '1px solid #C6C6C6',
                                  borderBottom: '1px solid #C6C6C6',
                                  fontWeight: '400',
                                  borderLeft: '1px solid C6C6C6',
                                  backgroundColor: `${selectedValue === 'PUBLIC' &&
                                    (isExploreCreateCommunityPage
                                      ? '#50A1CA'
                                      : '#416DAB')
                                    }`,
                                  textTransform: 'capitalize',
                                  width: '17vh',
                                  '&:hover': {
                                    backgroundColor: `${selectedValue === 'PUBLIC' &&
                                      (isExploreCreateCommunityPage
                                        ? '#50A1CA'
                                        : '#416DAB')
                                      }`, // Background color on hover
                                  },
                                }}
                                onClick={() => {
                                  setSelectedValue('PUBLIC');
                                  handleButtonChanges('PUBLIC');
                                  // setIsSelectionMade(true);
                                }}
                              >
                                Public
                              </Button>
                              <Button
                                sx={{
                                  color: `${selectedValue === 'PRIVATE'
                                    ? '#ffffff'
                                    : '#000000'
                                    }`,
                                  borderRight: '1px solid #C6C6C6',
                                  borderTop: '1px solid #C6C6C6',
                                  borderBottom: '1px solid #C6C6C6',
                                  borderLeft: '1px solid C6C6C6',
                                  fontWeight: '400',
                                  backgroundColor: `${selectedValue === 'PRIVATE'
                                    ? isExploreCreateCommunityPage
                                      ? '#50A1CA'
                                      : '#416DAB'
                                    : 'white'
                                    }`,
                                  textTransform: 'capitalize',
                                  width: '17vh',
                                  '&:hover': {
                                    backgroundColor: `${selectedValue === 'PRIVATE' &&
                                      (isExploreCreateCommunityPage
                                        ? '#50A1CA'
                                        : '#416DAB')
                                      }`, // Background color on hover
                                  },
                                }}
                                onClick={() => {
                                  setSelectedValue('PRIVATE');
                                  handleButtonChanges('PRIVATE');
                                  // setIsSelectionMade(true);
                                }}
                              >
                                Private
                              </Button>
                            </ButtonGroup>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              color: '#1A1A1A',
                              fontSize: '14px',
                              fontWeight: '400',
                            }}
                          >
                            Do you collect subscription?{' '}
                            <Tooltip
                              title={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: subscription,
                                  }}
                                />
                              }
                              placement="top"
                              arrow
                            >
                              <IconButton>
                                <InfoIcon
                                  sx={{
                                    fontSize: '16px',
                                    color: isExploreCreateCommunityPage
                                      ? '#50A1CA'
                                      : '#416DAB',

                                    ml: -1,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              '& > *': {
                                mt: 1,
                              },
                            }}
                          >
                            <ButtonGroup
                              variant="outlined"
                              aria-label="outlined button group"
                              sx={{ color: '#C6C6C6' }}
                            >
                              <Button
                                sx={{
                                  color: `${selectedSubscription === 'YES'
                                    ? '#ffffff'
                                    : '#000000'
                                    }`,
                                  borderRight: '1px solid #C6C6C6',
                                  borderTop: '1px solid #C6C6C6',
                                  borderBottom: '1px solid #C6C6C6',
                                  borderLeft: '1px solid C6C6C6',
                                  fontWeight: '400',
                                  backgroundColor: `${selectedSubscription === 'YES'
                                    ? isExploreCreateCommunityPage
                                      ? '#50A1CA'
                                      : '#416DAB'
                                    : 'white'
                                    }`,
                                  textTransform: 'capitalize',
                                  width: '17vh',
                                  '&:hover': {
                                    backgroundColor: `${selectedSubscription === 'YES' &&
                                      (isExploreCreateCommunityPage
                                        ? '#50A1CA'
                                        : '#416DAB')
                                      }`, // Background color on hover
                                  },
                                }}
                                onClick={() => {
                                  setSelectedSubscription('YES');
                                  handleButtonSubscription('YES');
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                sx={{
                                  color: `${selectedSubscription === 'NO'
                                    ? '#ffffff'
                                    : '#000000'
                                    }`,
                                  borderRight: '1px solid #C6C6C6',
                                  borderTop: '1px solid #C6C6C6',
                                  borderBottom: '1px solid #C6C6C6',
                                  borderLeft: '1px solid C6C6C6',
                                  fontWeight: '400',
                                  backgroundColor: `${selectedSubscription === 'NO'
                                    ? isExploreCreateCommunityPage
                                      ? '#50A1CA'
                                      : '#416DAB'
                                    : 'white'
                                    }`,
                                  width: '17vh',
                                  textTransform: 'capitalize',
                                  '&:hover': {
                                    backgroundColor: `${selectedSubscription === 'NO' &&
                                      (isExploreCreateCommunityPage
                                        ? '#50A1CA'
                                        : '#416DAB')
                                      }`, // Background color on hover
                                  },
                                }}
                                onClick={() => {
                                  setSelectedSubscription('NO');
                                  handleButtonSubscription('NO');
                                }}
                              >
                                No
                              </Button>
                            </ButtonGroup>
                          </Box>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>

                <Stack sx={{ pt: 3 }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Stack
                        direction={'row'}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          mb: 1,
                        }}
                      >
                        <Typography sx={{ fontSize: '14px' }}>
                          Community address
                        </Typography>
                      </Stack>
                      <TextField
                        focused
                        fullWidth
                        label="Appatment, suite, area etc."
                        id="outlined-size-small"
                        size="small"
                        type="text"
                        name="fullAddress"
                        onChange={handleChanges}
                        value={CommunityData?.fullAddress}
                        InputLabelProps={{
                          style: { zIndex: 0 },
                        }}
                        sx={{
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#575757',
                          },
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                          {
                            border: '1.5px solid #D6D6D6',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>

                <Stack sx={{ pt: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                      <Stack direction="row">
                        <TextField
                          focused
                          size="small"
                          fullWidth
                          label="Pin/Zip code"
                          id="fullWidth"
                          name="zipCode"
                          onChange={handleChanges}
                          value={CommunityData?.zipCode}
                          InputLabelProps={{
                            style: { zIndex: 0 },
                          }}
                          sx={{
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#575757',
                            },
                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                            {
                              border: '1.5px solid #D6D6D6',
                            },
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <Stack direction="row">
                        <TextField
                          focused
                          size="small"
                          fullWidth
                          label="City"
                          onChange={handleChanges}
                          name="city"
                          value={CommunityData?.city}
                          id="fullWidth"
                          InputLabelProps={{
                            style: { zIndex: 0 },
                          }}
                          sx={{
                            '& .MuiInputLabel-root.Mui-focused': {
                              color: '#575757',
                            },
                            '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                            {
                              border: '1.5px solid #D6D6D6',
                            },
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        focused
                        size="small"
                        fullWidth
                        id="fullWidth"
                        label="GST No (Optional)"
                        name="gstNumber"
                        onChange={handleChanges}
                        value={CommunityData?.gstNumber}
                        InputLabelProps={{
                          style: { zIndex: 0 },
                        }}
                        sx={{
                          '& .MuiInputLabel-root.Mui-focused': {
                            color: '#575757',
                          },
                          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                          {
                            border: '1.5px solid #D6D6D6',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>

                <Box sx={{ pt: 3, mb: { xs: 5, md: 1 } }}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="economy"
                    sx={{
                      background: isExploreCreateCommunityPage
                        ? isSaveButtonEnabled()
                          ? 'linear-gradient(to right bottom, #50a1ca, #3b9b7f)'
                          : 'red' // Set the background color to red when disabled
                        : isSaveButtonEnabled()
                          ? 'linear-gradient(to right bottom, #3C5AA0, #50A1CA)'
                          : 'red', // Set the background color to red when disabled
                      textTransform: 'capitalize',
                      borderRadius: '10px',
                      width: '190px',
                      fontFamily: 'Inter',
                    }}
                    onClick={handleSubmit}
                    disabled={!isSaveButtonEnabled()}
                  >
                    {id ? 'Update' : 'Create'} Community
                  </Button>
                </Box>
              </Stack>
            </Box>
          </Box>
        </>
      )}

      {page === 1 && <CreateCommunity communitydata={CommunityData} />}
    </>
  );
};
