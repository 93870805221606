import { useContext, useState } from 'react';

import { useSnackbar } from 'notistack';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';

import { BankAccInterface } from '../models/BankAcc.models';

import {
  getMyBankAccounts,
  addBankAccountsByCommunity,
  deleteBankDetailsById,
  updateBankDetails,
  getBankDetailsById,
  deleteManyBankDetailsByIds,
} from '../services/bankAccount.service';

export const useBankAccounts = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [BankDetails, setBankDetails] = useState<BankAccInterface[]>([]);

  const getAllMyBankAccounts = async () => {
    try {
      setIsLoading(true);
      const response = await getMyBankAccounts(getAccessToken());
      if (response?.status === 200) {
        setBankDetails(response?.data);
        return response.data;
      } else {
        enqueueSnackbar('Fetching BankAccounts failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch {
      enqueueSnackbar('Fetching BankAccounts failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addCommunityBankDetails = async (id: string, bankDetails: any) => {
    try {
      setIsLoading(true);
      const response = await addBankAccountsByCommunity(
        getAccessToken(),
        id,
        bankDetails
      );
      if (response?.status === 201) {
        setBankDetails(response?.data);
        enqueueSnackbar('Fetching BankAccounts successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        return response.data;
      } else if (response?.status === 200) {
        enqueueSnackbar(response?.data?.message, {
          variant: 'warning',
          autoHideDuration: 5000,
        });
      } else {
        enqueueSnackbar('Fetching BankAccounts failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch {
      enqueueSnackbar('Fetching BankAccounts failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteBankDetailsId = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await deleteBankDetailsById(getAccessToken(), id);
      if (response?.status === 200) {
        setBankDetails(response?.data);
        enqueueSnackbar('Bank Details Deleted Successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        return response.data;
      } else {
        enqueueSnackbar('Bank Details Delete  failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch {
      enqueueSnackbar('Bank Details Delete failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const deleteManyBankDetailsIds = async (id: any) => {
    try {
      setIsLoading(true);
      const response = await deleteManyBankDetailsByIds(getAccessToken(), id);
      if (response?.status === 200) {
        setBankDetails(response?.data);
        enqueueSnackbar('Bank Details Deleted Successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        return response.data;
      } else {
        enqueueSnackbar('Bank Details Delete  Failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch {
      enqueueSnackbar('Bank Details Delete Failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateBankDetail = async (id: string, formData: any) => {
    setIsLoading(true);
    try {
      const response = await updateBankDetails(getAccessToken(), id, formData);

      if (response && response.status === 200) {
        setBankDetails(response.data);
        enqueueSnackbar('updated BankDetails successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      } else {
        enqueueSnackbar('Failed to update BankDetails', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch (error) {
      enqueueSnackbar('An error occurred while update the BankDetails', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    setIsLoading(false);
  };

  const getBankDetailById = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await getBankDetailsById(getAccessToken(), id);
      if (response?.status === 200) {
        setBankDetails(response?.data);
        // enqueueSnackbar("Fetching BankAccounts successfully", { variant: "success" });
        return response.data;
      } else {
        enqueueSnackbar('Fetching BankAccounts failed', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch {
      enqueueSnackbar('Fetching BankAccounts failed', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    BankDetails,
    getAllMyBankAccounts,
    addCommunityBankDetails,
    deleteBankDetailsId,
    updateBankDetail,
    getBankDetailById,
    deleteManyBankDetailsIds,
  };
};
