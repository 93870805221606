import { useContext } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import {
  updateContact,
  getWebsiteData,
  updateAbout,
  updateFaq,
  updateService,
  updateTeam,
  updateBanner,
} from '../services/webBuilder.service';
import { uploadImage } from '../services/getImageUrl.service';
import { IWebAboutUs, IWebBanners, IWebContact, IWebFaqs, IWebService, IWebTeam } from '../models/builder.model';

export const useWebBuilders = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);


  const webBuilderData = async ( communityId:string,)=> {
    try {
      const response = await getWebsiteData(communityId )
      return response
    }catch(error) {
      console.log(error)
    }
  }


  const updateAboutUs = async (communityId: string, aboutDetails:IWebAboutUs) => {
    try {
      const response = await updateAbout(getAccessToken(),communityId, aboutDetails);
      return response;
    } catch (error) {
      console.log(error);
      console.error("Error in updateContacts:", error);
        throw error; 
    }
  };

  const updateServices = async (serviceDetails:IWebService[], communityId: string) => {
    try {
      const response = await updateService(getAccessToken(),communityId,serviceDetails);
      return response;
    } catch (error) {
      console.log(error);
      console.error("Error in updateContacts:", error);
      throw error; 
    }
  };


  const updateBanners = async (bannerDetails:IWebBanners[], communityId: string) => {
    try {
      const response = await updateBanner(getAccessToken(),communityId,bannerDetails);
      return response;
    } catch (error) {
      console.log(error);
      console.error("Error in bannerUploading:", error);
      throw error; 
    }
  };



  const uploadImages = async (imageFiles: File[]) => {
    try {
      const token = getAccessToken();
      const uploadPromises = imageFiles.map((imageFile) => uploadImage(token, imageFile));
      const responses = await Promise.all(uploadPromises);
      console.log('All images uploaded successfully:', responses);
      return responses; 
    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  };
  



  const updateTeams = async (teamDetails:IWebTeam[], communityId: string) => {
    try {
      const response = await updateTeam(getAccessToken(),communityId,teamDetails);
      return response;
    } catch (error) {
      console.log(error);
      console.error("Error in updateContacts:", error);
      throw error; 
    }
  };



  
  const updateFaqs = async (faqDetails:IWebFaqs[], communityId: string) => {
    try {
      const response = await updateFaq( getAccessToken(), communityId, faqDetails);
      return response;
    } catch (error) {
      console.log(error);
      console.error("Error in updateContacts:", error);
        throw error; 
    }
  };


  const updateContacts = async (communityId: string, contactDetails: IWebContact) => {
    try {
        const response = await updateContact(getAccessToken(), communityId, contactDetails);
        return response;
    } catch (error) {
        console.error("Error in updateContacts:", error);
        throw error; 
    }
};

  
  return {
    webBuilderData,
    updateAboutUs,
    updateServices,
    uploadImages,
    updateFaqs,
    updateContacts,
    updateTeams,
    updateBanners,
  };
};
