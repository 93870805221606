import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CssBaseline, ThemeProvider, createTheme, Fade } from '@mui/material';
import AuthProvider from './contexts/AuthProvider';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { SnackbarCloseButton } from './components/snackbar/SnackbarCloseButton';
import { Provider } from 'react-redux';
import store, { persister } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  modalTitle: {
    fontSize: '28px',
    fontWeight: 300,
  },
  typography: {
    subtitle0: {
      fontSize: '18px',
    },
  },
  palette: {
    standard: {
      main: '#FFBC11',
      contrastText: '#fff',
    },
    economy: {
      main: '#4578F9',
      contrastText: '#fff',
    },
    business: {
      main: '#43B430',
      contrastText: '#fff',
    },
    others: {
      main: '#CB3EFF',
      contrastText: '#fff',
    },
  },
});
root.render(
  <GoogleOAuthProvider clientId="194091859008-332qnlri8i5a0ngoqd4h82h1mtq432vj.apps.googleusercontent.com">
    {/* <React.StrictMode> */}
    <Provider store={store}>
      <PersistGate persistor={persister}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              TransitionComponent={Fade}
              hideIconVariant
              action={(snackbarId) => (
                <SnackbarCloseButton snackbarId={snackbarId} />
              )}
            >
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </SnackbarProvider>
          </AuthProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
