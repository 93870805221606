import { useSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import {
  createSubscription,
  fetchSubscriptionByCommunityId,
  updateSubscriptionStatus,
  updateSubscriptionStatusBySequenceId,
} from '../services/subscription.service';
import { IAddSubscription } from '../components/Plans/Subscription/CreateSubscription';

export const useSubscription = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getSubscription = async (id: string, details: IAddSubscription) => {
    setIsLoading(true);
    try {
      const response = await createSubscription(getAccessToken(), id, details);
      if (response.status === 201) {
        enqueueSnackbar('Subscription Created', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        return response;
      } else if (response.status === 500) {
        enqueueSnackbar(`You already added on this plan: ${response.status}`, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return response;
      } else {
        enqueueSnackbar(`Subscription Creation Failed : ${response.status}`, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return response;
      }
    } catch (error) {
      enqueueSnackbar('An unexpected error occurred', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const subscriptionListByCommunityId = async (id: string) => {
    try {
      const response = await fetchSubscriptionByCommunityId(
        getAccessToken(),
        id
      );
      if (response.status === 200) {
        return response.data;
      }
      return response;
    } catch (error) {
      enqueueSnackbar("Couldn't fetch Subscription List", {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const changePaymentStatusBySequenceId = async (
    id: string,
    status: string
  ): Promise<any | null> => {
    try {
      const response = await updateSubscriptionStatusBySequenceId(
        getAccessToken(),
        id,
        status
      );
      if (response.status === 200) {
        enqueueSnackbar('Payment Status Updated Successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        return response.data;
      } else if (response.status === 500 || response.status === 401) {
        enqueueSnackbar(
          'A payment with the same status already exists for this user',
          {
            variant: 'error',
            autoHideDuration: 3000,
          }
        );
        return null;
      } else {
        enqueueSnackbar('Failed to update payment status', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return null;
      }
    } catch (error) {
      console.error('Error in changePaymentStatusBySequenceId:', error);
      enqueueSnackbar("Couldn't Change Payment Status", {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return null;
    }
  };

  const changeSequenceStatusId = async (
    communityId: string,
    subscription_status: string,
    sequenceId: string
  ): Promise<any | null> => {
    try {
      const response = await updateSubscriptionStatus(
        getAccessToken(),
        communityId,
        sequenceId,
        subscription_status
      );
      if (response.status === 200) {
        enqueueSnackbar('Sequence Status Updated Successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        return response.data;
      } else {
        enqueueSnackbar('Failed to update Sequence status', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        return null;
      }
    } catch (error) {
      console.error('Error in changePaymentStatusBySequenceId:', error);
      enqueueSnackbar("Couldn't Change Payment Status", {
        variant: 'error',
        autoHideDuration: 3000,
      });
      return null;
    }
  };

  return {
    isLoading,
    getSubscription,
    subscriptionListByCommunityId,
    changePaymentStatusBySequenceId,
    changeSequenceStatusId,
  };
};
