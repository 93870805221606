import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useAddOns } from '../../hooks/useAddOn';
import { IAddOnType } from '../../models/addon.model';
import { ContentWrapper } from '../Layout/components/ContentWrapper';
import { SubNavbar } from '../Layout/components/SubNavbar';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  amount: Yup.number()
    .required('Amount is required')
    .positive('Must be a positive value'),
  addOnType: Yup.string().required('Add-on type is required'),
  addOnKey: Yup.string().required('Add-on key is required'),
  summary: Yup.string()
    .required('Add-on summary is required')
    .min(10, 'Mininum 10 charecters required'),
});

export const CreateAddOn = () => {
  const { types } = useAddOns();
  const {
    register,
    // control,
    // handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: any) => {};
  return (
    <Stack direction={'column'} spacing={1} sx={{ height: '100%' }}>
      <SubNavbar title={'Create Add-on'} />
      <ContentWrapper>
        {/* <Stack spacing={2}></Stack> */}
        <Box component={'form'} onSubmit={onSubmit}>
          <Box sx={{ p: '20px' }}>
            <Grid container>
              <Grid item container spacing={2} xs={12} sm={8}>
                <Grid item xs={12}>
                  <TextField
                    label="Add-on Title"
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...register(`title`)}
                    error={errors?.title ? true : false}
                    helperText={
                      errors?.title ? (errors?.title?.message as string) : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Add-on Amount"
                    variant="outlined"
                    size="small"
                    fullWidth
                    {...register(`amount`)}
                    error={errors?.amount ? true : false}
                    helperText={
                      errors?.amount ? (errors?.amount?.message as string) : ''
                    }
                  />
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Add-on Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Add-on Type"
                        size="small"
                        defaultValue={''}
                        {...register(`addOnType`)}
                        error={errors?.addOnType ? true : false}
                      >
                        {types.map((type: IAddOnType) => (
                          <MenuItem value={type.value}>{type.title}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      label="Set user limit"
                      size="small"
                      fullWidth
                      variant="outlined"
                      {...register(`addOnKey`)}
                      error={errors?.addOnKey ? true : false}
                      helperText={
                        errors?.addOnKey
                          ? (errors?.addOnKey?.message as string)
                          : ''
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    id="outlined"
                    label="Mobile Number"
                    size="small"
                    fullWidth
                    variant="outlined"
                    {...register(`summary`)}
                    error={errors?.summary ? true : false}
                    helperText={
                      errors?.summary
                        ? (errors?.summary?.message as string)
                        : ''
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button>Save Now</Button>
          </Box>
        </Box>
      </ContentWrapper>
    </Stack>
  );
};
