import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Radio,
  TextField,
  Box,
  Grid,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
  Stack,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import { usePaymentModes } from '../../hooks/usePaymentModes';
import { BankDetailsforAdmin } from '../../models/BankAcc.models';
import { useBankAccounts } from '../../hooks/useBankAccounts';
import CommentsPopUp from '../sub-components/popup/CommentsPopup';

interface PopUpProps {
  onCloseModal: () => void; // Specify the type for onCloseModal
  tableRowData: BankDetailsforAdmin | null;
  FetchData: () => void;
}
interface PaymentMode {
  _id: string;
  key: string;
  value: string;
}

const PopUp: React.FC<PopUpProps> = ({
  onCloseModal,
  tableRowData,
  FetchData,
}) => {
  const { getAllPaymentmodes } = usePaymentModes();
  const { updateBankDetail } = useBankAccounts();
  const [paymentMode, setpaymentMode] = useState<PaymentMode[]>([]);

  const fetchMyBankDetails = async () => {
    try {
      const response = await getAllPaymentmodes();

      setpaymentMode(response.modes);
    } catch (error) {
      console.error('Error fetching Bank details:', error);
    }
  };

  useEffect(() => {
    // if (paymentMode.length === 0) {
    fetchMyBankDetails();
    // }
    // eslint-disable-next-line
  }, []);

  const [comments, setComments] = useState('');

  // const [isSubmitButtonEnabled, setSubmitButtonEnabled] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [parentData, setParentData] = useState<BankDetailsforAdmin>({
    _id: '',
    community: {
      logo: '',
      name: '',
      status: '',
      type: '',
      _id: '',
    },
    fullName: '',
    phoneNumber: '',
    bankName: '',
    branchName: '',
    accountNumber: '',
    ifsc: '',
    status: '',
    label: '',
    percentageChares: 0,
    fixedChares: 0,
    deductionAccount: '',
    comments: [],
    paymentMode: [],
    // comments: [],
  });
  useEffect(() => {
    if (tableRowData) {
      setParentData((prevData) => ({
        ...prevData,
        ...tableRowData,
      }));
    } else {
      // If tableRowData is not available, set parentData to default state
      setParentData({
        _id: '',
        community: {
          logo: '',
          name: '',
          status: '',
          type: '',
          _id: '',
        },
        fullName: '',
        phoneNumber: '',
        bankName: '',
        branchName: '',
        accountNumber: '',
        ifsc: '',
        status: '',
        label: '',
        percentageChares: 0,
        fixedChares: 0,
        deductionAccount: '',
        comments: [],
        paymentMode: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [selectedStatus, setSelectedStatus] = useState(
    (tableRowData?.status ?? '').charAt(0).toUpperCase() +
      (tableRowData?.status ?? '').slice(1).toLowerCase()
  );

  const [selectedRadioValue, setSelectedRadioValue] = useState(
    tableRowData?.fixedChares ? 'a' : 'b'
  );

  const [fixedCharge, setFixedCharge] = useState(tableRowData?.fixedChares);
  const [payOutPercentage, setpayOutPercentage] = useState(
    tableRowData?.percentageChares
  );

  useEffect(() => {
    setSelectedRadioValue(tableRowData?.fixedChares ? 'a' : 'b');
    setFixedCharge(tableRowData?.fixedChares);
    setpayOutPercentage(tableRowData?.percentageChares);
  }, [tableRowData]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedRadioValue(value);

    // Reset the value of the inactive text field to an empty string
    if (value === 'a') {
      setpayOutPercentage(0);
    } else if (value === 'b') {
      setFixedCharge(0);
    }
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { id, value } = event.target;
    if (id === 'a') {
      setFixedCharge(Number(value));
    } else if (id === 'b') {
      setpayOutPercentage(Number(value));
    }
  };

  const statusColor =
    selectedStatus === 'Active'
      ? 'green'
      : selectedStatus === 'Created'
        ? '#FF4F00'
        : selectedStatus === 'Pending'
          ? '#DAA44D'
          : selectedStatus === 'Validating'
            ? '#50A1CA'
            : selectedStatus === 'Reject'
              ? '#DA0242'
              : 'grey';

  const handleButtonClick = (buttonType: any) => {
    onCloseModal();
  };

  // Handler for comments change
  const handleCommentsChange = (value: string) => {
    setComments(value);
  };

  const columnHeaderStyle: React.CSSProperties = {
    fontSize: '11px',
    fontWeight: '600',
    paddingBottom: '5px',
  };

  const columnValueStyle: React.CSSProperties = {
    paddingBottom: '5px',
    fontSize: '11px',
    fontWeight: '400',
  };

  const [selectedPaymentModes, setSelectedPaymentModes] = React.useState<
    string[]
  >(tableRowData?.paymentMode || []);

  const isDisabled =
    !selectedStatus ||
    !selectedPaymentModes ||
    !comments ||
    (fixedCharge &&
      fixedCharge > 0 &&
      payOutPercentage &&
      payOutPercentage > 0);

  const handleSubmit = async () => {
    // Create a JSON object with the form values
    const updatedData = {
      fullName: parentData.fullName,
      branchName: parentData.branchName,
      accountNumber: parentData.accountNumber,
      ifsc: parentData.ifsc,
      phoneNumber: parentData.phoneNumber,
      bankName: parentData.bankName,
      status: selectedStatus.toUpperCase(),
      label: parentData.label,
      percentageChares: payOutPercentage,
      paymentMode: selectedPaymentModes,
      fixedChares: fixedCharge,
      message: comments,
    };

    try {
      setIsLoading(true);
      await updateBankDetail(parentData?._id, updatedData);
      FetchData();
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      onCloseModal();
      FetchData();
      setIsLoading(false);
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item sx={{ paddingBottom: 0 }}>
        <Accordion
          sx={{
            variant: 'plain',
            width: '100%',
            border: '1px solid #ddd',
            pb: 0,
          }}
          elevation={0}
        >
          <AccordionSummary
            sx={{
              maxHeight: '40px',
              '&.Mui-expanded': {
                // Note the dot before Mui-expanded
                minHeight: 0,
                pb: 0,
              },
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="payout-details-content"
            id="payout-details-header"
          >
            <Typography
              variant="subtitle1"
              sx={{ fontSize: '13px', fontFamily: 'Roboto', fontWeight: '500' }}
            >
              Bank Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              pt: 0,
              maxHeight: '250px', // Set your desired max width here
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '4px', // Set the width of the scrollbar
                height: '4px', // Set the height of the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888', // Set the color of the scrollbar thumb
                borderRadius: '4px', // Set the border radius of the scrollbar thumb
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1', // Set the background color of the scrollbar track
                borderRadius: '4px', // Set the border radius of the scrollbar track
              },
            }}
          >
            <Grid container>
              <Grid item xs={6} sm={6}>
                <Typography style={columnHeaderStyle}>
                  Community Name
                </Typography>
                <Typography style={columnHeaderStyle}>Admin Name</Typography>
                <Typography style={columnHeaderStyle}>Mobile Number</Typography>
                <Typography style={columnHeaderStyle}>Bank Name</Typography>
                <Typography style={columnHeaderStyle}>
                  Account Number
                </Typography>
                <Typography style={columnHeaderStyle}>IFSC</Typography>
                <Typography style={columnHeaderStyle}>Label:</Typography>
                <Typography style={columnHeaderStyle}>
                  Payout Percentage:
                </Typography>
                <Typography style={columnHeaderStyle}>Account:</Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography style={columnValueStyle}>
                  : {tableRowData?.community.name}
                </Typography>
                <Typography style={columnValueStyle}>
                  : {tableRowData?.fullName}
                </Typography>
                <Typography style={columnValueStyle}>
                  : {tableRowData?.phoneNumber}
                </Typography>
                <Typography style={columnValueStyle}>
                  : {tableRowData?.bankName} {tableRowData?.branchName}
                </Typography>

                <Typography style={columnValueStyle}>
                  : {tableRowData?.accountNumber}
                </Typography>
                <Typography style={columnValueStyle}>
                  : {tableRowData?.ifsc}
                </Typography>
                <Typography style={columnValueStyle}>
                  : {tableRowData?.label}
                </Typography>
                <Typography style={columnValueStyle}>
                  {tableRowData?.percentageChares
                    ? ` ${':'}${tableRowData?.percentageChares}`
                    : `${': '} ${'NA'}`}
                </Typography>
                <Typography style={columnValueStyle}>
                  {tableRowData?.deductionAccount
                    ? ` ${':'}${tableRowData?.deductionAccount}`
                    : `${': '} ${'NA'}`}
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item>
        <Accordion
          sx={{ variant: 'plain', width: '100%', border: '1px solid #ddd' }}
          elevation={0}
        >
          <AccordionSummary
            sx={{ minHeight: 'auto', maxHeight: 'auto' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="payout-details-content"
            id="payout-details-header"
          >
            <Typography
              variant="subtitle1"
              sx={{ fontSize: '13px', fontFamily: 'Roboto', fontWeight: '500' }}
            >
              Comments
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              pt: 0,
              maxHeight: '250px', // Set your desired max width here
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                width: '4px', // Set the width of the scrollbar
                height: '4px', // Set the height of the scrollbar
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888', // Set the color of the scrollbar thumb
                borderRadius: '4px', // Set the border radius of the scrollbar thumb
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f1f1f1', // Set the background color of the scrollbar track
                borderRadius: '4px', // Set the border radius of the scrollbar track
              },
            }}
          >
            <CommentsPopUp data={tableRowData?.comments} />
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item>
        <Accordion
          sx={{ variant: 'plain', width: '100%', border: '1px solid #ddd' }}
          defaultExpanded
          elevation={0}
        >
          <AccordionSummary
            sx={{ minHeight: '40px', maxHeight: '40px' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="payout-details-content"
            id="payout-details-header"
          >
            <Typography
              variant="subtitle1"
              sx={{ fontSize: '13px', fontFamily: 'Roboto', fontWeight: '500' }}
            >
              Payout Details
            </Typography>
          </AccordionSummary>

          <Box sx={{ borderRadius: '10px' }}>
            <AccordionDetails sx={{ px: 1 }}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item>
                  <Box>
                    <Radio
                      checked={selectedRadioValue === 'a'}
                      onChange={handleRadioChange}
                      value="a"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                    />
                    <label style={{ fontSize: '13px', fontWeight: '500' }}>
                      Flat(₹):
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6} xl={6}>
                  <Box ml={2} mr={2}>
                    <TextField
                      disabled={selectedRadioValue !== 'a'}
                      id="a"
                      // label="Text Field A"
                      variant="outlined"
                      value={fixedCharge}
                      onChange={handleTextFieldChange}
                      fullWidth
                      size="small"
                      InputProps={{
                        inputProps: {
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        },
                        style: {
                          // height: "4.5vh",
                        },
                      }}
                      sx={{
                        flex: 1,
                        p: 0,
                        '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                          {
                            padding: '5px',
                            fontSize: '13px',
                            // textAlign: "right",
                          },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item xs={6} md={6} xl={6}>
                  <Box
                    display="flex"
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    <Radio
                      checked={selectedRadioValue === 'b'}
                      onChange={handleRadioChange}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'B' }}
                    />
                    <Typography
                      sx={{
                        fontSize: { xs: '11px', md: '13px' },
                        fontWeight: '450',
                      }}
                    >
                      Percentage(%):
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6} xl={6}>
                  <Box ml={2} mr={2}>
                    <TextField
                      disabled={selectedRadioValue !== 'b'}
                      id="b"
                      fullWidth
                      // label="Text Field B"
                      type="number"
                      variant="outlined"
                      value={payOutPercentage}
                      onChange={handleTextFieldChange}
                      size="small"
                      InputLabelProps={{
                        style: {
                          padding: '0px',
                          fontSize: '10px',
                        },
                      }}
                      InputProps={{
                        // inputProps: { min: 0, max: 100 },
                        style: {
                          // height: "4.5vh",
                          borderColor: '#696969',
                        },
                      }}
                      sx={{
                        flex: 1,
                        p: 0,
                        '& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                          {
                            padding: '5px',
                            fontSize: '13px',
                            // textAlign: "right",
                          },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      pl: 2,
                    }}
                  >
                    Status:
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} xl={6}>
                  <Box ml={2} mr={2} mb={1}>
                    {/* Add some margin around the text field */}

                    <Select
                      displayEmpty
                      fullWidth
                      inputProps={{ 'aria-label': 'Without label' }}
                      value={selectedStatus}
                      onChange={(event) =>
                        setSelectedStatus(event.target.value)
                      }
                      sx={{
                        fontSize: '13px',
                        width: '100%',
                        '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
                          {
                            padding: '5px',
                            textAlign: 'center',
                          },
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: statusColor,
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: statusColor,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: statusColor,
                        },
                        backgroundColor:
                          selectedStatus === 'Active'
                            ? '#E8F5E9' // Very light green
                            : selectedStatus === 'Created'
                              ? '#FFF3E0' // Very light orange
                              : selectedStatus === 'Pending'
                                ? '#FFF9C4' // Very light yellow
                                : selectedStatus === 'Validating'
                                  ? '#E1F5FE' // Very light blue
                                  : selectedStatus === 'Reject'
                                    ? '#FFEBEE' // Very light red
                                    : '#FFFFFF', // Light grey (fallback color)
                      }}
                    >
                      {['Active', 'Validating', 'Reject'].map((item) => (
                        <MenuItem
                          key={item}
                          value={item}
                          sx={{
                            fontSize: '13px',
                            color:
                              item === 'Active'
                                ? 'green'
                                : item === 'Created'
                                  ? '#FF4F00'
                                  : item === 'Pending'
                                    ? '#DAA44D'
                                    : item === 'Validating'
                                      ? '#50A1CA'
                                      : item === 'Reject'
                                        ? '#DA0242'
                                        : 'grey',
                          }}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: '13px',
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      pl: 2,
                    }}
                  >
                    Payment Mode:
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} xl={6}>
                  <Box ml={2} mr={2}>
                    <Stack spacing={1}>
                      <Select
                        id="selectedPaymentModes"
                        multiple
                        value={selectedPaymentModes}
                        onChange={(event) =>
                          setSelectedPaymentModes(
                            event.target.value as string[]
                          )
                        }
                        displayEmpty
                        renderValue={(selected) => (
                          <div
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {selected.join(', ')}
                          </div>
                        )}
                        input={
                          <OutlinedInput
                            placeholder="Choose Payment Modes"
                            notched
                            sx={{ width: '100%' }}
                          />
                        }
                        sx={{
                          fontSize: '13px',
                          width: '100%',
                          '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
                            {
                              padding: '5px',
                              textAlign: 'center',
                            },
                        }}
                      >
                        {paymentMode?.map((mode) => (
                          <MenuItem
                            key={mode.key}
                            value={mode.key}
                            sx={{ fontSize: '13px' }}
                          >
                            <Checkbox
                              checked={selectedPaymentModes.includes(mode.key)}
                            />
                            {mode.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>

              {/* <Grid container direction={"row"} >
                <Grid item xl={4} md={6} xs={6}>
                  <Box>

                  <Radio
                    checked={selectedRadioValue === "a"}
                    onChange={handleRadioChange}
                    value="a"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "A" }}
                  />
                  <label style={{ fontSize: "13px", fontWeight: "500" }}>
                    Flat(₹):
                  </label>
                  </Box>

                  <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                    <Radio
                      checked={selectedRadioValue === "b"}
                      onChange={handleRadioChange}
                      value="b"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "B" }}
                    />
                    <Typography sx={{ fontSize:{xs:"11px",md:"13px"}, fontWeight: "500" }}>
                      Percentage(%):
                    </Typography>
                  </Box>
                  <Box>

                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      pl: 2,
                    }}
                  >
                    Status:
                  </Typography>
                  </Box>
                    <Box>

                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontFamily: "Inter",
                      fontWeight: "500",
                      pl: 2,
                    }}
                  >
                    Payment Mode:
                  </Typography>
                    </Box>
                </Grid>
                <Grid item xs={0} xl={0} md={0}></Grid>
                <Grid item xl={4} md={6} xs={6} sx={{mt:1}}>
                  <Stack spacing={1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >

                      <TextField
                        disabled={selectedRadioValue !== "a"}
                        id="a"
                        // label="Text Field A"
                        variant="outlined"
                        value={fixedCharge}
                        onChange={handleTextFieldChange}
                        size="small"
                        InputProps={{
                          inputProps: {
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          },
                          style: {
                            // height: "4.5vh",
                          },
                        }}
                        sx={{
                          flex: 1,
                          p: 0,
                          "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                            {
                              padding: "5px",
                              fontSize: "13px",
                              // textAlign: "right",
                            },
                        }}
                      />
                    </Box>

                    <TextField
                      disabled={selectedRadioValue !== "b"}
                      id="b"
                      // label="Text Field B"
                      type="number"
                      variant="outlined"
                      value={payOutPercentage}
                      onChange={handleTextFieldChange}
                      size="small"
                      // InputLabelProps={{
                      //   style: {
                      //     padding: "0px",
                      //     fontSize: "10px",
                      //   },
                      // }}
                      InputProps={{
                        inputProps: { min: 0, max: 100 },
                        style: {
                          // height: "4.5vh",
                          borderColor: "#696969",
                        },
                      }}
                      sx={{
                        flex: 1,
                        p: 0,
                        fontSize: "10px",
                        "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "5px",
                            fontSize: "13px",
                            textAlign: "start",
                          },
                      }}
                    />
                    <FormControl
                      id="selectedStatus"
                      sx={{
                        "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "5px",
                            textAlign: "center",
                            color: statusColor,
                          },
                        "& .outlinedInput": {
                          backgroundColor: statusColor, // Default background color is white
                        },
                      }}
                    >
                      <Select
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        value={selectedStatus}
                        onChange={(event) =>
                          setSelectedStatus(event.target.value)
                        }
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: statusColor,
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: statusColor,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: statusColor,
                          },
                          backgroundColor:
                            selectedStatus === "Active"
                              ? "#E8F5E9" // Very light green
                              : selectedStatus === "Created"
                              ? "#FFF3E0" // Very light orange
                              : selectedStatus === "Pending"
                              ? "#FFF9C4" // Very light yellow
                              : selectedStatus === "Validating"
                              ? "#E1F5FE" // Very light blue
                              : selectedStatus === "Reject"
                              ? "#FFEBEE" // Very light red
                              : "#FFFFFF", // Light grey (fallback color)
                        }}
                      >
                        {["Active", "Validating", "Reject"].map((item) => (
                          <MenuItem
                            key={item}
                            value={item}
                            sx={{
                              fontSize: "13px",
                              color:
                                item === "Active"
                                  ? "green"
                                  : item === "Created"
                                  ? "#FF4F00"
                                  : item === "Pending"
                                  ? "#DAA44D"
                                  : item === "Validating"
                                  ? "#50A1CA"
                                  : item === "Reject"
                                  ? "#DA0242"
                                  : "grey",
                            }}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Select
                      id="selectedPaymentModes"
                      multiple
                      value={selectedPaymentModes}
                      onChange={(event) =>
                        setSelectedPaymentModes(event.target.value as string[])
                      }
                      displayEmpty
                      renderValue={(selected) => (
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {selected.join(", ")}
                        </div>
                      )}
                      input={
                        <OutlinedInput
                          placeholder="Choose Payment Modes"
                          notched
                        />
                      }
                      sx={{
                        fontSize: "13px",
                        width: "100%",
                        "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "5px",
                            textAlign: "center",
                          },
                      }}
                    >
                      {paymentMode.map((mode) => (
                        <MenuItem key={mode.key} value={mode.key}>
                          <Checkbox
                            checked={selectedPaymentModes.includes(mode.key)}
                          />
                          {mode.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>
                </Grid>
                <Grid item xl={4} xs={0}></Grid>
              </Grid> */}

              <TextField
                id="comments"
                multiline
                rows={2}
                fullWidth
                variant="outlined"
                label="Enter comments"
                onChange={(event) => handleCommentsChange(event.target.value)}
                sx={{ marginTop: '5px' }}
              />
            </AccordionDetails>
          </Box>
        </Accordion>

        <Grid
          container
          direction="row"
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'rightf',
            justifyContent: 'right',
            marginTop: '16px',
          }}
        >
          <Grid item>
            <Button
              variant="contained"
              sx={{
                px: 2,
                textTransform: 'capitalize',
                borderRadius: '4px',
                background:
                  'linear-gradient(to right bottom, #3C5AA0, #50A1CA)',
                '&:hover': {
                  backgroundColor: '#50A1CA',
                  cursor: 'pointer',
                  border: 'none',
                },
              }}
              onClick={handleSubmit}
              disabled={Boolean(isDisabled)}
            >
              {isLoading ? (
                <CircularProgress size={24} sx={{ color: 'white' }} />
              ) : (
                'Approve'
              )}
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              sx={{
                px: 2,
                textTransform: 'capitalize',
                borderRadius: '4px',
                background:
                  'linear-gradient(to right bottom, #3C5AA0, #50A1CA)',
                '&:hover': {
                  backgroundColor: '#50A1CA',
                  cursor: 'pointer',
                  border: 'none',
                },
              }}
              onClick={handleButtonClick}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PopUp;
