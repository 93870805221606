import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { phone } from '../../configurations/regExp';
import { IEditUser } from '../../models/user.model';
import { Box } from '@mui/system';
import { Grid } from '@mui/material';

interface IEditUserProps {
  data: IEditUser;
  onAction: (a: any, d?: any) => void;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  emailId: Yup.string().required('Email is required').email('Email is invalid'),
  phoneNumber: Yup.string()
    .matches(phone, 'Invalid Mobile number')
    .required('Mobile number required'),
});

export const EditUser = ({ data, onAction }: IEditUserProps) => {
  const {
    register,

    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      firstName: data.firstName ?? '',
      lastName: data.lastName ?? '',
      emailId: data.emailId ?? '',
      phoneNumber: data.phoneNumber ?? '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const handleCancel = () => {
    onAction('cancel');
  };
  const handleSumbit = () => {
    trigger();
    if (isValid) {
      //const values: IEditUser = { id: data.id, ...getValues() };
      //editUsers(values)
      console.log('editUsers');
    }
  };
  return (
    <>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <Box sx={{ p: '20px' }}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                id="outlined-basic"
                label="First name"
                variant="outlined"
                size="small"
                fullWidth
                {...register(`firstName`)}
                error={errors?.firstName ? true : false}
                helperText={
                  errors?.firstName
                    ? (errors?.firstName?.message as string)
                    : ''
                }
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id="outlined-basic"
                label="Last name"
                variant="outlined"
                size="small"
                fullWidth
                {...register(`lastName`)}
                error={errors?.lastName ? true : false}
                helperText={
                  errors?.lastName ? (errors?.lastName?.message as string) : ''
                }
              />
            </Grid>

            <Grid item sm={6}>
              <TextField
                required
                id="outlined-basic"
                label="Email"
                size="small"
                fullWidth
                variant="outlined"
                {...register(`emailId`)}
                error={errors?.emailId ? true : false}
                helperText={
                  errors?.emailId ? (errors?.emailId?.message as string) : ''
                }
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                required
                id="outlined"
                label="Mobile Number"
                size="small"
                fullWidth
                variant="outlined"
                {...register(`phoneNumber`)}
                error={errors?.phoneNumber ? true : false}
                helperText={
                  errors?.phoneNumber
                    ? (errors?.phoneNumber?.message as string)
                    : ''
                }
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="warning" variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!isValid} onClick={handleSumbit}>
          Update
        </Button>
      </DialogActions>
    </>
  );
};
