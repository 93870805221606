import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommunity } from '../../models/communities.modal';

interface SelectedProgress {
  progressValue: number;
  selectedCommunityId: string | null;
}

interface ICommunityState {
  logo: any;
  selectedCommunity: ICommunity | null;
  selectedCommunityProgress: SelectedProgress;
}

const initialState: ICommunityState = {
  selectedCommunity: null,
  logo: undefined,
  selectedCommunityProgress: {
    progressValue: 0,
    selectedCommunityId: null,
  },
};

const selectedCommunity = createSlice({
  name: 'selectedCommunity',
  initialState,
  reducers: {
    loadSelectedCommunity(state, action: PayloadAction<ICommunity>) {
      state.selectedCommunity = action.payload;
      // console.log('selected Communities Payload');
      // console.log(action.payload);
    },
    unloadSelectedCommunity(state) {
      state.selectedCommunity = null;
    },
    setSelectedCommunityProgress(
      state,
      action: PayloadAction<SelectedProgress>
    ) {
      state.selectedCommunityProgress = action.payload;
    },
  },
});

interface ICommunitiesState {
  communities: ICommunity[] | [];
  adminCommunities: ICommunity[] | [];
  joinedCommunities: ICommunity[] | [];
}

const communitiesState: ICommunitiesState = {
  communities: [],
  adminCommunities: [],
  joinedCommunities: [],
};

const communities = createSlice({
  name: 'communities',
  initialState: communitiesState,
  reducers: {
    loadCommunities(state, action: PayloadAction<ICommunity[]>) {
      state.communities = action.payload;
    },
    setAdminCommunities(state, action: PayloadAction<ICommunity[]>) {
      state.adminCommunities = action.payload;
    },
    setJoinedCommunities(state, action: PayloadAction<ICommunity[]>) {
      state.joinedCommunities = action.payload;
    },
    unloadCommunities(state) {
      state.communities = [];
      state.adminCommunities = [];
      state.joinedCommunities = [];
    },
  },
});

export { selectedCommunity, communities };
export const {
  loadSelectedCommunity,
  unloadSelectedCommunity,
  setSelectedCommunityProgress,
} = selectedCommunity.actions;
export const {
  loadCommunities,
  setAdminCommunities,
  setJoinedCommunities,
  unloadCommunities,
} = communities.actions;
