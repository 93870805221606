import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useWebBuilders } from '../../hooks/useWebBuilder';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IWebBanners } from '../../models/builder.model';

export interface AddBannersProps {
  banners?: IWebBanners[];
  refreshData: () => void;
}

const AddBanners: React.FC<AddBannersProps> = ({ banners, refreshData }) => {
  const selectedCommunity = useSelector(
    (state: any) => state?.selectedCommunity.selectedCommunity
  );

  const { updateBanners, uploadImages } = useWebBuilders();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setLoading] = useState(false);
  const [loaderImg, setLoadingImg] = useState(false);

  const [newBanner, setNewBanner] = useState<IWebBanners>({
    heading: '',
    subheading: '',
    image: '',
  });

  const [bannerData, setBannerData] = useState<IWebBanners[]>([]);


  console.log(bannerData, " bannerData")

  useEffect(() => {
    if (banners) {
      setBannerData(banners);
    }
  }, [banners]);

  const handleInputChange = (name: string, value: string) => {
    setNewBanner((prevBanner) => ({
      ...prevBanner,
      [name]: value,
    }));
  };

  const handleImageChange = async (files: FileList | null, index?: number): Promise<void> => {
    if (!files) return;
    const selectedFiles = Array.from(files);
    setLoadingImg(true);
    try {
      const responses = await uploadImages(selectedFiles);
      const uploadedImageUrl = responses[0].value;
      if (index !== undefined) {
        setBannerData((prevData) => {
          const updatedBannerData = [...prevData];
          updatedBannerData[index].image = uploadedImageUrl;
          return updatedBannerData;
        });
      } else {
        setNewBanner((prevBanner) => ({
          ...prevBanner,
          images: uploadedImageUrl,
        }));
      }
      enqueueSnackbar("Banner image updated successfully", { variant: "success" });
    } catch (error) {
      console.error('Error during upload:', error);
      enqueueSnackbar('Failed to upload image. Please try again.', { variant: 'error' });
    } finally {
      setLoadingImg(false);
    }
  };

  const handleRemoveImage = (index?: number) => {
    if (index !== undefined) {
      setBannerData((prevData) => {
        const updatedBannerData = [...prevData];
        updatedBannerData[index].image = '';
        return updatedBannerData;
      });
    } else {
      setNewBanner((prevBanner) => ({
        ...prevBanner,
        images: '',
      }));
    }
  };

  const handleBannerChange = (
    index: number,
    field: keyof IWebBanners,
    value: string
  ) => {
    setBannerData((prevBanners) =>
      prevBanners.map((banner, i) =>
        i === index ? { ...banner, [field]: value } : banner
      )
    );
  };

  const addNewBanner = () => {
    if (newBanner.heading && newBanner.subheading) {
      setBannerData((prevData) => [...prevData, newBanner]);
      setNewBanner({ heading: '', subheading: '', image: '', });
    } else {
      enqueueSnackbar('Please fill in both title and description fields.', { variant: 'warning' });
    }
  };

  const handleSubmit = async () => {
    const bannersToSubmit = bannerData.filter(banner => banner.heading !== '' || banner.subheading !== '');
    console.log("Submitting Banners:", bannersToSubmit);
    setLoading(true);
    try {
      const communityId = selectedCommunity?._id;
      const response = await updateBanners(bannersToSubmit, communityId);
      console.log(response, "response")
      if (response?.status === 200) {
        enqueueSnackbar("Banners updated successfully", { variant: "success" });
        refreshData();
      }
    } catch (error) {
      console.error('Error updating Banners:', error);
      enqueueSnackbar('Failed to update banners. Please try again.', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const deleteSingleBannerForm = (index: number) => {
    setBannerData((prevData) => prevData.filter((_, i) => i !== index));
  };

  return (
    <>
      <Stack spacing={2}>
        {bannerData?.map((banner, index) => (
          <Stack
            key={index}
            sx={{
              border: '1px solid #E0E0E0',
              padding: '30px',
              margin: '15px 0px',
              position: 'relative',
            }}
          >
            <IconButton
              style={{
                position: 'absolute',
                top: '1px',
                right: '1px',
                backgroundColor: 'white',
              }}
              onClick={() => deleteSingleBannerForm(index)}
            >
              <DeleteIcon />
            </IconButton>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Stack
                  display={'flex'}
                  flexDirection={'row'}
                  gap={5}
                  alignItems={'center'}
                >
                  <Stack sx={{ width: '75%' }}>
                    <TextField
                      label="Heading"
                      variant="outlined"
                      fullWidth
                      value={banner?.heading}
                      onChange={(e) =>
                        handleBannerChange(index, 'heading', e.target.value)
                      }
                      sx={{ marginBottom: '15px' }}
                    />
                    <TextField
                      label="Sub Heading"
                      variant="outlined"
                      fullWidth
                      value={banner?.subheading}
                      onChange={(e) =>
                        handleBannerChange(index, 'subheading', e.target.value)
                      }
                    />
                  </Stack>
                  <Stack
                    sx={{
                      backgroundColor: banner.image ? '#ffffff' : '#F5F5F5',
                      p: 1.5,
                      m: 1,
                      borderRadius: '5px',
                      border: banner.image ? 'none' : '1px dashed #000000',
                      height: { xs: '20vh', md: '15vh' },
                      width: '25%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: banner.image ? `url(${banner.image})` : 'none',
                      position: 'relative',
                    }}
                  >
                    {banner.image ? (
                      <>
                        <IconButton
                          onClick={() => handleRemoveImage(index)}
                          sx={{
                            position: 'absolute',
                            top: 5,
                            right: 5,
                            backgroundColor: 'white',
                            '&:hover': { backgroundColor: '#f2f2f2' },
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Typography sx={{ fontSize: '13px', color: '#5E6C84', pb: 1 }}>
                          Upload Banner Image
                        </Typography>
                        {loaderImg ? <CircularProgress size={24} sx={{ color: '#3C5AA0' }} /> :
                          <input
                            type="file"
                            name="images"
                            onChange={(e) => handleImageChange(e.target.files, index)}
                            style={{
                              border: '2px solid #5E6C84',
                              borderRadius: '5px',
                              padding: '8px',
                              backgroundColor: '#ffffff',
                              color: '#5E6C84',
                              fontSize: '10px',
                              fontWeight: '500',
                              cursor: 'pointer',
                              width: '70%',
                              marginTop: '10px',
                            }}
                          />
                        }
                      </>
                    )}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        ))}

        <Stack
          sx={{
            border: '1px solid #E0E0E0',
            padding: '24px',
            margin: '15px 0px',
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Stack
                display={'flex'}
                flexDirection={'row'}
                gap={5}
                alignItems={'center'}
              >
                <Stack sx={{ width: '75%' }}>
                  <TextField
                    label="Heading"
                    variant="outlined"
                    fullWidth
                    value={newBanner?.heading}
                    onChange={(e) => handleInputChange('heading', e.target.value)}
                    sx={{ marginBottom: '15px' }}
                  />
                  <TextField
                    label="Sub Heading"
                    variant="outlined"
                    fullWidth
                    value={newBanner?.subheading}
                    onChange={(e) =>
                      handleInputChange('subheading', e.target.value)
                    }
                  />
                </Stack>
                <Stack
                  sx={{
                    backgroundColor: '#F5F5F5',
                    p: 1.5,
                    m: 1,
                    borderRadius: '5px',
                    border: '1px dashed #000000',
                    height: { xs: '20vh', md: '15vh' },
                    width: '25%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <Typography sx={{ fontSize: '13px', color: '#5E6C84', pb: 1 }}>
                    Upload Banner Image
                  </Typography>
                  {loaderImg ? <CircularProgress size={24} sx={{ color: '#3C5AA0' }} /> :
                    <input
                      type="file"
                      name="images"
                      onChange={(e) => handleImageChange(e.target.files)}
                      style={{
                        border: '2px solid #5E6C84',
                        borderRadius: '5px',
                        padding: '8px',
                        backgroundColor: '#ffffff',
                        color: '#5E6C84',
                        fontSize: '10px',
                        fontWeight: '500',
                        cursor: 'pointer',
                        width: '70%',
                        marginTop: '10px',
                      }}
                    />
                  }
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Stack>


        <Button
          variant="outlined"
          sx={{
            textTransform: 'capitalize',
            width: 'fit-content',
            fontSize: '13px',
            color: '#2952A2',
            border: '1px solid #2952A2',
            ':hover': {
              border: '1px solid #2952A2',
              color: '#2952A2',
            },
          }}
          onClick={addNewBanner}
        >
          Add New
        </Button>

        <Button
          variant="contained"
          size="large"
          sx={{
            textTransform: 'capitalize',
            borderRadius: '10px',
            mt: 2,
            mb: 2,
            color: '#ffffff',
            width: '100px',
            boxShadow: 'none',
            background:
              'linear-gradient(140deg, #3C5AA0 13.54%, #467DB4 68.75%, #50A1CA 100%)',
            '&:hover': {
              backgroundColor: '#50A1CA',
              cursor: 'pointer',
            },
          }}
          onClick={handleSubmit}
          disabled={loader}
        >
          {loader ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Update'}
        </Button>
      </Stack>
    </>
  );
};

export default AddBanners;
