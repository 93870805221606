import { Box, Stack, Typography } from '@mui/material';
import Carousel from 'react-multi-carousel';
import './ArrowStyles.css';
import { AdminCommunities } from '../AllCommunities/AdminCommunities';
import Loader from '../../Loader/Loader';
import { ICommunity } from '../../../models/communities.modal';
import { useNavigate } from 'react-router-dom';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

interface ITableProps {
  data: ICommunity[];
}

const AdministeredCommunities = ({ data }: ITableProps) => {
  const navigate = useNavigate();
  const myCommunities = data.map((item) => <AdminCommunities {...item} />);

  // console.log(myCommunities, "myCommunities")

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 6,
      slidesToSlide: 3,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 2.5,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      {myCommunities.length > 0 ? ( // Conditionally render the Stack section
        <Stack>
          <Stack
            direction={'row'}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '18px' },
                fontWeight: 700,
                textDecoration: 'none',
                color: '#000000',
                marginLeft: 2,
              }}
            >
              Administered Communities
            </Typography>

            {myCommunities && myCommunities.length > 5 ? (
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#50A1CA',
                  textAlign: 'right',
                  alignItems: 'end',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => navigate(`/admin-communities`)}
              >
                {myCommunities?.length} Communities
              </Typography>
            ) : null}
          </Stack>
          {false ? (
            <Loader />
          ) : (
            <Carousel showDots={false} arrows={true} responsive={responsive}>
              {myCommunities}
            </Carousel>
          )}
        </Stack>
      ) : (
        <Stack>
          <Stack
            direction={'row'}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography
              sx={{
                fontSize: { xs: '14px', md: '18px' },
                fontWeight: 700,
                textDecoration: 'none',
                color: '#000000',
                // marginLeft: 10,
              }}
            >
              Administered Communities
            </Typography>

            {myCommunities && myCommunities.length > 5 ? (
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 400,
                  color: '#50A1CA',
                  textAlign: 'right',
                  alignItems: 'end',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => navigate(`/joined-communities`)}
              >
                {myCommunities?.length} Communities
              </Typography>
            ) : null}
          </Stack>

          <Stack
            sx={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              border: '1px solid #BFBFBF',
              height: '200px',
              borderRadius: '6px',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/explore-create-community')}
          >
            <Box
              sx={{
                backgroundColor: '#ffffff',
                padding: '20px',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <AddRoundedIcon
                style={{
                  fontSize: '30px',
                  color: '#50A1CA',
                }}
              />
            </Box>

            <Typography
              sx={{
                fontSize: { xs: '14px', md: '18px' },
                fontWeight: 700,
                textDecoration: 'none',
                color: '#838383',
                textAlign: 'center',
              }}
            >
              Create New Community
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '11px', md: '13px' },
                textDecoration: 'none',
                color: '#838383',
                textAlign: 'center',
              }}
            >
              This option allows you to start a new community from scratch. You
              can set up community details, <br></br> invite members, and manage
              content specific to the new community you create.
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default AdministeredCommunities;
