import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';

export const Help = () => {
  return (
    <>
      <Helmet>
        <title>Help - Communn - Build & Manage your community</title>
        <meta
          name="description"
          content="Automate Community Management with AI-Powered
        Subscription effortlessly
        Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta name="format-detection" content="telephone=no" />
        <meta
          name="author"
          content="Automate Community Management with AI-Powered
        Subscription effortlessly
        Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
        <meta
          name="keywords"
          content="Automate Community Management with AI-Powered
        Subscription effortlessly
        Our AI-powered platform enhances community management with tools for user data management, interaction,communication, subscription and payment processing, content management, and advanced analytics"
        />
      </Helmet>
      <Stack>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            color: '#3760A9',
            fontFamily: 'Inter',
          }}
        >
          Help
        </Typography>
      </Stack>
    </>
  );
};
