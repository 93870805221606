import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { CustomActions } from './components/CustomActions';

import { IColumn } from '../../models/table.model';
import { UserInfoCell } from './components/UserInfoCell';

import { TextContentCell } from './components/TextContentCell';
import { StatusCell } from './components/StatusCell';
import { IAddOnsList } from '../../models/addon.model';

interface ITableProps {
  data: IAddOnsList[];
  columns: IColumn[];
  onActionClick: (a: any, e?: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AddOnsTable = ({ columns, data, onActionClick }: ITableProps) => {
  return (
    <>
      <PerfectScrollbar>
        <Box>
          <Table
            sx={{
              '& th': {
                fontSize: '10px',
                fontWeight: 500,
                color: '#565656',
                p: 0,
              },
              '& td': {
                borderColor: '#E0E0E0',
                fontSize: '11px',
                fontWeight: 400,
                color: '#565656',
                p: '10px 0px',
                '& .MuiTypography-root': { fontSize: '11px' },
              },
            }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column: IColumn, i: number) => (
                  <TableCell key={i}>
                    {/* <CustomTableHeader title={column.title} /> */}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item: IAddOnsList, i: number) => (
                <TableRow hover key={i}>
                  <TableCell>
                    <TextContentCell title={item.title} />
                  </TableCell>
                  <TableCell>
                    <TextContentCell title={item.type} />
                  </TableCell>
                  <TableCell>
                    <TextContentCell title={item.keys} />
                  </TableCell>
                  <TableCell>
                    <UserInfoCell
                      name={`${item.createdBy.firstName} ${item.createdBy.lastName}`}
                      avatar={item?.createdBy?.avatar}
                      avatarStyles={{
                        height: '24px',
                        width: '24px',
                        fontSize: '11px',
                      }}
                      nameProps={{
                        sx: {
                          fontWeight: 600,
                          fontSize: '12px !important',
                          color: '#1A1A1A',
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <StatusCell
                      text={item.status}
                      type={item.status === 'active' ? 'success' : 'error'}
                    />
                  </TableCell>

                  <TableCell>
                    <TextContentCell
                      title={item.updated.date}
                      subTitle={item.updated.time}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomActions
                      actions={item.actions}
                      onActionClick={onActionClick}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </>
  );
};
