import {
  Grid,
  Stack,
  Typography,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  styled,
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import { useCommunity } from '../../../hooks/useCommunity';
import { ICommunity } from '../../../models/communities.modal';
import { useEffect, useState } from 'react';
import { JoinedAllCommunities } from '../AllCommunities/JoinedAllCommunities';
import { Helmet } from 'react-helmet';
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { metaConfig } from '../../../utils/constants';

const JoinedCommunitiesGird = () => {
  const { isLoading, memberCommunities } = useCommunity();
  const navigate = useNavigate();
  const [myMemberCommunities, setMyMemberCommunities] = useState<ICommunity[]>(
    []
  );

  useEffect(() => {
    async function fetchData() {
      const responseData = await memberCommunities();
      console.log(responseData);
      setMyMemberCommunities(responseData?.data);
    }
    fetchData();
  }, []);

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.joinedCommunities}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack
        direction={'row'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: -18,
          zIndex: 1,
          borderRadius: '10px',
          ml: 1,
          mr: 1,
          backgroundColor: '#ffffff', // optional: add a background color if needed
          padding: '10px 0', // optional: add padding for better appearance
        }}
      >
        <BootstrapTooltip title="Back" placement="left">
          <KeyboardBackspaceIcon
            sx={{
              color: '#50A1CA',
              cursor: 'pointer',
              ml: 2,
              fontSize: { xs: '20px', md: '40px' },
            }}
            onClick={() => navigate(`/explore-communities`)}
          />
        </BootstrapTooltip>
        <Typography
          sx={{
            fontSize: { xs: '14px', md: '18px' },
            fontWeight: 700,
            color: '#000000',
          }}
        >
          Joined Communities
        </Typography>

        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            color: '#B3B3B3',
            mr: 2,
          }}
        >
          {myMemberCommunities?.length} communities
        </Typography>
      </Stack>

      {isLoading ? (
        <Loader />
      ) : (
        <Grid container spacing={0}>
          {myMemberCommunities.map((item) => (
            <Grid item key={item._id} xs={6} sm={2} md={2} lg={2} xl={2}>
              <JoinedAllCommunities {...item?.community} />
            </Grid>
          ))}
        </Grid>
      )}
      {!isLoading && myMemberCommunities.length === 0 && (
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              border: '1px solid #BFBFBF',
              height: '200px',
              borderRadius: '6px',
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: '#838383', textAlign: 'center' }}
            >
              No Communities
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default JoinedCommunitiesGird;
