import { LinearProgress, linearProgressClasses, styled } from '@mui/material';
import { GetColor } from '../../tables/GetColor';

const colorCodes: any = {
  'success.light': '#7FC41B',
  'warning.light': '#ff9800',
  'primary.light': '#FE7F06',
  other: '#DA0242',
};
export const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'name',
})<{ name: string }>(({ theme, name }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      theme.palette.mode === 'light'
        ? colorCodes[GetColor(name) || 'success:light']
        : '#308fe8',
  },
}));
