// usePaymentModes.ts

import { useContext, useState } from 'react';
import { getAllPaymentModes } from '../services/super.service'; // adjust the path based on your project structure
import { AuthContext, IAuthContext } from '../contexts/Auth.context';
import { useSnackbar } from 'notistack';

export const usePaymentModes = () => {
  const { getAccessToken } = useContext<IAuthContext>(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  const [paymentModes, setPaymentModes] = useState<string[]>([]);

  const getAllPaymentmodes = async () => {
    try {
      setIsLoading(true);
      const response = await getAllPaymentModes(getAccessToken());
      if (response?.status === 200) {
        setPaymentModes(response?.data);
        return response.data;
      } else {
        enqueueSnackbar('Failed to fetch bank accounts', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      return response;
    } catch {
      enqueueSnackbar('Failed to fetch bank accounts', {
        variant: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  return {
    isLoading,
    paymentModes,
    getAllPaymentmodes,
  };
};
