import {
  Avatar,
  Button,
  Divider,
  Grid,
  List,
  Paper,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useRequests } from '../../hooks/useRequests';
import { Invitations } from '../../models/Invitation.model';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { NoRequests } from '../AlternatePages/NoRequests';
// import InvitesList from './CommunityInvitations/InvitesList';
import SimpleBar from 'simplebar-react';
import InvitiesLoader from '../Loader/InvitesLoader';
import { metaConfig } from '../../utils/constants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const CommunityInvitations = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { acceptInvitation, getCommunityRequests, deleteInvitation } =
    useRequests();
  const [requests, setRequests] = useState<Invitations[]>([]);
  const [loading, setLoading] = useState(true);

  const [reload, setReload] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        if (reload) {
          const communityRequestsData = await getCommunityRequests();
          setRequests(communityRequestsData?.data);
        }
        setLoading(false);
        setReload(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }
    fetchData();
    return () => {
      setLoading(false);
      setReload(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const formatDateRelative = (dateString: string): string => {
    const currentDate = new Date();
    const inputDate = new Date(dateString);
    const timeDifference = currentDate.getTime() - inputDate.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    if (daysDifference < 1) {
      if (hoursDifference < 1) {
        if (minutesDifference < 1) {
          return 'Just Now';
        }
        return `${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''
          } ago`;
      } else if (hoursDifference < 24) {
        if (hoursDifference === 1) {
          return '1 hour ago';
        }
        return `${hoursDifference} Hours ago`;
      } else {
        return 'Today';
      }
    } else if (daysDifference === 1) {
      return 'Yesterday';
    } else if (daysDifference === 2) {
      return '2 days ago';
    } else if (daysDifference === 3) {
      return '3 days ago';
    }
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return inputDate.toLocaleDateString(undefined, options);
  };

  const handleDeleteClick = async (id: string) => {
    try {
      const response = await deleteInvitation(id);
      if (response && response.status === 200) {
        setReload(true);
        //  navigate("/invitations");
      } else {
        // console.log("joined community failed");
      }
    } catch (error) { }
  };

  const handleAcceptClick = async (id: string, message: string) => {
    try {
      const formData = new FormData();
      formData.append('request_id', id);
      const response = await acceptInvitation(formData);
      if (response && response.status === 200) {
        setReload(true);
        navigate('/users');
        // console.log("joined successfully");
      } else {
        // console.log("joined community failed");
      }
    } catch (error) { }
  };

  return (
    <>
      <Helmet>
        <title>{metaConfig.title.requestsandInvites}</title>
        <meta name="description" content={metaConfig.description} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="author" content={metaConfig.author} />
        <meta name="keywords" content={metaConfig.keywords} />
      </Helmet>
      <Stack direction={'row'} flexGrow={1} spacing={1}>
        <Box component="form" noValidate autoComplete="off">
          <Typography
            sx={{
              fontSize: { xs: '15px', md: '20px' },
              fontWeight: 500,
              color: '#3760A9',
              fontFamily: 'Inter',
              marginBottom: '10px',
            }}
          >
            Manage Requests
          </Typography>
        </Box>
      </Stack>
      <Grid container>
        <Grid item xs={12} md={12} sm={12}>
          <Paper sx={{ p: 2 }} elevation={0}>
            <Box>
              {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  sx={{

                    "& .MuiTabs-indicator": {
                      backgroundColor: "#3760A9",

                    },
                    "& .MuiTab-root": {
                      textTransform: "capitalize",
                      fontSize: 14,
                      color: "#000000",
                      border: "none",
                      fontWeight: 500,
                      "&.Mui-selected": {
                        // backgroundColor: "#D9D9D9",
                        // borderTopRightRadius: "10px",
                        color: "#3760A9",
                      },
                      "&:not(.Mui-selected)": {
                        // backgroundColor: "white",
                        // fontWeight: 500,
                        // color: "#000000",
                      },
                      "&:.css-1aquho2-MuiTabs-indicator": {
                        backgroundColor: 'red',
                      }
                    },
                  }}
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Community Requests" {...a11yProps(0)} />
                  <Tab label="Invites" {...a11yProps(1)} />
                </Tabs>
              </Box> */}

              {/* <CustomTabPanel value={value} index={0} key={value + 1} >
                {loading ? (
                  
                  <InvitiesLoader />
                ) : (
                  <SimpleBar style={{ maxHeight: "70vh" }}>
                    <List sx={{ width: "100%", bgcolor: "background.paper", height: '70vh' }}>
                      {requests && requests.length > 0 ? (
                        requests.map((item: Invitations, index: number,) => (
                          <>
                            <Grid container direction={"row"} spacing={{ xs: 3, md: 5 }} display={'flex'}>
                              <Grid item xs={3} sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                                sm={1.5} md={1.5}>
                                <Avatar
                                  sx={{ width: 45, height: 45 }}
                                  alt={item?.createdBy?.firstName}
                                  src={item?.createdBy?.avatar}
                                />
                              </Grid>
                              <Grid item xs={5} md={6} sm={5} >
                                <Typography
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: 500,
                                    color: "#000000",
                                    fontFamily: 'Inter'
                                  }}
                                >
                                  {item?.createdBy?.firstName} {item.createdBy?.lastName}{" "}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#565656",
                                    fontSize: "12px",
                                    fontWeight: "500",
                                    fontFamily: 'Inter',
                                  }}
                                >
                                  {item?.createdBy?.emailId}
                                </Typography>
                                <Typography sx={{ color: "black", fontSize: "12px", fontFamily: 'Inter' }}>
                                  {item?.message}
                                </Typography>
                                <Typography
                                  sx={{ color: "#787878", fontSize: "12px", fontFamily: 'Inter' }}
                                >
                                  {formatDateRelative(item?.createdAt)}

                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={3} md={3} sm={2.5}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Button
                                  onClick={() => handleAcceptClick(item?._id, item?.message)}
                                  sx={{
                                    textTransform: "none",
                                    fontSize: "13px",
                                    backgroundColor: "#3C5AA0",
                                    color: "#ffffff",
                                    fontWeight: "500",
                                    ml: { xs: 20, md: 5 },
                                    alignItems: "right",
                                    "&:hover": {
                                      boxShadow: "none",
                                      backgroundColor: "#3C5AA0",
                                      fontFamily: 'Inter'
                                    },
                                  }}
                                >
                                  Accept
                                </Button>
                                <Button
                                  onClick={() => handleDeleteClick(item?._id)}
                                  sx={{
                                    textTransform: "none",
                                    fontSize: "13px",
                                    backgroundColor: "#3C5AA0",
                                    color: "#ffffff",
                                    fontWeight: "500",
                                    ml: 5,
                                    alignItems: "right",
                                    "&:hover": {
                                      boxShadow: "none",
                                      backgroundColor: "#3C5AA0",
                                      fontFamily: 'Inter'
                                    },
                                  }}
                                >
                                  Delete
                                </Button>
                              </Grid>
                            </Grid>
                            {index < requests.length - 1 && ( // Render divider if not the last item
                              <Stack>
                                <Divider />
                              </Stack>
                            )}
                          </>
                        ))
                      ) : (
                        <NoRequests />
                      )}
                    </List>
                  </SimpleBar>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <InvitesList />
              </CustomTabPanel> */}
              {loading ? (
                <InvitiesLoader />
              ) : (
                <SimpleBar style={{ maxHeight: '70vh' }}>
                  <List
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      height: '70vh',
                    }}
                  >
                    {requests && requests.length > 0 ? (
                      requests.map((item: Invitations, index: number) => (
                        <>
                          <Grid
                            container
                            direction={'row'}
                            spacing={{ xs: 3, md: 5 }}
                            display={'flex'}
                          >
                            <Grid
                              item
                              xs={3}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              sm={1.5}
                              md={1.5}
                            >
                              <Avatar
                                sx={{ width: 45, height: 45 }}
                                alt={item?.createdBy?.firstName}
                                src={item?.createdBy?.avatar}
                              />
                            </Grid>
                            <Grid item xs={5} md={6} sm={5}>
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 500,
                                  color: '#000000',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {item?.createdBy?.firstName}{' '}
                                {item.createdBy?.lastName}{' '}
                              </Typography>
                              <Typography
                                sx={{
                                  color: '#565656',
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {item?.createdBy?.emailId}
                              </Typography>
                              <Typography
                                sx={{
                                  color: 'black',
                                  fontSize: '12px',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {item?.message}
                              </Typography>
                              <Typography
                                sx={{
                                  color: '#787878',
                                  fontSize: '12px',
                                  fontFamily: 'Inter',
                                }}
                              >
                                {formatDateRelative(item?.createdAt)}
                              </Typography>
                            </Grid>

                            <Grid
                              item
                              xs={3}
                              md={3}
                              sm={2.5}
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Button
                                onClick={() =>
                                  handleAcceptClick(item?._id, item?.message)
                                }
                                sx={{
                                  textTransform: 'none',
                                  fontSize: '13px',
                                  backgroundColor: '#3C5AA0',
                                  color: '#ffffff',
                                  fontWeight: '500',
                                  ml: { xs: 20, md: 5 },
                                  alignItems: 'right',
                                  '&:hover': {
                                    boxShadow: 'none',
                                    backgroundColor: '#3C5AA0',
                                    fontFamily: 'Inter',
                                  },
                                }}
                              >
                                Accept
                              </Button>
                              <Button
                                onClick={() => handleDeleteClick(item?._id)}
                                sx={{
                                  textTransform: 'none',
                                  fontSize: '13px',
                                  backgroundColor: '#3C5AA0',
                                  color: '#ffffff',
                                  fontWeight: '500',
                                  ml: 5,
                                  alignItems: 'right',
                                  '&:hover': {
                                    boxShadow: 'none',
                                    backgroundColor: '#3C5AA0',
                                    fontFamily: 'Inter',
                                  },
                                }}
                              >
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                          {index < requests.length - 1 && ( // Render divider if not the last item
                            <Stack>
                              <Divider />
                            </Stack>
                          )}
                        </>
                      ))
                    ) : (
                      <NoRequests />
                    )}
                  </List>
                </SimpleBar>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
export default CommunityInvitations;
