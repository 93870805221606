import {
  Avatar,
  Divider,
  ListItem,
  Paper,
  Hidden,
  Stack,
  AvatarGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useSelector } from 'react-redux';
// import store from "../../store";
import { Link } from '@mui/material';
// import { IUserInfo } from "../../models/user.model";
import { CommunityMembers } from '../../models/community-user.model';
import { Key, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCommunity } from '../../hooks/useCommunity';
import { AuthContext } from '../../contexts/Auth.context';
import { ICommunity } from '../../models/communities.modal';
import axios from 'axios';
import { BASE_URL } from '../../configurations/url.config';
const CommunityBrief = () => {
  const selectedCommunity = useSelector(
    (state: any) => state.selectedCommunity.selectedCommunity
  );

  const { getCommunityById } = useCommunity();
  const { isAuthenticated } = useContext(AuthContext);

  const community_id = selectedCommunity?._id;
  const [data, setData] = useState<ICommunity>();

  useEffect(
    () => {
      const fetchData = async () => {
        try {
          let response;
          if (isAuthenticated && community_id) {
            response = await getCommunityById(community_id);
            setData(response?.data);
          } else if (community_id) {
            response = await axios.get(
              `${BASE_URL}/communities/${community_id}/home`
            );
            const { community } = response?.data;
            setData(community);
          }
        } catch (error) {
          console.log('error');
        }
      };

      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, community_id]
  );

  const navigate = useNavigate();
  const handlenavigateUser = (id: string) => {
    navigate(`/posts/profile/${id}`);
  };

  const handleNavigateToMembers = () => {
    navigate('/users');
  };
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '100%',
        borderRadius: '8px',
        background:
          ' -webkit-linear-gradient(180deg, #3C5AA0 0%, #50A1CA 100%);',
        backgroundColor: '#50A1CA',
        pb: 2,
        boxShadow: 'none',
      }}
    >
      <Stack sx={{ p: 2 }}>
        <Typography
          sx={{
            fontSize: '2.2vh',
            fontWeight: '600',
            textAlign: 'center',
            color: 'white',
            fontFamily: 'Inter',
          }}
        >
          {selectedCommunity?.name?.length > 25
            ? selectedCommunity?.name.slice(0, 25) + '...'
            : selectedCommunity?.name}
        </Typography>
        <Divider sx={{ backgroundColor: 'white' }} />
      </Stack>
      <Stack direction="row">
        <Stack sx={{ color: 'white' }}>
          <ListItem sx={{ fontSize: '1.5vh', fontFamily: 'Inter' }}>
            Created On:
          </ListItem>
          {selectedCommunity?.joinedOn && (
            <ListItem sx={{ fontSize: '1.5vh' }}>Updated On:</ListItem>
          )}
          <ListItem sx={{ fontSize: '1.5vh', fontFamily: 'Inter' }}>
            Community Posts:
          </ListItem>
          <ListItem sx={{ fontSize: '1.5vh', fontFamily: 'Inter' }}>
            Total Members:
          </ListItem>
        </Stack>
        <Stack sx={{ color: 'white' }}>
          <ListItem
            sx={{ fontSize: '1.5vh', fontWeight: '500', fontFamily: 'Inter' }}
          >
            {selectedCommunity?.createdAt}
          </ListItem>
          {selectedCommunity?.joinedOn &&
            selectedCommunity?.joinedOn?.length > 0 && (
              <ListItem
                sx={{
                  fontSize: '1.5vh',
                  fontWeight: '500',
                  fontFamily: 'Inter',
                }}
              >
                {selectedCommunity?.updatedAt}
              </ListItem>
            )}
          <ListItem
            sx={{ fontSize: '1.5vh', fontWeight: '500', fontFamily: 'Inter' }}
          >
            {selectedCommunity?.posts || 'No Posts'}
          </ListItem>
          <ListItem
            sx={{ fontSize: '1.5vh', fontWeight: '500', fontFamily: 'Inter' }}
          >
            {selectedCommunity?.members?.length || 'No one has Joined'}
          </ListItem>
        </Stack>
      </Stack>

      <Hidden xsDown>
        <Stack direction="row" spacing={1}>
          <AvatarGroup
            sx={{ pl: '1.5vw' }}
            total={selectedCommunity?.members?.length || 0}
            max={7}
            slotProps={{
              additionalAvatar: {
                onClick: handleNavigateToMembers,
                sx: { cursor: 'pointer' },
              },
            }}
          >
            {selectedCommunity?.members?.map(
              (members: CommunityMembers, index: number) => (
                <Tooltip
                  title={members?.user?.firstName}
                  key={index}
                  placement="top"
                  arrow
                >
                  <Avatar
                    key={index}
                    alt={members?.user?.avatar}
                    src={members?.user?.avatar}
                    sx={{ backgroundColor: '#50A1CA', cursor: 'pointer' }}
                    onClick={() => handlenavigateUser(members.user._id)}
                  >
                    {members?.user?.avatar
                      ? null
                      : members?.user?.firstName?.charAt(0)}
                  </Avatar>
                </Tooltip>
              )
            )}
          </AvatarGroup>
        </Stack>

        <Stack
          spacing={2}
          direction="row"
          sx={{ pl: '1.5vw', pb: '1vh', mt: 1 }}
        >
          {selectedCommunity?.socialLinks?.map(
            (link: any, index: Key | null | undefined) =>
              link.type === 'twitter' ? (
                <Link href={link.value} target="_blank" key={index}>
                  <TwitterIcon sx={{ color: 'white' }} />
                </Link>
              ) : link.type === 'instagram' ? (
                <Link href={link.value} target="_blank" key={index}>
                  <InstagramIcon sx={{ color: 'white' }} />
                </Link>
              ) : link.type === 'facebook' ? (
                <Link href={link.value} target="_blank" key={index}>
                  <FacebookIcon sx={{ color: 'white' }} />
                </Link>
              ) : link.type === 'linkedin' ? (
                <Link href={link.value} target="_blank" key={index}>
                  <LinkedInIcon sx={{ color: 'white' }} />
                </Link>
              ) : (
                ''
              )
          )}
        </Stack>
      </Hidden>
    </Paper>
  );
};
export default CommunityBrief;
