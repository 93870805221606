import { Box, Dialog, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AddLink, Folder } from '../../../assets/icons';
import AddLinkPopUp from './AddLinkPopUp';
import { interfaceCourse } from '../../../models/courses.model';
interface sectionProps {
  data: interfaceCourse[];
  sectionPopUp: boolean;
  section: any;
  refetchCourseData: () => void;
  onFilesSelected: (files: FileList | null) => void;
  inputId: string;
}
const DragAndAddFiles: React.FC<sectionProps> = ({
  data,
  sectionPopUp,
  section,
  refetchCourseData,
  onFilesSelected,
  inputId,
}) => {
  const [openAddLinkDialog, setOpenAddLinkDialog] = useState(false);
  const [linkInputs, setLinkInputs] = useState(['']);

  const handleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = e.target.files; // Convert FileList to File[]
      onFilesSelected(files);
    }
  };

  const handleAddLink = () => {
    setLinkInputs([...linkInputs, '']); // Add a new input to the array
  };

  const handleDeleteLink = (index: any) => {
    const updatedInputs = linkInputs.filter((_, i) => i !== index);
    setLinkInputs(updatedInputs);
  };

  const handleInputChange = (value: any, index: any) => {
    const updatedInputs = [...linkInputs];
    updatedInputs[index] = value;
    setLinkInputs(updatedInputs);
  };

  const handleOpenAddLinkDialog = () => {
    setOpenAddLinkDialog(true);
  };
  const handleCloseAddLinkDialog = () => {
    setOpenAddLinkDialog(false);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };
  const handleDrop = async (event: any) => {
    event.preventDefault();
    // const filesArray: File[] = Array.from(event.dataTransfer.files) as File[];
    onFilesSelected(event.dataTransfer.files);
  };

  return (
    <Stack>
      <Grid container px={2.5} sx={{ pb: 2 }}>
        <input
          id={inputId}
          type="file"
          accept=".jpg,.png,.mp3,.mp4,.mov,.docx,.pdf,.zip,.csv,.txt"
          style={{ display: 'none' }} // Hide the input visually
          onChange={handleFiles}
        />
        <Grid
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          item
          xs={12}
          sm={5}
          md={5.5}
          alignItems="center"
          sx={{
            p: 2,
            background: '#F0F9FF',
            border: '1px dashed #4B8EBF',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <label htmlFor={inputId} style={{ cursor: 'pointer' }}>
            <Stack flexDirection={'row'}>
              <Box mr={1}>
                <Folder />
              </Box>
              <Box>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: '600', color: '#565656' }}
                >
                  Drag & Drop to upload files
                </Typography>
                <Typography
                  sx={{ fontSize: '8px', fontWeight: '400', color: '#565656' }}
                >
                  Supported files: JPG, PNG, MP3, MP4, MOV, DOCX, PDF, ZIP, CSV,
                  TXT
                </Typography>
              </Box>
            </Stack>
          </label>
        </Grid>

        <Grid
          item
          xs={12}
          sm={1}
          md={1}
          justifyContent="center"
          alignItems="center"
          sx={{ p: 0.5, display: 'flex', flexDirection: 'column' }}
        >
          <Box
            sx={{
              borderRadius: '50%',
              background: '#E6E6E6',
              width: '24px',
              height: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: '400', color: '#000000' }}
            >
              or
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          md={5.5}
          onClick={handleOpenAddLinkDialog}
          alignItems="center"
          sx={{
            p: 2,
            background: '#F0F9FF',
            border: '1px dashed #4B8EBF',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Box mr={1}>
            <AddLink />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: '600', color: '#565656' }}
            >
              Add Link
            </Typography>
            <Typography
              sx={{ fontSize: '8px', fontWeight: '400', color: '#565656' }}
            >
              Direct your members to YouTube, Google Drive or articles
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* <Grid container px={2.5}>
      <Grid item xs={12} sm={12} alignItems="center" sx={{ p: 2, background: "#F0F9FF", borderRadius: '5px', display: "flex", alignItems: "center" }}>
      <Stack direction="row" alignItems="center">
         <DragIndicatorOutlinedIcon sx={{color:"#565656",fontSize:"20px",pr:"3px"}}/>
            <FileIcon />
         <Stack sx={{px:"10px"}}>
           <Typography sx={{fontSize:"12px",color:"#565656",fontFamily:"Roboto",fontWeight:"600"}}>document.pdf</Typography>
            <Typography sx={{fontSize:"8px",color:"#565656",fontFamily:"Roboto"}}>1 MB</Typography>
        </Stack>
      </Stack>
    </Grid>
      </Grid> */}

      <Dialog
        fullWidth
        maxWidth="xs"
        open={openAddLinkDialog}
        onClose={handleCloseAddLinkDialog}
        PaperProps={{
          style: {
            width: '100%',
          },
        }}
      >
        <AddLinkPopUp
          handleCloseAddLinkDialog={handleCloseAddLinkDialog}
          linkInputs={linkInputs}
          handleInputChange={handleInputChange}
          handleDeleteLink={handleDeleteLink}
          handleAddLink={handleAddLink}
          sectionPopUp={sectionPopUp}
          data={data}
          section={section}
          refetchCourseData={refetchCourseData}
          linkData={''}
          courseLinks={''}
        />
      </Dialog>
    </Stack>
  );
};

export default DragAndAddFiles;
