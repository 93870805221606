import axios from 'axios';
import { BASE_URL } from '../configurations/url.config';
import { BASE_URL_V2 } from '../configurations/url.config';

export const uploadSingleFile = async (
  token: string,
  file: File,
  name: string
) => {
  try {
    const formData = new FormData();
    formData.append(name, file);

    const response = await axios.post(`${BASE_URL}/file/upload`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: '*',
      },
    });

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: {} };
  }
};
export const uploadCommunityLogo = async (
  token: string,
  file: File,
  name: string
) => {
  try {
    const formData = new FormData();
    formData.append(name, file);

    const response = await axios.post(`${BASE_URL}/file/upload`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: '*',
      },
    });

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: {} };
  }
};

export const uploadCommunityFile = async (
  token: string,
  file: File,
  name: string,
  id: string
) => {
  try {
    const formData = new FormData();
    formData.append(name, file);
    formData.append('key', name);
    const response = await axios.post(
      `${BASE_URL}/community/${id}/uploadSingleFile`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          Accept: '*',
        },
      }
    );

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: {} };
  }
};
export const singleFileUpload = async (
  token: string,
  files: FileList | null,
  location: any
) => {
  try {
    if (!files || files.length === 0) {
      throw new Error('No files provided');
    }

    // Create a new FormData object to hold the files and location
    const formData = new FormData();
    formData.append('location', location);
    formData.append('file', files[0]); // Assuming a single file upload, you might need to loop for multiple files

    const response = await axios.post(
      `${BASE_URL_V2}/files/upload-single-file`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          Accept: '*',
        },
      }
    );

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: {} };
  }
};
export const updateSingleFileUpload = async (
  token: string,
  files: FileList | null,
  _id: string,
  location: any
) => {
  try {
    if (!files || files.length === 0) {
      throw new Error('No files provided');
    }

    // Create a new FormData object to hold the files and location
    const formData = new FormData();

    formData.append('file', files[0]);
    formData.append('location', location);
    const response = await axios.put(
      `${BASE_URL_V2}/files/re-upload-single-file/${_id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
          Accept: '*',
        },
      }
    );

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: {} };
  }
};
