import axios from 'axios';
import { BASE_URL } from '../configurations/url.config';
import { IAddOn, IAddOnPayload, IAddOnResponse } from '../models/addon.model';

export const get = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/addOn`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      response.data = response.data.map((d: IAddOnResponse): IAddOn => {
        return {
          id: d._id,
          title: d.title,
          type: d.addOnType,
          keys: d.addOnKey,
          createdBy: d.createdBy,
          status: d.status.toLowerCase(),
          updated: { date: '12 Aug. 2022', time: '08:34pm' },
        };
      });
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
export const add = async (data: IAddOnPayload, token: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/addOn`,
      { data: JSON.stringify(data) },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      const d: IAddOn = {
        id: response.data._id,
        title: response.data.title,
        type: response.data.addOnType,
        keys: response.data.addOnKey,
        createdBy: response.data.createdBy,
        status: response.data.status.toLowerCase(),
        updated: { date: '12 Aug. 2022', time: '08:34pm' },
      };
      response.data = d;
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
export const update = async (
  id: string,
  data: IAddOnPayload,
  token: string
) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/addOn/${id}`,
      { data: JSON.stringify(data) },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.status === 200) {
      response.data = response.data.map((d: IAddOnResponse): IAddOn => {
        return {
          id: response.data._id,
          title: d.title,
          type: d.addOnType,
          keys: d.addOnKey,
          createdBy: d.createdBy,
          status: d.status.toLowerCase(),
          updated: { date: '12 Aug. 2022', time: '08:34pm' },
        };
      });
    }
    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: [] };
  }
};
export const remove = async (id: string, token: string) => {
  try {
    const response = await axios.delete(`${BASE_URL}/addOn/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (err) {
    console.log('ERR :', err);
    return { status: 500, data: null };
  }
};
