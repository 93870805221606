import axios from 'axios';
import { BASE_URL } from '../configurations/url.config';

export const getPaymentList = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/payment/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};

export const transactionDetails = async (token: string, id: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/payment/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
export const initiatePayment = async (
  token: string,
  communityId: string,
  planId: string
) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/payment/${communityId}/initiate/${planId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response;
  } catch (err) {
    return { status: 500, data: [] };
  }
};
